import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { CustomDialog } from '../../helpers/styles/CustomDialog';
import { pxToRem } from '../../utils/unitsConversion';
import { useAuth } from '../../auth/providers/AuthProvider';
import Roles from '../../enums/Roles.enum';

const Transition = forwardRef( function Transition ( props, ref )
{
  return <Slide direction="up" ref={ ref } { ...props } />;
} );

function DialogRejectSubject ( {
  openReturnRequestOpenDialog,
  handleReturnRequestCloseDialog,
  feedbackFieldValue,
  handleFeedbackFieldChange,
  handleFeedbackFieldBlur,
  feedbackFieldTouched,
  isLoading,
  request,
  handleReturnRequest,
} )
{

  const auth = useAuth();
  const user = auth.getUser();

  return (
    <CustomDialog
      open={ openReturnRequestOpenDialog }
      onClose={ handleReturnRequestCloseDialog }
      color='error'
      maxWidth="lg"
      TransitionComponent={ Transition }
      keepMounted
      aria-labelledby="alert-return-request-dialog-title"
      aria-describedby="alert-return-request-dialog-description"
    >
      <DialogTitle id="alert-return-request-dialog-title" align='center'>
        <InfoIcon />
        <Typography variant="h4" component={ 'span' }>
          {Roles.DirectCurricularArea.toLowerCase() === user.role.toLowerCase() &&
            'No recomendar solicitud'
          }
          { (Roles.AcademicSecretary.toLowerCase() === user.role.toLowerCase() ||
            Roles.AcademicDirectionOfHeadquarters.toLowerCase() === user.role.toLowerCase()) &&
            'Rechazar solicitud'
          }
        </Typography>
      </DialogTitle>
      <DialogContent align='center'>
        <DialogContentText id="alert-return-request-dialog-description" component="div">
          <Typography variant="body1" sx={ { mb: 3 } }>
            {Roles.DirectCurricularArea.toLowerCase() === user.role.toLowerCase() &&
              '¿Está seguro que desea no recomendar la solicitud? Por favor, deje una retroalimentación.'
            }
            {(Roles.AcademicSecretary.toLowerCase() === user.role.toLowerCase() ||
              Roles.AcademicDirectionOfHeadquarters.toLowerCase() === user.role.toLowerCase()) &&
              '¿Está seguro que desea rechazar la solicitud? Por favor, deje una retroalimentación.'
            }
          </Typography>

          <TextField
            id="feedbakFieldId"
            label="Retroalimentación"
            multiline
            fullWidth
            minRows={ 5 }
            value={ feedbackFieldValue }
            onChange={ handleFeedbackFieldChange }
            onBlur={ handleFeedbackFieldBlur }
            required
            minLength={ 10 }
            maxLength={ 500 }
            error={ feedbackFieldTouched && ( feedbackFieldValue.length < 10 || feedbackFieldValue.length > 500 ) }
            helperText={
              feedbackFieldTouched && (
                ( feedbackFieldValue.length < 10 && 'Debe tener al menos 10 caracteres.' ) ||
                ( feedbackFieldValue.length > 500 && 'Debe tener menos de 500 caracteres.' )
              )
            }
          />
        </DialogContentText>.
      </DialogContent>
      <DialogActions sx={ { justifyContent: 'center', gap: pxToRem( 16 ) } }>
        { isLoading ? (
          <Box sx={ { display: 'flex', justifyContent: 'center' } }>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Button
              color='primary'
              variant='outlined'
              size='medium'
              onClick={ () => handleReturnRequestCloseDialog() }
            >Cancelar</Button>
            <Button
              disabled={ ( feedbackFieldValue.length < 10 || feedbackFieldValue.length > 500 ) }
              color='error'
              variant='outlined'
              size='medium'
              onClick={ () => handleReturnRequest( request.IDIDENTIFICADORASIGNATURA ) }
            >Enviar</Button>
          </>
        ) }
      </DialogActions>
    </CustomDialog>
  )
}


DialogRejectSubject.propTypes = {
  openReturnRequestOpenDialog: PropTypes.bool,
  handleReturnRequestCloseDialog: PropTypes.func.isRequired,
  feedbackFieldValue: PropTypes.string,
  handleFeedbackFieldChange: PropTypes.func.isRequired,
  handleFeedbackFieldBlur: PropTypes.func.isRequired,
  feedbackFieldTouched: PropTypes.bool,
  isLoading: PropTypes.bool,
  request: PropTypes.object,
  handleReturnRequest: PropTypes.func.isRequired,
};

export default DialogRejectSubject;