import { Box, Button, CircularProgress, Typography } from '@mui/material';
import Roles from '../../enums/Roles.enum';
import AlertDialog from '../../shared/components/AlertDialog';
import SubjectDetail from '../../shared/components/SubjectDetail';
import DialogApproveSubject from '../shared/DialogApproveSubject';
import DialogRejectSubject from '../shared/DialogRejectSubject';
import UseLogicSubjectRequest from './hooks/UseLogicSubjectRequest';
import PropTypes from 'prop-types';
import Sources from '../../enums/Sources.enum';

function SubjectRequest ( { source, title } )
{
 
  const {
    user,
    request,
    handleReturnRequestOpenDialog,
    handleReturnRequestCloseDialog,
    openReturnRequestOpenDialog,
    handleApproveRequest,
    handleReturnRequest,
    handleFeedbackFieldChange,
    handleFeedbackFieldBlur,
    feedbackFieldValue,
    feedbackFieldTouched,
    isLoading,
    openErrorOpenDialog,
    handleErrorCloseDialog,
    handleApproveRequestOpenDialog,
    handleApproveRequestCloseDialog,
    openApproveRequestOpenDialog,
    actNumberFieldValue,
    actNumberFieldTouched,
    handleActNumberFieldChange,
    handleActNumberFieldBlur,
    actDate,
    handleActDate,
    openSuccessDialog,
    successDialogMessage,
    handleCloseSuccessDialog,
    openCreateSubjectFromCurrentSubjectDialog,
    handleOpenCreateSubjectDialog,
    handleCloseCreateSubjectDialog,
    handleCreateSubjectFromCurrentSubject,
  } = UseLogicSubjectRequest(source);

  return (
    <Box>
      <Typography variant='h4' component='h2' sx={ { mb: 3 } }>{ title }</Typography>
      <SubjectDetail subject={ request } source={ source }></SubjectDetail>

      { isLoading ? (
        <Box sx={ { display: 'flex', justifyContent: 'center' } }>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={ { p: 3, textAlign: 'center' } }>
          {/* Solo sale para el rol dirección academica de sede y que sea de propuesta de edición */}
          { user.role.toLowerCase() === Roles.AcademicDirectionOfHeadquarters.toLowerCase() && source === Sources.SubjectByProposedEdition &&
            <Button type='button' variant="contained" sx={ { mr: 2 } } onClick={ () => handleOpenCreateSubjectDialog() }>Crear asignatura a partir de esta</Button>
          }
          <Button type='button' variant="contained" sx={ { mr: 2 } } color="success" onClick={ () => handleApproveRequestOpenDialog() }>
            { user.role.toLowerCase() === Roles.AcademicDirectionOfHeadquarters.toLowerCase() ?
              source === Sources.SubjectByProposedEdition ? 'Aceptar cambio' : 'Codificar y Registrar en SIA' :
                user.role.toLowerCase() === Roles.DirectCurricularArea.toLowerCase() ?
                  'Recomendar':
                  'Aprobar' }
          </Button>
          <Button type='button' variant="contained" sx={ { mr: 2 } } color="error" onClick={ () => handleReturnRequestOpenDialog() }>
            {user.role.toLowerCase() === Roles.DirectCurricularArea.toLowerCase() ? 'No recomendar' : 'Rechazar'}
          </Button>
        </Box>
      ) }
      
      { /*  Dialog para crear una asignatura apartir de la actual y solo aplica para dirección academica de sede */ }
      <AlertDialog
        openDialog={ openCreateSubjectFromCurrentSubjectDialog }
        handleCloseDialog={ handleCloseCreateSubjectDialog }
        title='¿Está seguro de crear la asignatura a partir de esta?'
        type='info'
        primaryButtonText='Cancelar'
        description=''
        handleSecondaryAction={ handleCreateSubjectFromCurrentSubject }
        secondaryButtonText='Aceptar'>
      </AlertDialog>

      { /*  Dialog para sacar mensaje satisfactorio */ }
      <AlertDialog
        openDialog={ openSuccessDialog }
        handleCloseDialog={ handleCloseSuccessDialog }
        title={ successDialogMessage.title }
        type={successDialogMessage.type}
        primaryButtonText={successDialogMessage.primaryButtonText}
        description={successDialogMessage.description}>
      </AlertDialog>


      { /*  Dialog para sacar un error al momento de actualizar la solicitud */ }
      <AlertDialog openDialog={ openErrorOpenDialog }
        handleCloseDialog={ handleErrorCloseDialog }
        title={ 'La Solicitud ' + request.nombre }
        type='error'
        primaryButtonText='Aceptar'
        description='Error al actualizar la solicitud'></AlertDialog>

      {/* Dialog para aprobar la solicitud */ }
      <DialogApproveSubject
        openApproveRequestOpenDialog={ openApproveRequestOpenDialog }
        handleApproveRequestCloseDialog={ handleApproveRequestCloseDialog }
        user={ user }
        actNumberFieldValue={ actNumberFieldValue }
        handleActNumberFieldChange={ handleActNumberFieldChange }
        handleActNumberFieldBlur={ handleActNumberFieldBlur }
        actNumberFieldTouched={ actNumberFieldTouched }
        actDate={ actDate }
        handleActDate={ handleActDate }
        isLoading={ isLoading }
        handleApproveRequest={ handleApproveRequest }
        request={ request }
        source={ source }
      ></DialogApproveSubject>

      {/* Dialog para devolver la solicutud */ }
      <DialogRejectSubject
        openReturnRequestOpenDialog={ openReturnRequestOpenDialog }
        handleReturnRequestCloseDialog={ handleReturnRequestCloseDialog }
        feedbackFieldValue={ feedbackFieldValue }
        handleFeedbackFieldChange={ handleFeedbackFieldChange }
        handleFeedbackFieldBlur={ handleFeedbackFieldBlur }
        feedbackFieldTouched={ feedbackFieldTouched }
        isLoading={ isLoading }
        request={ request }
        handleReturnRequest={ handleReturnRequest }
      >
      </DialogRejectSubject>
    </Box>
  )
}


SubjectRequest.propTypes = {
  source: PropTypes.string,
  title: PropTypes.string,
};

export default SubjectRequest;