
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sources from '../../../enums/Sources.enum';
import { useAuth } from '../../../auth/providers/AuthProvider';
import createAxiosInstance from '../../../utils/AxiosConfig';
import { getSubjectStateByRol } from '../../../helpers/Subject.helper';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Roles from '../../../enums/Roles.enum';

dayjs.extend( utc );
dayjs.extend( timezone );
dayjs.tz.setDefault( 'America/Bogota' );

export default function UseLogicSubjectRequest ( source )
{
  const [ openReturnRequestOpenDialog, setReturnRequestOpenDialog ] = useState( false );
  const [ openApproveRequestOpenDialog, setApproveRequestOpenDialog ] = useState( false );

  const [ feedbackFieldValue, setFeedbackFieldValue ] = useState( '' );
  const [ feedbackFieldTouched, setFeedbackFieldTouched ] = useState( false );

  const [ actNumberFieldValue, setActNumberFieldValue ] = useState( '' );
  const [ actNumberFieldTouched, setActNumberFieldTouched ] = useState( false );
  const todayDate = dayjs.utc().subtract(1, 'day').format( 'YYYY-MM-DD' );
  const [ actDate, setActDate ] = useState( dayjs.utc( todayDate ) );

  const [ isLoading, setIsLoading ] = useState( false );
  const [ openErrorOpenDialog, setErrorOpenDialog ] = useState( false );

  const [ openSuccessDialog, setOpenSuccessDialog ] = useState( false );
  const [ successDialogMessage, setSuccessDialogMessage ] = useState( {
    title: 'Solicitud',
    type: 'success',
    description: '',
    primaryButtonText: 'Aceptar',
    redirectTo: ''
  } );

  const [ openCreateSubjectFromCurrentSubjectDialog, setOpenCreateSubjectFromCurrentSubjectDialog ] = useState( false );

  const auth = useAuth();
  const user = auth.getUser();
  const { state } = useLocation();
  const navigate = useNavigate();

  const request = state ? state.request : null;

  useEffect( () =>
  {
    const requestValidate = () =>
    {
      if ( !request )
      {
        navigate( source === Sources.SubjectByProposedEdition ? `/requests-by-proposed-edition` : `/pending-requests` );
      }
    };
    requestValidate();
  }, [] );

  const handleReturnRequestOpenDialog = () =>
  {
    setReturnRequestOpenDialog( true );
  };

  const handleReturnRequestCloseDialog = () =>
  {
    setReturnRequestOpenDialog( false );
  };

  const handleApproveRequestOpenDialog = () =>
  {
    setApproveRequestOpenDialog( true );
  };

  const handleApproveRequestCloseDialog = () =>
  {
    setApproveRequestOpenDialog( false );
  };

  const getApproveEndpoint = () =>
  {
    // solicitudes por propuesta de edición
    if ( source === Sources.SubjectByProposedEdition )
    {
      return 'auxiliary/actualizar-propuesta-edicion';
    }
    // Solicitudes por propuesta de creación (asginaturas pendientes)
    return 'auxiliary';
  }

  const getRedirectUrl = () =>
  {
    // solicitudes por propuesta de edición
    if ( source === Sources.SubjectByProposedEdition )
    {
      return '/requests-by-proposed-edition';
    }
    // Solicitudes por propuesta de creación (asginaturas pendientes)
    return '/pending-requests';
  }

  const callToApiApproveRequest = async ( requestId ) =>
  {
    setIsLoading( true );
    try
    {
      const subjectByState = getSubjectStateByRol( user.role );
      const request = {
        idSubjectState: subjectByState.approved,
      };

      if ( actNumberFieldValue )
      {
        request.actNumber = actNumberFieldValue;
        request.actDate = dayjs.utc( actDate ).format( 'YYYY-MM-DD' );
      }
      const { status } = await createAxiosInstance().patch( `/${ getApproveEndpoint() }/${ requestId }`, request );
      setIsLoading( false );
      if ( status === 200 )
      {
        setSuccessDialogMessage( {
          title: Roles.AcademicSecretary.toLowerCase() === user.role.toLowerCase() ?
            'Solicitud aprobada' :
              Roles.AcademicSecretary.toLowerCase() === Roles.AcademicDirectionOfHeadquarters.toLowerCase() ?
              'Por revisión en SIA' : 'Solicitud enviada correctamente',
          type: 'success',
          description: '',
          primaryButtonText: 'Aceptar',
          redirectTo: getRedirectUrl()
        } );
        setApproveRequestOpenDialog( false );
        setOpenSuccessDialog( true );
      }
    } catch ( error )
    {
      setIsLoading( false );
      setApproveRequestOpenDialog( false );
      handleErrorOpenDialog();
    }
  }

  const handleApproveRequest = ( requestId ) =>
  {
    callToApiApproveRequest( requestId );
  }

  const getRequest = ( requestId ) =>
  {
    // solicitudes por propuesta de edición
    if ( source === Sources.SubjectByProposedEdition )
    {
      return {
        idEditSubject: requestId,
        descripcion: feedbackFieldValue,
      };
    }
    // Solicitudes por propuesta de creación (asginaturas pendientes)
    return {
      idSubject: requestId,
      descripcion: feedbackFieldValue,
    };
  }

  const callToApiToCreateFeedback = async ( requestId ) =>
  {
    setIsLoading( true );
    try
    {
      const { status } = await createAxiosInstance().post( `/subject-feedback`, getRequest( requestId ) );
      setIsLoading( false );
      if ( status === 201 || status === 200 )
      {
        setSuccessDialogMessage( {
          title: Roles.AcademicSecretary.toLowerCase() === user.role.toLowerCase() ?
            'Solicitud rechazada' : 'Solicitud devuelta correctamente',
          type: 'success',
          description: '',
          primaryButtonText: 'Aceptar',
          redirectTo: getRedirectUrl()
        } );
        setReturnRequestOpenDialog( false );
        setOpenSuccessDialog( true );
      }
    } catch ( error )
    {
      setIsLoading( false );
      setReturnRequestOpenDialog( false );
      handleErrorOpenDialog();
    }
  }

  const getRejectEndpoint = () =>
  {
    // solicitudes por propuesta de edición
    if ( source === Sources.SubjectByProposedEdition )
    {
      return 'auxiliary/actualizar-propuesta-edicion'
    }
    // Solicitudes por propuesta de creación (asginaturas pendientes)
    return 'auxiliary';
  }

  const callToApiRejectedRequest = async ( requestId ) =>
  {
    setIsLoading( true );
    try
    {
      const subjectByState = getSubjectStateByRol( user.role );
      const { status } = await createAxiosInstance().patch( `/${ getRejectEndpoint() }/${ requestId }`,
        {
          idSubjectState: subjectByState.rejected
        } );
      setIsLoading( false );
      if ( status === 200 )
      {
        callToApiToCreateFeedback( requestId );
      }
    } catch ( error )
    {
      setIsLoading( false );
      setReturnRequestOpenDialog( false );
      handleErrorOpenDialog();
    }
  }

  const handleOpenCreateSubjectDialog = () =>
  {
    setOpenCreateSubjectFromCurrentSubjectDialog( true );
  }

  const handleCloseCreateSubjectDialog = () =>
  {
    setOpenCreateSubjectFromCurrentSubjectDialog( false );
  }

  // Solo sale para el rol dirección academica de sede y que sea de propuesta de edición
  const handleCreateSubjectFromCurrentSubject = async () =>
  {
    handleCloseCreateSubjectDialog();
    setIsLoading( true );

    try
    {
      const subjectByState = getSubjectStateByRol( user.role );
      const { status } = await createAxiosInstance().patch( `/auxiliary/actualizar-propuesta-edicion-sin-deshabilitar/${ request.id }`,
        {
          idSubjectState: subjectByState.approved
        } );
      setIsLoading( false );
      if ( status === 200 )
      {
        setSuccessDialogMessage( {
          title: 'Asignatura creada correctamente',
          type: 'success',
          description: '',
          primaryButtonText: 'Aceptar',
          redirectTo: getRedirectUrl()
        } );
        setOpenSuccessDialog( true );
      }
    } catch ( error )
    {
      setIsLoading( false );
      handleErrorOpenDialog();
    }
  }

  const handleActDate = (value) => {
    setActDate(value);
  }

  const handleReturnRequest = ( requestId ) =>
  {
    callToApiRejectedRequest( requestId );
  }

  const handleFeedbackFieldChange = ( event ) =>
  {
    setFeedbackFieldValue( event.target.value );
  };

  const handleFeedbackFieldBlur = () =>
  {
    setFeedbackFieldTouched( true );
  };

  const handleActNumberFieldChange = ( event ) =>
  {
    setActNumberFieldValue( event.target.value );
  };

  const handleActNumberFieldBlur = () =>
  {
    setActNumberFieldTouched( true );
  };

  const handleErrorOpenDialog = () =>
  {
    setErrorOpenDialog( true );
  };

  const handleErrorCloseDialog = () =>
  {
    setErrorOpenDialog( false );
  };

  const handleCloseSuccessDialog = () =>
  {
    setOpenSuccessDialog( false );
    navigate( successDialogMessage.redirectTo, { replace: true } );
  }

  return {
    user,
    request,
    handleReturnRequestOpenDialog,
    handleReturnRequestCloseDialog,
    openReturnRequestOpenDialog,
    handleApproveRequest,
    handleReturnRequest,
    handleFeedbackFieldChange,
    handleFeedbackFieldBlur,
    feedbackFieldValue,
    feedbackFieldTouched,
    isLoading,
    openErrorOpenDialog,
    handleErrorCloseDialog,
    handleApproveRequestOpenDialog,
    handleApproveRequestCloseDialog,
    openApproveRequestOpenDialog,
    actNumberFieldValue,
    actNumberFieldTouched,
    handleActNumberFieldChange,
    handleActNumberFieldBlur,
    actDate,
    handleActDate,
    openSuccessDialog,
    successDialogMessage,
    handleCloseSuccessDialog,
    openCreateSubjectFromCurrentSubjectDialog,
    handleOpenCreateSubjectDialog,
    handleCloseCreateSubjectDialog,
    handleCreateSubjectFromCurrentSubject,
  }
}
