import { Box, Button, Grid, Typography } from "@mui/material";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { RichTextEditorProvider, RichTextField } from "mui-tiptap";
import RichTextFieldControls from "../../RichTextFieldControls";
import { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { LearningAchievementSubjectFormByStudyPlan } from "./LearningAchievementSubjectFormByStudyPlan";
import AlertDialog from "../../../../../shared/components/AlertDialog";

export const LearningAchievementByStudyPlanForm = ({
  sendDataForm,
  index,
  learningAchievement,
  subjectByProposedEdition,
  formValidate,
  learningAchievementsValidateEmitter,
}) => {
  const regex = /<[^>]+>/g;

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState({
    title: "",
    type: "info",
    description: "",
    primaryButtonText: "",
    redirectTo: "",
  });

  // campo plan
  const [planField, setPlanField] = useState("");

  // campo resultado de aprendizaje
  const [learningAchievementField, setLearningAchievementField] = useState({
    plainText: "",
    textHtml: "",
  });

  const [
    learningAchievementsSubjectFormData,
    setLearningAchievementsSubjectFormData,
  ] = useState([]);

  useEffect(() => {
    learningAchievementsValidateEmitter(false);
    setPlanField(learningAchievement?.nombrePlan);
    setLearningAchievementField({
      plainText: learningAchievement?.resultadoAprendizaje
        ?.replace(regex, "")
        ?.trim(),
      textHtml: learningAchievement?.resultadoAprendizaje,
    });
    setLearningAchievementsSubjectFormData(
        learningAchievement?.aspectoMacro ?
        JSON.parse(learningAchievement?.aspectoMacro).items :
        [{
          aspectoMacro: "",
        },]);
  }, []);

  const handleDialog = () => {
    setOpenDialog(false);
  };

  // ritch text editor
  const learningAchievementEditor = useEditor({
    extensions: [StarterKit],
    content: learningAchievement?.resultadoAprendizaje,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      const text = editor.getText();
      setLearningAchievementField({
        plainText: text,
        textHtml: html,
      });
      sendDataForm(
        {
          ...learningAchievement,
          resultadoAprendizaje: html,
          nombrePlan: planField,
        },
        index
      );
    },
  });

  const canAddLearningAchievementsSubject = () => {
    const isValid = learningAchievementsSubjectFormData?.every((learning) =>
      learning?.aspectoMacro?.replace(regex, "")?.trim()
    );
    return isValid;
  };

  const handleAddLearningAchievementsSubjectClick = () => {
    if (canAddLearningAchievementsSubject()) {
      learningAchievementsValidateEmitter(false);
      const dataToAdded = [
        ...learningAchievementsSubjectFormData,
        {
          aspectoMacro: "",
        },
      ];
      setLearningAchievementsSubjectFormData(dataToAdded);
      sendDataForm(
        {
          ...learningAchievement,
          aspectoMacro: JSON.stringify({
            items: dataToAdded
          }),
        },
        index
      );
    } else {
      setDialogMessage({
        title:
          "No puede agregar mas resultados de aprendizaje asignatura sin haber rellenado los campos",
        type: "info",
        description: "",
        primaryButtonText: "Aceptar",
        redirectTo: "",
      });
      setOpenDialog(true);
    }
  };

  const handleLearningAchievementSubject = (data, indexForm) => {
    setLearningAchievementsSubjectFormData((prevLearningAchievementSubject) => {
      // Crear una copia superficial del array
      const updatedLearningAchievementSubject = [...prevLearningAchievementSubject];
      
      // Actualizar solo el elemento necesario
      updatedLearningAchievementSubject[indexForm] = data;
  
      // Enviar los datos actualizados
      sendDataForm(
        {
          ...learningAchievement,
          aspectoMacro: JSON.stringify({
            items: updatedLearningAchievementSubject
          }),
        },
        index
      );
  
      // Devolver el nuevo estado
      return updatedLearningAchievementSubject;
    });
  };

  const handleDeleteLearningAchievementsSubjectClick = (indexForm) => {
    learningAchievementsSubjectFormData.splice(indexForm, 1);
    setLearningAchievementsSubjectFormData([]);
    setTimeout(() => {
      setLearningAchievementsSubjectFormData(
        learningAchievementsSubjectFormData
      );
      sendDataForm(
        {
          ...learningAchievement,
          aspectoMacro: JSON.stringify({
            items: learningAchievementsSubjectFormData
          }),
        },
        index
      );
    }, 0);
  };

  return (
    <Box>
      <Grid container spacing={{ xs: 3 }}>
        <Grid item xs={12} sm={6}>
          <Typography gutterBottom variant="h6" component="div" sx={{ mb: 2 }}>
            RAP {index + 1}
          </Typography>
          <Typography gutterBottom variant="h7" component="div">
            Plan de estudio: {planField}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography gutterBottom variant="h7" component="div">
            Objetivo resultado de aprendizaje*
          </Typography>
          <RichTextEditorProvider editor={learningAchievementEditor}>
            <RichTextField
              controls={<RichTextFieldControls></RichTextFieldControls>}
            />
          </RichTextEditorProvider>
          {learningAchievementField?.plainText?.length === 0 &&
            formValidate && (
              <Typography variant="body2" gutterBottom color="error">
                El campo es requerido
              </Typography>
            )}
        </Grid>

        {learningAchievementsSubjectFormData.map(
          (learningAchievement, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <LearningAchievementSubjectFormByStudyPlan
                formValidate={formValidate}
                subjectByProposedEdition={subjectByProposedEdition}
                learningAchievement={learningAchievement}
                itemCount={learningAchievementsSubjectFormData.length}
                sendDataForm={handleLearningAchievementSubject}
                handleDelete={handleDeleteLearningAchievementsSubjectClick}
                index={index}
              ></LearningAchievementSubjectFormByStudyPlan>
            </Grid>
          )
        )}
        <Grid item xs={12}>
          <Button
            startIcon={<AddCircleIcon />}
            sx={{ textTransform: "initial" }}
            type="button"
            onClick={handleAddLearningAchievementsSubjectClick}
          >
            Añadir resultados de aprendizaje asignatura
          </Button>
        </Grid>
      </Grid>
      <AlertDialog
        openDialog={openDialog}
        handleCloseDialog={handleDialog}
        title={dialogMessage.title}
        type={dialogMessage.type}
        primaryButtonText={dialogMessage.primaryButtonText}
        description={dialogMessage.description}
      ></AlertDialog>
    </Box>
  );
};
