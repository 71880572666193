import InfoIcon from '@mui/icons-material/Info';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import { CustomDialog } from '../../helpers/styles/CustomDialog';
import { pxToRem } from '../../utils/unitsConversion';
import { UseLogicSubjectView } from './hooks/UseLogicSubjectView';
import SubjectDetail from '../../shared/components/SubjectDetail';

const Transition = forwardRef( function Transition ( props, ref )
{
  return <Slide direction="up" ref={ ref } { ...props } />;
} );

export default function SubjectView ()
{

  const {
    subject,
    isLoading,
    handleCloseDialog,
    openDialog,
    source,
  } = UseLogicSubjectView();

  return (
    <Box>
      { isLoading ? (
        <Box sx={ { display: 'flex', justifyContent: 'center' } }>
          <CircularProgress />
        </Box>
      ) : (
        subject && <SubjectDetail subject={ subject } source={ source }></SubjectDetail>
      ) }

      <CustomDialog
        open={ openDialog }
        onClose={ handleCloseDialog }
        color='error'
        TransitionComponent={ Transition }
        keepMounted
        aria-labelledby="alert-cancel-dialog-title"
        aria-describedby="alert-cancel-dialog-description"
      >
        <DialogTitle id="alert-cancel-dialog-title" align='center'>
          <InfoIcon />
          <Typography variant="h4" component={ 'span' }>
            Asignatura
          </Typography>
        </DialogTitle>
        <DialogContent align='center'>
          <DialogContentText id="alert-cancel-dialog-description" component="div">
            <Typography variant="body1">
              Error al obtener la asignatura
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={ { justifyContent: 'center', gap: pxToRem( 16 ) } }>
          <Button
            color='primary'
            variant='outlined'
            size='medium'
            onClick={ () => handleCloseDialog() }
          >Aceptar</Button>
        </DialogActions>
      </CustomDialog>
    </Box>
  )
}
