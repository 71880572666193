import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import PropTypes from 'prop-types';
import { useAuth } from '../../auth/providers/AuthProvider';
import Roles from '../../enums/Roles.enum';
import { getStatusSubject } from '../../helpers/Subject.helper';
import SubjectStatus from '../../enums/SubjectStatus.enum';
import Sources from '../../enums/Sources.enum';

dayjs.extend( utc );
dayjs.extend( timezone );
dayjs.tz.setDefault( 'America/Bogota' );

function SubjectResults ( { items, redirectTo, handleSubjectEdit, handleOpenFeedbackDialog, source } )
{

  const auth = useAuth();
  const user = auth.getUser();

  return (
    <TableContainer component={ Paper }
      sx={ {
        mt: 3,
        overflowX: 'auto',
      } }>
      <Table sx={ { minWidth: 650 } } size="small" aria-label="Resultados de asignatura">
        <TableHead>
          <TableRow>
            <TableCell>{ user.role.toLowerCase() === Roles.Teacher.toLowerCase() ? 'Fecha de creación' : 'Fecha solicitud' }</TableCell>
            { source === Sources.SubjectByProposedEdition &&
              <TableCell align="left">Código</TableCell>
            }
            <TableCell align="left">Nombre</TableCell>
            <TableCell>No. Creditos</TableCell>
            <TableCell>Validable</TableCell>
            <TableCell>Nivel</TableCell>
            <TableCell>Libre elección</TableCell>
            { user.role.toLowerCase() !== Roles.Teacher.toLowerCase() &&
              <TableCell align="left">Solicitante</TableCell>
            }
            { user.role.toLowerCase() === Roles.Teacher.toLowerCase() && source !== Sources.DraftSubjects &&
              <TableCell align="left">Estado solicitud</TableCell>
            }
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { items.map( ( item ) => (
            <TableRow
              key={ item.IDIDENTIFICADORASIGNATURA }
              sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
            >
              <TableCell component="th" scope="row">
                { dayjs.utc( item.createdAt ).format( 'YYYY-MM-DD' ) ?? '-' }
              </TableCell>
              { source === Sources.SubjectByProposedEdition &&
                <TableCell align="left">
                  { item.CODIGO_ASIGNATURA }
                </TableCell>
              }
              <TableCell align="left">
                { item.NOMBRE }
              </TableCell>
              <TableCell component="th" scope="row">
                { item.NUM_CREDITOS }
              </TableCell>
              <TableCell component="th" scope="row">
                { item.VALIDABLE ? 'Si' : 'No' }
              </TableCell>
              <TableCell component="th" scope="row">
                { item.NIVEL }
              </TableCell>
              <TableCell component="th" scope="row">
                { item.ASIGNATURALIBRELECCION ? 'Si' : 'No' }
              </TableCell>
              { user.role.toLowerCase() !== Roles.Teacher.toLowerCase() &&
                <TableCell align="left">
                  { item.user ? (
                    <span>{ item.user.name } { item.user.lastname }</span>
                  ): (
                    <span>{ item.idDocente ?? '-' }</span>
                  )}
                </TableCell>
              }
              { user.role.toLowerCase() === Roles.Teacher.toLowerCase() && source !== Sources.DraftSubjects &&
                <TableCell align="left">
                  { getStatusSubject( item.subjectState ).status }<br></br>
                  { getStatusSubject( item.subjectState ).message }
                </TableCell>
              }
              <TableCell align="left">
                { user.role.toLowerCase() === Roles.Teacher.toLowerCase() ? (
                  <Button variant="contained" size="small" sx={{ whiteSpace: 'nowrap' }}
                    onClick={ () => redirectTo( item.IDIDENTIFICADORASIGNATURA ) }>{source === Sources.DraftSubjects ? 'Ver propuesta' : 'Ver asignatura'}</Button>
                ) : (
                  <Button variant="contained" size="small" sx={{ whiteSpace: 'nowrap' }}
                    onClick={ () => redirectTo( item ) }>Ver solicitud</Button>
                )}
                {((user.role.toLowerCase() === Roles.Teacher.toLowerCase() &&
                  getStatusSubject(item.subjectState).status === SubjectStatus.Rejected &&
                  item.subjectFeedback && item.subjectFeedback.length > 0) || 
                  (source === Sources.DraftSubjects)) && (
                    <Button 
                      variant="outlined"
                      sx={{ m: 1, whiteSpace: 'nowrap' }}
                      color="secondary"
                      onClick={() => handleSubjectEdit(item)}
                      size="small"
                    >
                      {source === Sources.DraftSubjects ? 'Continuar con la propuesta' : 'Editar asignatura'}
                    </Button>
                )}

                { user.role.toLowerCase() === Roles.Teacher.toLowerCase() &&
                  (getStatusSubject( item.subjectState ).status === SubjectStatus.Rejected ||
                  getStatusSubject( item.subjectState ).status === SubjectStatus.Draft) &&
                  item.subjectFeedback && item.subjectFeedback.length > 0 &&
                  <Button variant="contained"
                    sx={ { m: 1, whiteSpace: 'nowrap' } }
                    color="success"
                    onClick={ () => handleOpenFeedbackDialog( item.subjectFeedback ) }
                    size="small">Ver comentarios</Button>
                }
              </TableCell>
            </TableRow>
          ) ) }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

SubjectResults.propTypes = {
  items: PropTypes.array,
  redirectTo: PropTypes.func.isRequired,
  handleSubjectEdit: PropTypes.func,
  handleOpenFeedbackDialog: PropTypes.func,
  source: PropTypes.string,
};

export default SubjectResults;