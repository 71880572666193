import { useCallback, useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CircleIcon from '@mui/icons-material/Circle';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { DurationAndAttendancePercentage } from './DurationAndAttendancePercentage';

export default function GeneralInformationForm ( { formData, setFormData, campusesData, subjectByProposedEdition, validate } )
{
	const [ faculties, setFaculties ] = useState( [] );
	const [ basicUnits, setBasicUnits ] = useState( [] );

	useEffect( () =>
	{
		const campus = campusesData.find( campus => campus.NOMBRE === formData.SEDE );
		if ( campus ) {
			const faculties = campus.Facultades;
			setFaculties( faculties );
			const faculty = faculties.find( faculty => faculty.NOMBRE === formData.FACULTAD );
			if (faculty)
			{
				setBasicUnits( faculty.UABS );
			}
		}
	}, [] );

	const handleChange = useCallback( ( event ) =>
	{
		const { name, value } = event.target;
		setFormData( prevFormData => ( {
			...prevFormData,
			[ name ]: value
		} ) );
	}, [] );


	let currentCampusValue = '';
	const handleCampusChange = useCallback( ( event ) =>
	{
		currentCampusValue = event.target.value;
		const campus = campusesData.find( campus => campus.NOMBRE === currentCampusValue )
		setFormData( prevFormData => ( {
			...prevFormData,
			sede: currentCampusValue,
			facultad: '',
			unidadAcademicaBasica: '',
			idUAB: null,
		} ) );
		const facultiesData = campus.Facultades;
		setFaculties( facultiesData );
		setBasicUnits( [] );

	}, [] );

	let basicUnitsData = [];
	const handleFacultyChange = useCallback( ( event ) =>
	{
		setFormData( prevFormData => ( {
			...prevFormData,
			facultad: event.target.value,
			unidadAcademicaBasica: '',
			idUAB: null,
		} ) );

		const campus = campusesData.find( campus => campus.NOMBRE === ( currentCampusValue || formData.SEDE ) );
		if ( campus )
		{
			const faculty = campus.Facultades.find( faculty => faculty.NOMBRE === event.target.value );
			if (faculty) {
				basicUnitsData = faculty.UABS;
			}
		}

		setBasicUnits( basicUnitsData );
	}, [] );

	const handleBasicUnit = useCallback( ( event ) =>
	{
		const basicUnit = basicUnitsData.find( basicUnit => basicUnit.NOMBRE === event.target.value );

		setFormData( prevFormData => ( {
			...prevFormData,
			unidadAcademicaBasica: basicUnit.NOMBRE,
			idUAB: basicUnit.id,
		} ) );

	}, [] );

	const handleCheckboxChange = ( event, name ) =>
	{
		setFormData( {
			...formData,
			[ name ]: event.target.checked
		} );
	};

	const levelData = [
		{
			value: 'pregrado',
			label: 'Pregrado',
		},
		{
			value: 'posgrado',
			label: 'Posgrado',
		},
	];

	const ratingTypeData = [
		{
			value: 'cuantitativa',
			label: 'Cuantitativa',
		},
		{
			value: 'cualitativa',
			label: 'Cualitativa',
		},
	];

	return (
		<Box
			component="form"
			sx={ {
				'& .MuiTextField-root': { width: '100%' },
			} }
			noValidate
			autoComplete="off"
		>

			<Typography variant="h6" sx={ { mb: 3 } } >
				1. Identificación de la asignatura:
			</Typography>
			<Grid container spacing={ { xs: 3 } } columns={ { xs: 1, sm: 8, md: 12 } }>
				<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
					<TextField
						disabled={ subjectByProposedEdition }
						id="subjectName"
						name="NOMBRE"
						label="Nombre de asignatura*"
						defaultValue={ formData.NOMBRE || '' }
						size='small'
						error={!formData.NOMBRE && validate}
						helperText=''
						maxLength={ 100 }
						onChange={ handleChange }
					/>
				</Grid>

				{ campusesData.length > 0 &&
					<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
						<TextField
							disabled
							select
							required
							id="campus"
							name="SEDE"
							label="Sede"
							value={ formData.SEDE ?? '' }
							size='small'
							helperText=''
							onChange={ ( e ) => handleCampusChange( e ) }
						>
							{ campusesData.map( ( option ) => (
								<MenuItem key={ option.ID } value={ option.NOMBRE }>
									{ option.NOMBRE }
								</MenuItem>
							) ) }
						</TextField>
					</Grid>
				}

				{ faculties.length > 0 &&
					<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
						<TextField
							disabled
							select
							required
							id="faculty"
							name="FACULTAD"
							label="Facultad"
							value={ formData.FACULTAD ?? '' }
							size='small'
							helperText=''
							onChange={ ( e ) => handleFacultyChange( e ) }>
							{ faculties.map( ( option ) => (
								<MenuItem key={ option.id } value={ option.NOMBRE }>
									{ option.NOMBRE }
								</MenuItem>
							) ) }
						</TextField>
					</Grid>
				}

				{ basicUnits.length > 0 &&
					<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
						<TextField
							disabled
							select
							required
							id="basicUnitOffers"
							name="UNIDADACADEMICABASICA"
							label="Unidad básica que ofrece"
							value={ formData.UNIDADACADEMICABASICA ?? '' }
							size='small'
							helperText=''
							onChange={ ( e ) => handleBasicUnit( e ) }>
							{ basicUnits.map( ( option ) => (
								<MenuItem key={ option.NOMBRE } value={ option.NOMBRE }>
									{ option.NOMBRE }
								</MenuItem>
							) ) }
						</TextField>
					</Grid>
				}

				<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
					<TextField
						disabled={ subjectByProposedEdition }
						id="level"
						name="NIVEL"
						required
						select
						label="Nivel"
						value={ formData.NIVEL ?? '' }
						error={!formData.NIVEL && validate}
						helperText=""
						size="small"
						onChange={ handleChange } >
						{ levelData.map( ( option ) => (
							<MenuItem key={ option.value } value={ option.value }>
								{ option.label }
							</MenuItem>
						) ) }
					</TextField>
				</Grid>

				<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
					<TextField
						disabled={ subjectByProposedEdition }
						id="ratingType"
						name="TIPOCALIFICACION"
						required
						select
						label="Tipo de calificación"
						value={ formData.TIPOCALIFICACION ?? '' }
						error={!formData.TIPOCALIFICACION && validate}
						helperText=""
						size="small"
						onChange={ handleChange } >
						{ ratingTypeData.map( ( option ) => (
							<MenuItem key={ option.value } value={ option.value }>
								{ option.label }
							</MenuItem>
						) ) }
					</TextField>
				</Grid>

				<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
					<FormControlLabel
						control={
							<Checkbox
								name="isNacionalSubject"
								disabled={ subjectByProposedEdition }
								onChange={ ( e ) => handleCheckboxChange( e, 'ASIGNATURA_NACIONAL' ) }
								checked={ !!formData.ASIGNATURA_NACIONAL }
							/>
						}
						label="La asignatura es nacional"
					/>
				</Grid>

				<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
					<FormControlLabel
						control={
							<Checkbox
								name="VALIDABLE"
								onChange={ ( e ) => handleCheckboxChange( e, 'VALIDABLE' ) }
								checked={ !!formData.VALIDABLE }
							/>
						}
						label="La asignatura es validable"
					/>
				</Grid>
			</Grid>

			{/* Seccion de duración y porcentaje de asistencia */ }
			<DurationAndAttendancePercentage
				formData={ formData }
				setFormData={ setFormData }
				subjectByProposedEdition={ subjectByProposedEdition }
				validate={ validate } />

			{ formData.NIVEL == 'pregrado' &&
				<Grid item xs={ 12 } sx={ { mt: 5, mb: 3 } }>
					<Typography gutterBottom variant="h6">
						4. Libre elección
					</Typography>
					<Grid item xs={ 12 }>
						<FormControlLabel
							control={
								<Checkbox
									name="ASIGNATURALIBRELECCION"
									disabled={ subjectByProposedEdition }
									onChange={ ( e ) => handleCheckboxChange( e, 'ASIGNATURALIBRELECCION' ) }
									checked={ !!formData.ASIGNATURALIBRELECCION }
								/>
							}
							label="La asignatura es de LIBRE ELECCION?"
						/>
					</Grid>
				</Grid>
			}
		</Box>
	)
}
