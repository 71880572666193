import { createTheme } from '@mui/material';

// Create a theme instance.
export const principalTheme = createTheme( {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      tabletPortrait: 768,
    },
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#ED362F',
      light: '#FD463F',
      lighter: '#FFC8C6',
      dark: '#C72D28',
      darker: '#AD2723',
      contrastText: '#fff',
    },
  },
} );