import { LoginForm } from '../../components/LoginForm';
import { Header, FooterLayout } from '../../../layouts/index.js';
import { Container, Box } from '@mui/material';
import Grid from '@mui/material/Grid';

import './Authentication.css';
import { useState } from 'react';
import { RecoverPasswordForm } from '../../components/RecoverPasswordForm.jsx';

export const Authentication = () => {
  const [formType, setFormType] = useState('login');
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Box
        sx={{
          flex: '1 0 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth='lg'>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <div className='login-container'>
              <div className='login-form'>
                <img src='./logo.png' alt='Logo' className='logo' />
                { formType === 'login' ? (
                  <>
                    <h3>Ingresa</h3>
                    <LoginForm />
                  </>
                ) : (
                  <>
                    <h3>Olvidaste tu contraseña</h3>
                    <RecoverPasswordForm />
                  </>
                )}
              </div>
              <div id='formFooter'>
                { formType === 'login' ? (
                    <a className='underlineHover' onClick={() => { setFormType('recoverPassword'); }}>
                      ¿Olvidaste tu constraseña?
                    </a>
                  ) : (
                  <a className='underlineHover' onClick={() => { setFormType('login'); }}>
                    Login
                  </a>
                )}
              </div>
            </div>
          </Grid>
        </Container>
      </Box>
      <FooterLayout />
    </Box>
  );
};
