/**
 * 1 Creada
  2 Revision director area curricular
  3 Devolución director area curricular
  4 Revision secretaria academica
  5 Devolución secretaria academica
  6 Revision direccion academica de sede
  7 Devolucion direccion academica de sede
  8 Asignatura aceptada por direccion academica de sede
*/
const SubjectByState = {
  Created: 1,
  ReviewCurriculumAreaDirector: 2,
  RejectedByCurriculumAreaDirector: 3,
  ReviewAcademicSecretary: 4,
  RejectedByAcademicSecretary: 5,
  ReviewAcademicDepartmentSite: 6,
  RejectedByAcademicDepartmentSite: 7,
  SubjectAcceptedByAcademicDepartmentSite: 8,
  SubjectDraft: 15
}

export default SubjectByState;