import createAxiosInstance from '../../utils/AxiosConfig';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/providers/AuthProvider';
import Roles from '../../enums/Roles.enum';

export const UseLogicSubjectsApproved = () =>
{

  const auth = useAuth();
  const user = auth.getUser();

  const [ subjects, setSubjects ] = useState( [] );
  const [ filteredSubjects, setFilteredSubjects ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( true );
  const [ openDialog, setOpenDialog ] = useState( false );

  const [ term, setTerm ] = useState( '' );
  const [ searchCriteria, setSearchCriteria ] = useState( 'NOMBRE' );
  const [ subjectByProposedEdition, setSubjectByProposedEdition ] = useState(false);
  const [ searchCriteriaData, setSearchCriteriaData ] = useState( [
    {
      value: 'NOMBRE',
      label: 'Nombre',
    },
    {
      value: 'CODIGO_ASIGNATURA',
      label: 'Código asignatura',
    },
    // {
    //   value: 'sede',
    //   label: 'Sede',
    // },
    // {
    //   value: 'facultad',
    //   label: 'Facultad',
    // },
    // {
    //   value: 'unidadAcademicaBasica',
    //   label: 'UAB',
    // }
  ] );
  const navigate = useNavigate();

  useEffect( () =>
  {

    const searchCriteriaDataByRol = () => {
      if (user.role.toLowerCase() === Roles.DirectCurricularArea.toLowerCase()) {
        setSearchCriteriaData([
          {
            value: 'nombre',
            label: 'Nombre',
          },
          {
            value: 'sede',
            label: 'Sede',
          },
          {
            value: 'facultad',
            label: 'Facultad',
          },
        ])
      }
    }

    const fetchSubjects = async () =>
    {
      try
      {
        const { data } = await createAxiosInstance().get( `/subject-approved/by-token/1` );

        setFilteredSubjects( data );
        setSubjects( data );

        setIsLoading( false );
      } catch ( error )
      {
        setIsLoading( false );
        handleClickOpenDialog();
      }
    };
    // searchCriteriaDataByRol();
    setSubjectByProposedEdition(user.role === Roles.Teacher);
    fetchSubjects();
  }, [] );

  const handleClickOpenDialog = () =>
  {
    setOpenDialog( true );
  };

  const handleCloseDialog = () =>
  {
    setOpenDialog( false );
  };

  const handleSearchCriteria = ( e ) =>
  {
    setSearchCriteria( e.target.value );
    handleFilteredSubjects( e.target.value );
  }

  const handleSearchTerm = ( e ) =>
  {
    e.preventDefault();
    handleFilteredSubjects( searchCriteria );
  }

  const normalizeString = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  }

  const handleFilteredSubjects = ( searchCriteria ) =>
  {
    const filterdSubjects = subjects.filter( subject =>
      normalizeString(subject[ searchCriteria ]).includes( normalizeString(term) )
    );
    setFilteredSubjects( filterdSubjects );
  }

  /**
   * 
   * Redireccionar a la vista para proponer una propuesta de edición de una asignatura aprobada
   */
  const handleSubjectEdit = ( subject ) => {
    navigate( `/subject-proposal-edit`, {
      replace: true,
      state: {
        subject,
        subjectByProposedEdition,
      }
    } );
  }

  return {
    filteredSubjects,
    isLoading,
    handleCloseDialog,
    openDialog,
    setTerm,
    handleSearchCriteria,
    handleSearchTerm,
    subjectByProposedEdition,
    handleSubjectEdit,
    searchCriteriaData,
  }
}