import createAxiosInstance from '../../utils/AxiosConfig';
import { Box, Typography, Alert } from '@mui/material';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState, useEffect } from 'react';
import GeneralInformationForm from './components/GeneralInformationForm';
import ContentAndDescriptionForm from './components/ContentAndDescriptionForm';
import SubjectProgramForm from './components/SubjectProgramForm';
import { WarningAlert } from './components/WarningAlert';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { StudyPlansList } from './components/StudyPlansList';
import { useAuth } from '../../auth/providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import SubjectByState from '../../enums/SubjectByState.enum';
import { controlInterfaceCreateSubjectByRol, getCampusAndFacultyFromUAB } from '../../helpers/Subject.helper';
import AlertDialog from '../../shared/components/AlertDialog';
import { validationsPerField } from '../../shared/config/validations-per-field';
import { LearningAchievements } from './components/learning-achievements/LearningAchievements';
import DialogConfirmSubjectAsDraft from '../drafts/DialogConfirmSubjectAsDraft';

function CustomTabPanel ( props )
{

  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={ value !== index }
      id={ `simple-tabpanel-${ index }` }
      aria-labelledby={ `simple-tab-${ index }` }
      { ...other }
    >
      { value === index && (
        <Box>
          { children }
        </Box>
      ) }
    </div>
  );
}

function a11yProps ( index )
{
  return {
    id: `simple-tab-${ index }`,
    'aria-controls': `simple-tabpanel-${ index }`,
  };
}

export const SubjectProposal = ( { subject, subjectByProposedEdition, editSubjectByEditingProposal, isDraftSubject } ) =>
{
  const auth = useAuth();
  const user = auth.getUser();
  const navigate = useNavigate();

  const [ isLoading, setIsLoading ] = useState( false );
  const [ openDialog, setOpenDialog ] = useState( false );
  const [ dialogMessage, setDialogMessage ] = useState( {
    title: '',
    type: 'info',
    description: '',
    primaryButtonText: '',
    redirectTo: '',
  } );

  const [ interfaceControl, setInterfaceControl ] = useState( {
    isValid: true,
    reason: '',
  } );
  const [ campusesData, setCampusesData ] = useState( [] );
  const [ statesForms, setStatesForms ] = useState( {
    generalInformForm: {
      isValid: false,
    },
    studyPlansForm: {
      isValid: false,
    },
    learningAchievementsForm: {
      isValid: false,
    },
    subjectProgramForm: {
      isValid: false,
    },
    contentAndBibliographyForm: {
      isValid: false,
    },
  } );
  const [ validateAllFields, setValidateAllFields ] = useState({
    generalInformForm: {
      validate: false,
    },
    studyPlansForm: {
      validate: false,
    },
    learningAchievementsForm: {
      validate: false,
    },
    subjectProgramForm: {
      validate: false,
    },
    contentAndBibliographyForm: {
      validate: false,
    },
    requirementsForm: {
      validate: false,
    }
  });
  const [ subjects, setSubjects ] = useState( [] );

  const [ GeneralInformForm, setGeneralInformForm ] = useState( {
    idSubjectState: SubjectByState.ReviewCurriculumAreaDirector, // se actualiza a director area curricular
    idDocente: user.id,
    idUAB: null,
    NOMBRE: '', // nombre asignatura
    SEDE: '',
    FACULTAD: '',
    TIPOCALIFICACION: '',
    ASIGNATURA_NACIONAL: false,
    UNIDADACADEMICABASICA: '',
    NIVEL: '',
    VALIDABLE: false,
    ASIGNATURALIBRELECCION: false,
    HAP: '', // Horas de Actividad Presencial a la semana o intensidad horaria
    HAI: '', // Horas de Actividad Presencial a la semana o intensidad horaria
    THS: '', // Total Horas de actividad académica por Semana
    NUM_SEMANAS: '',
    THP: '', // Total Horas de actividad académica por Semana
    NUM_CREDITOS: '',
    PORCENTAJE_ASISTENCIA: '',
    HAPXSEMANA: '', // Horas presenciales
    MINIMOHORAS: '',
  } );

  const [ StudyPlansForm, setStudyPlansForm ] = useState( {
    planes: [],
    requisitos: [], // requisitos
  } );

  const [ LearningAchievementsForm, setLearningAchievementsForm ] = useState( {
    resultadosAprendizajePorPlan: [],
    resultadosAprendizajeAsignatura: [],
  } );

  const [ SubjectProgForm, setSubjectProgForm ] = useState( {
    DESCRIPCIONASIGNATURA: '',
    OBJETIVOS: '',
    METODOLOGIA: '',
  } );

  const [ ContentAndDescForm, setContentAndDescForm ] = useState( {
    DContenidos: [],  // semanas
    BIBLIOGRAFIAS: JSON.stringify( [] ),
  } );


  useEffect( () =>
  {

    const fetchData = async () =>
    {
      try
      {
        await fetchCampuses();
        subjectValidate();
        await fetchSubjects();
      } catch ( error )
      {
        console.error( 'Error:', error );
      }
    };

    const fetchInterfaces = async ( campusesData ) =>
    {
      try
      {
        const idUAB = user.idUab;
        const detail = getCampusAndFacultyFromUAB( idUAB, campusesData );
        const { status, data } = await createAxiosInstance().get( `/t-control-interface/sede/${ detail.campus.id }` );
        if ( status === 200 )
        {
          const userRole = user.role;
          const res = controlInterfaceCreateSubjectByRol( data, userRole );
          setInterfaceControl( {
            isValid: res.isValid,
            reason: res.reason,
          } );
        }
      } catch ( error )
      {
        console.error( error );
        // setDialogMessage(
        //   {
        //     title: 'Error al procesar la petición',
        //     type: 'error',
        //     description: '',
        //     primaryButtonText: 'Aceptar',
        //     redirectTo: '',
        //   }
        // );
        // setOpenDialog( true );
      }
    }

    const fetchCampuses = async () =>
    {
      try
      {
        const idUAB = user.idUab;
        const { status, data } = await createAxiosInstance().get( `/sede` );
        const detail = getCampusAndFacultyFromUAB( idUAB, data );

        if ( status === 200 )
        {
          setCampusesData( data );

          if ( !subject && detail )
          {
            await fetchInterfaces( data );
            setGeneralInformForm( prevFormData => (
              {
                ...prevFormData,
                idUAB: detail.UAB.id,
                SEDE: detail.campus.name,
                FACULTAD: detail.faculty.name,
                UNIDADACADEMICABASICA: detail.UAB.name,
              }
            ) );
          }

        }

      } catch ( error )
      {
        console.error( error );
        setDialogMessage(
          {
            title: 'Error al procesar la petición',
            type: 'error',
            description: '',
            primaryButtonText: 'Aceptar',
            redirectTo: '/subject-approved',
          }
        );
        setOpenDialog( true );
      }
    };

    const fetchSubjects = async () =>
    {
      try
      {
        const { data } = await createAxiosInstance().get( `/subject-approved/by-token/1` );
        const subjects = data.map(subject => {
          return {
            CODIGO_ASIGNATURA: subject.CODIGO_ASIGNATURA,
            IDIDENTIFICADORASIGNATURA: subject.IDIDENTIFICADORASIGNATURA,
            NOMBRE: subject.NOMBRE,
          }
        })
        setSubjects( subjects );
      } catch ( error )
      {
        console.error( error );
        setDialogMessage(
          {
            title: 'Error al procesar la petición',
            type: 'error',
            description: '',
            primaryButtonText: 'Aceptar',
            redirectTo: '/subject-approved',
          }
        );
        setOpenDialog( true );
      }
    };

    const subjectValidate = () =>
    {

      if ( subject )
      {
        const generalInforFormData = {
          idSubjectState: SubjectByState.ReviewCurriculumAreaDirector, // se actualiza a director area curricular
          idDocente: user.id,
          idUAB: subject.idUAB,
          NOMBRE: subject.NOMBRE, // nombre asignatura
          SEDE: subject.SEDE,
          FACULTAD: subject.FACULTAD,
          UNIDADACADEMICABASICA: subject.UNIDADACADEMICABASICA,
          NIVEL: subject.NIVEL,
          VALIDABLE: !!subject.VALIDABLE,
          ASIGNATURALIBRELECCION: !!subject.ASIGNATURALIBRELECCION,
          HAP: +subject.HAP, // Horas de Actividad Presencial a la semana o intensidad horaria
          HAI: +subject.HAI, // Horas de Actividad Presencial a la semana o intensidad horaria
          THS: +subject.THS, // Total Horas de actividad académica por Semana
          NUM_SEMANAS: +subject.NUM_SEMANAS,
          THP: +subject.THP, // Total Horas de actividad académica por Semana
          NUM_CREDITOS: +subject.NUM_CREDITOS,
          PORCENTAJE_ASISTENCIA: +subject.PORCENTAJE_ASISTENCIA,
          HAPXSEMANA: +subject.HAPXSEMANA, // Horas presenciales
          MINIMOHORAS: +subject.MINIMOHORAS,
          TIPOCALIFICACION: subject.TIPOCALIFICACION,
          ASIGNATURA_NACIONAL: subject.ASIGNATURA_NACIONAL,
        };
        setGeneralInformForm( generalInforFormData );

        setStudyPlansForm( studyPLansMapper(subject.studyPlan) );

        setLearningAchievementsForm({
          resultadosAprendizajePorPlan: learningAchievementsFormData(subject.resultadoAprendizaje, 'plans'),
          resultadosAprendizajeAsignatura: learningAchievementsFormData(subject.resultadoAprendizaje),
        });

        setSubjectProgForm( {
          DESCRIPCIONASIGNATURA: subject.DESCRIPCIONASIGNATURA,
          OBJETIVOS: subject.OBJETIVOS,
          METODOLOGIA: subject.METODOLOGIA,
        } );

        setContentAndDescForm( {
          DContenidos: subject.content,  // semanas
          BIBLIOGRAFIAS: subject.BIBLIOGRAFIAS,
        } );
      }
    };
    fetchData();
  }, [] );

  const studyPLansMapper = (studyPlansData) => {
    const studyPlans = {
      planes: [],
      requisitos: subject.subjectsRequirement, // requisitos
    };
    if (!studyPlansData?.length) {
      return studyPlans;
    }
    studyPlans.planes = studyPlansData.map(plan => {
      return {
        ...plan,
        isValid: true,
      }
    });

    return studyPlans;
  }

  const learningAchievementsFormData = (learningAchievements, type) => {
    if (!learningAchievements.length) {
      return [];
    }

    if (type === 'plans') {
      return learningAchievements.filter(learning => !learning.aspectoMacro || (learning.aspectoMacro && learning.aspectoMacro.search('items') !== -1));
    }

    return learningAchievements.filter(learning => learning.aspectoMacro);
  }

  const [ value, setValue ] = useState( 0 );

  const isRequirementsValid = (requirements) => {
    if (requirements.length === 1) {
      const requirement = requirements[0];
      const { CODIGO_ASIGNATURA, NOMBRE_ASIGNATURA, TIPO_REQUISITO, isValid } = requirement;
      // Verifica si todos los campos están vacíos
      const areAllEmpty = !CODIGO_ASIGNATURA && !NOMBRE_ASIGNATURA && !TIPO_REQUISITO;
      return areAllEmpty || isValid;
    }

    return requirements.every(requirement => requirement.isValid);
  }

  const isGeneralInformationFormValid = () =>
  {
    const isValidForm = !!( GeneralInformForm.NOMBRE &&
      GeneralInformForm.idUAB &&
      GeneralInformForm.SEDE &&
      GeneralInformForm.FACULTAD &&
      GeneralInformForm.UNIDADACADEMICABASICA &&
      GeneralInformForm.NIVEL &&
      GeneralInformForm.HAP &&
      GeneralInformForm.HAI &&
      GeneralInformForm.NUM_SEMANAS &&
      GeneralInformForm.NUM_CREDITOS &&
      GeneralInformForm.PORCENTAJE_ASISTENCIA &&
      GeneralInformForm.HAPXSEMANA &&
      GeneralInformForm.TIPOCALIFICACION);

    setValidateAllFields(validate => (
      {
        ...validate,
        generalInformForm: { validate: !isValidForm },
       
      }
    ));

    setStatesForms( states => ( {
      ...states,
      generalInformForm: {
        isValid: isValidForm,
      }
    } ) );
    return isValidForm;
  }

  const isStudyPlansFormValid = () => {
    const requirementsValidate = isRequirementsValid(StudyPlansForm.requisitos);
    const isValidForm = StudyPlansForm.planes.length > 0 && StudyPlansForm.planes.every(plan => plan.isValid) && requirementsValidate;

    setValidateAllFields(validate => (
      {
        ...validate,
        studyPlansForm: { validate: !isValidForm},
        requirementsForm: { validate: !requirementsValidate}
      }
    ));

    setStatesForms( states => ( {
      ...states,
      studyPlansForm: {
        isValid: isValidForm,
      }
    } ) );

    return isValidForm;
  }

  const areMacroAspectValid = (macroAspect) => {
    if (!macroAspect) {
      return true;
    }
    const regex = /<[^>]+>/g;
    const macroAspects = JSON.parse(macroAspect).items;
    return macroAspects.every(aspectMacro => aspectMacro.aspectoMacro?.replace(regex, "")?.trim());
  }

  const isLearningAchievementsValid = () => {
    const regex = /<[^>]+>/g;
    const isValidForm = StudyPlansForm.planes.length ?
      LearningAchievementsForm.resultadosAprendizajePorPlan.length > 0 && LearningAchievementsForm.resultadosAprendizajePorPlan.every(learning => learning.nombrePlan &&
        learning.resultadoAprendizaje?.replace(regex, "")?.trim() && areMacroAspectValid(learning.aspectoMacro)) :
        true;
    
    const isValidForm2 = !StudyPlansForm.planes.length ?
      LearningAchievementsForm.resultadosAprendizajeAsignatura.length > 0 &&
      LearningAchievementsForm.resultadosAprendizajeAsignatura.every(learning => learning.aspectoMacro?.replace(regex, "")?.trim()) :
      true;

    const isValid = (isValidForm && isValidForm2);

    setValidateAllFields(validate => (
      {
        ...validate,
        learningAchievementsForm: { validate: !isValid}
      }
    ));

    setStatesForms( states => ( {
      ...states,
      learningAchievementsForm: {
        isValid: isValid,
      }
    } ) );

    return isValid;
  }

  const handleLearningAchievementsValidate = (validate) => {
    setValidateAllFields(validators => (
      {
        ...validators,
        learningAchievementsForm: { validate }
      }
    ));
  }

  const fieldCompliesWithValidation = (field, fieldValidations) => {
    return field.trim().length >= fieldValidations.min && field.trim().length <= fieldValidations.max;
  }

  const isSubjectProgramFormValid = () =>
  {
    const regex = /<[^>]+>/g;
    const subjectProgramForm = !!(
      fieldCompliesWithValidation(SubjectProgForm.DESCRIPCIONASIGNATURA.replace( regex, '' ), validationsPerField.subjectDescription) &&
      fieldCompliesWithValidation(SubjectProgForm.OBJETIVOS.replace( regex, '' ), validationsPerField.subjectGoals) &&
      fieldCompliesWithValidation(SubjectProgForm.METODOLOGIA.replace( regex, '' ), validationsPerField.subjectTeachingMethodology) );

    setValidateAllFields(validate => (
      {
        ...validate,
        subjectProgramForm: { validate: !subjectProgramForm }
      }
    ));

    setStatesForms( states => ( {
      ...states,
      subjectProgramForm: {
        isValid: subjectProgramForm,
      }
    } ) );
    return subjectProgramForm;
  }

  const isContentAndDescFormValid = () =>
  {
    const bibliografias = JSON.parse( ContentAndDescForm.BIBLIOGRAFIAS );
    const contentAndDescForm = !!( ContentAndDescForm.DContenidos.length > 0 && ( bibliografias && bibliografias.length > 0 ) );
    setValidateAllFields(validate => (
      {
        ...validate,
        contentAndBibliographyForm: { validate: !contentAndDescForm }
      }
    ));
    setStatesForms( states => ( {
      ...states,
      contentAndBibliographyForm: {
        isValid: contentAndDescForm,
      }
    } ) );
    return contentAndDescForm;
  }

  const handleChange = ( event, newValue ) =>
  {
    setValue( newValue );
  };

  // validar cada paso al momento de darle continuar
  const nextBtn = () =>
  {
    if ( value < 4 )
    {
      // validaciones formulario información general
      if ( value === 0 )
      {
        if ( isGeneralInformationFormValid() ) {
          setValue( value + 1 );
        } else {
          setDialogMessage(
            {
              title: 'Complete los datos de información general',
              type: 'info',
              description: '',
              primaryButtonText: 'Aceptar',
              redirectTo: '',
            }
          );
          setOpenDialog( true );
          return;
        }
      }

      // validaciones formulario programa de asignatura
      if ( value === 1 )
      {
        if ( isSubjectProgramFormValid() )
        {
          setValue( value + 1 );
        } else
        {
          setDialogMessage(
            {
              title: 'Complete los datos del programa de asignatura',
              type: 'info',
              description: '',
              primaryButtonText: 'Aceptar',
              redirectTo: '',
            }
          );
          setOpenDialog( true );
          return;
        }
      }

       // validaciones formulario contenidos y bibliografias
       if ( value === 2 ) {
        if (isContentAndDescFormValid() && !isAllowedPlansTabOption()) {
          setStatesForms( states => ( {
            ...states,
            studyPlansForm: {
              isValid: true,
            }
          } ) );
          setValue( value + 2 );
        } else {
          if ( isContentAndDescFormValid() ) {
            setValue( value + 1 );
          } else {
            setDialogMessage(
              {
                title: 'Complete los datos de los contenidos y las bibliografías',
                type: 'info',
                description: '',
                primaryButtonText: 'Aceptar',
                redirectTo: '',
              }
            );
            setOpenDialog( true );
            return;
          }
        }
      }

      // validaciones formulaio planes de estudio
      if ( value === 3 )
      {
        if (isStudyPlansFormValid()) {
          setValue( value + 1 );
        } else {
          setDialogMessage(
            {
              title: 'Complete los planes de estudio o los requisitos',
              type: 'info',
              description: '',
              primaryButtonText: 'Aceptar',
              redirectTo: '',
            }
          );
          setOpenDialog( true );
          return;
        }
      }
    }
  }

  const prevBtn = () =>
  {
    if ( value > 0 )
    {
      if ( value === 4 && hidePlansTab() )
      {
        setValue( value - 2 );
      } else
      {
        setValue( value - 1 );
      }
    }
  }

  const getRedirectTo = () =>
  {
    if (subjectByProposedEdition )
    {
      return '/subjects-by-proposed-edition'
    }

    if ( !editSubjectByEditingProposal && !subjectByProposedEdition )
    {
      return '/subjects-teacher';
    }
  }

  const sendDataForm = async ( infoFormulario ) =>
  {
    try
    {
      setIsLoading( true );
      let response;
      if ( subject && !isDraftSubject)
      {
        // actualizar propuesta de edición
        if ( editSubjectByEditingProposal && subjectByProposedEdition )
        {
          response = await createAxiosInstance().patch( `/auxiliary/actualizar-edicion/${ subject.IDIDENTIFICADORASIGNATURA }`, infoFormulario ); // Crear propuesta de edición
        }
        if ( subjectByProposedEdition && !editSubjectByEditingProposal )
        {
          // Enviar propuesta de edición
          response = await createAxiosInstance().patch( `/auxiliary/actualizar-asignatura/${ subject.id }`, infoFormulario ); // Crear propuesta de edición
        }
        // actualizar una propuesta de creación cuando es rechazada por algun eslabón
        if ( !editSubjectByEditingProposal && !subjectByProposedEdition )
        {
          response = await createAxiosInstance().patch( `/subjects/update-simple/${ subject.IDIDENTIFICADORASIGNATURA }`, infoFormulario ); // actualizar un propuesta de creación
        }
      } else
      {
        response = await createAxiosInstance().post( `/subjects`, infoFormulario ); // Crear asignatura
      }

      const { status } = response;

      if ( status === 200 || status === 201 )
      {
        setDialogMessage(
          {
            title: 'Datos procesados correctamente',
            type: 'info',
            description: 'La propuesta ha sido enviada al Director de Área Curricular',
            primaryButtonText: 'Aceptar',
            redirectTo: getRedirectTo(),
          }
        );
        setOpenDialog( true );
      }
    } catch ( error )
    {
      setDialogMessage(
        {
          title: 'Error al procesar la solicitud',
          type: 'error',
          description: '',
          primaryButtonText: 'Aceptar',
          redirectTo: '',
        }
      );
      setOpenDialog( true );
      setIsLoading( false );
    }
  };

  const buildLearningAchievementsRequest = (learningAchievementsForm) => {
    let result = [];
    const learningAchievementsByPlan = learningAchievementsForm.resultadosAprendizajePorPlan;
    const learningAchievementsSubject = learningAchievementsForm.resultadosAprendizajeAsignatura;

    result = result.concat(learningAchievementsByPlan).concat(learningAchievementsSubject);
    return result;
  }
  
  const sendDataToAPI = () =>
  {
    const allowed = isAllowedPlansTabOption();
    const isPlansValid = allowed ? isStudyPlansFormValid() : true;
    if ( isGeneralInformationFormValid() && isPlansValid && isLearningAchievementsValid() && isSubjectProgramFormValid() && isContentAndDescFormValid() )
    {
      const learningAchievementsResult = {resultadosAprendizaje: buildLearningAchievementsRequest(LearningAchievementsForm) };

      let infoFormulario = Object.assign( {}, GeneralInformForm, StudyPlansForm, learningAchievementsResult, SubjectProgForm, ContentAndDescForm );
      // console.log(infoFormulario);
      sendDataForm( infoFormulario );
    } else
    {
      setDialogMessage(
        {
          title: 'Hay campos vacios o campos invalidos',
          type: 'info',
          description: '',
          primaryButtonText: 'Aceptar',
          redirectTo: '',
        }
      );
      setOpenDialog( true );
    }
  }

  const hidePlansTab = () =>
  {
    return ( !statesForms.generalInformForm.isValid ) || !isAllowedPlansTabOption();
  }

  const isAllowedPlansTabOption = () =>
  {
    return ( GeneralInformForm.NIVEL === 'posgrado' ||
      ( GeneralInformForm.NIVEL === 'pregrado' &&
        !GeneralInformForm.ASIGNATURALIBRELECCION ) );
  }

  const handleDialog = () =>
  {
    setOpenDialog( false );
    if ( dialogMessage.redirectTo )
    {
      navigate( dialogMessage.redirectTo, { replace: true } );
    }
  }

  const showFormData = () =>
  {
    let infoFormulario = Object.assign( {}, GeneralInformForm, StudyPlansForm, SubjectProgForm, ContentAndDescForm, LearningAchievementsForm );
    console.log( infoFormulario );
    console.log( statesForms );
  }

  return (
    <Box>
      { !interfaceControl.isValid ? (
        <Box sx={ { mb: 8 } }>
          <Box sx={ { mt: 3 } }>
            <Alert severity="warning" icon={ false } fontSize="inherit">
              <Typography variant="body1">
                { interfaceControl.reason }
              </Typography>
            </Alert>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box sx={ { mb: 8 } }>
            <WarningAlert />
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="center">
            <Grid item xs={ 12 } sm={ 12 } md={ 11 }>
              <Box sx={ { mb: 8, textAlign: 'center' } }>

                { subjectByProposedEdition && subject &&
                  <Typography gutterBottom variant="h5" component="h2">
                    Actualizar asignatura por propuesta de edición
                  </Typography>
                }

                { !subjectByProposedEdition && subject && !isDraftSubject &&
                  <Typography gutterBottom variant="h5" component="h2">
                    Actualizar asignatura por devolución
                  </Typography>
                }

                { !subjectByProposedEdition && !subject &&
                  <Typography gutterBottom variant="h5" component="h2">
                    Proceso de solicitud creación asignatura
                  </Typography>
                }

                { subject && isDraftSubject &&
                  <Typography gutterBottom variant="h5" component="h2">
                    Continuar con la propuesta de asignatura
                  </Typography>
                }
                <Tabs value={ value }
                  onChange={ handleChange }
                  aria-label="Pasos para la creación de la asignatura"
                  sx={ { display: 'inline-grid', '.MuiTabs-flexContainer': { overflow: 'auto', justifyContent: { lg: 'center' } }, '.MuiButtonBase-root': { textTransform: 'initial' } } }
                >
                  <Tab label="Información General" { ...a11yProps( 0 ) } />
                  <Tab label="Generalidades de la asignatura" { ...a11yProps( 1 ) } disabled={ !statesForms.generalInformForm.isValid } />
                  <Tab label="Contenido y Bibliografía" { ...a11yProps( 2 ) } disabled={ !statesForms.generalInformForm.isValid || !statesForms.subjectProgramForm.isValid} />
                  <Tab label="Planes de estudio" { ...a11yProps( 3 ) } disabled={ (!statesForms.generalInformForm.isValid || !statesForms.subjectProgramForm.isValid || !statesForms.contentAndBibliographyForm.isValid) || hidePlansTab() } />
                  <Tab label="Resultados de aprendizaje" { ...a11yProps( 4 ) }
                    disabled={ !statesForms.generalInformForm.isValid || !statesForms.subjectProgramForm.isValid || !statesForms.contentAndBibliographyForm.isValid || !statesForms.studyPlansForm.isValid } />
                </Tabs>
              </Box>
              <Box sx={ { textAlign: 'right' } }>
                <p>Campos requeridos*</p>
              </Box>
              <CustomTabPanel value={ value } index={ 0 }>
                { campusesData && campusesData.length > 0 &&
                  <GeneralInformationForm
                    formData={ GeneralInformForm }
                    setFormData={ setGeneralInformForm }
                    campusesData={ campusesData }
                    subjectByProposedEdition={ subjectByProposedEdition }
                    validate={ validateAllFields.generalInformForm.validate }>
                  </GeneralInformationForm>
                }
              </CustomTabPanel>

              <CustomTabPanel value={ value } index={ 1 }>
                <SubjectProgramForm
                  formData={ SubjectProgForm }
                  setFormData={ setSubjectProgForm }
                  subjectByProposedEdition={ subjectByProposedEdition }
                  validate={ validateAllFields.subjectProgramForm.validate }>
                </SubjectProgramForm>
              </CustomTabPanel>

              <CustomTabPanel value={ value } index={ 2 }>
                <ContentAndDescriptionForm
                  formData={ ContentAndDescForm }
                  setFormData={ setContentAndDescForm }
                  subjectByProposedEdition={ subjectByProposedEdition }
                  validate={ validateAllFields.contentAndBibliographyForm.validate }>
                </ContentAndDescriptionForm>
              </CustomTabPanel>

              <CustomTabPanel value={ value } index={ 3 } >
                { subjects.length > 0 &&
                  <StudyPlansList
                    formDataEvent={ StudyPlansForm }
                    setFormDataEvent={ setStudyPlansForm }
                    NIVEL={ GeneralInformForm.NIVEL }
                    subjectByProposedEdition={ subjectByProposedEdition }
                    validate={ validateAllFields.studyPlansForm.validate }
                    subjects={ subjects }
                    requirementsValidate={ validateAllFields.requirementsForm.validate }/>
                }
              </CustomTabPanel>

              <CustomTabPanel value={ value } index={ 4 } >
                <LearningAchievements
                  formDataEvent={ LearningAchievementsForm }
                  setFormDataEvent={ setLearningAchievementsForm }
                  plans={ StudyPlansForm.planes }
                  subjectByProposedEdition={ subjectByProposedEdition }
                  validate={ validateAllFields.learningAchievementsForm.validate }
                  learningAchievementsValidateEmitter = { handleLearningAchievementsValidate }/>
              </CustomTabPanel>

              <Box sx={ { display: 'flex', justifyContent: 'space-between', gap: '8px', p: 3 } }>
                <Box>
                  { !subjectByProposedEdition && !editSubjectByEditingProposal && statesForms.generalInformForm.isValid && (!subject || isDraftSubject) &&
                    <DialogConfirmSubjectAsDraft
                      id={ subject ? subject.IDIDENTIFICADORASIGNATURA : null}
                      generalInformForm={GeneralInformForm}
                      studyPlansForm={StudyPlansForm}
                      learningAchievementsForm={LearningAchievementsForm}
                      subjectProgForm={SubjectProgForm}
                      contentAndDescForm={ContentAndDescForm}></DialogConfirmSubjectAsDraft>
                  }
                </Box>
                  <Box>
                    {/* {<Button type='button' variant="contained" sx={ { mr: 2 } } onClick={ showFormData }>Ver form</Button> } */}

                    { value > 0 && <Button type='button' variant="contained" sx={ { mr: 2 } } onClick={ prevBtn }>Volver</Button> }
                    { value < 4 && <Button type='button' variant="contained" onClick={ nextBtn }>Continuar</Button> }
                    { value === 4 && <Button type='button' variant="contained" color="success" startIcon={ <SaveIcon /> } onClick={ sendDataToAPI } disabled={ isLoading }>Guardar</Button> }
                  </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) }
      <AlertDialog
        openDialog={ openDialog }
        handleCloseDialog={ handleDialog }
        title={ dialogMessage.title }
        type={ dialogMessage.type }
        primaryButtonText={ dialogMessage.primaryButtonText }
        description={ dialogMessage.description }>
      </AlertDialog>
    </Box>
  )
}
