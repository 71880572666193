export const validationsPerField = {
  subjectDescription: {
    max: 15650,
    min: 100
  },
  subjectGoals: {
    max: 10000,
    min: 100
  },
  subjectTeachingMethodology: {
    max: 5000,
    min: 100
  },
  subjectSpecificContent: {
    max: 1500,
    min: 20
  },
  subjectDetailContent: {
    max: 3000,
    min: 20
  },
}
