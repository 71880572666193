import EditNoteIcon from '@mui/icons-material/EditNote';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LogoutIcon from '@mui/icons-material/Logout';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import MenuIcon from '@mui/icons-material/Menu';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import SubjectIcon from '@mui/icons-material/Subject';
import { AppBar, Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import { useState } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import { useAuth } from '../auth/providers/AuthProvider';
import Roles from '../enums/Roles.enum';
import { IsValidShowResourceByRol } from '../router/ProtectedRoute';
import { FooterLayout } from './FooterLayout';
import { Header } from './Header';
import './styles/layout.css';

const drawerWidth = 240;

export default function Shell ( { content } )
{

  const { window } = Window;
  const [ mobileOpen, setMobileOpen ] = useState( false );
  const [ isClosing, setIsClosing ] = useState( false );
  const auth = useAuth();

  const handleDrawerClose = () =>
  {
    setIsClosing( true );
    setMobileOpen( false );
  };

  const handleDrawerTransitionEnd = () =>
  {
    setIsClosing( false );
  };

  const handleDrawerToggle = () =>
  {
    if ( !isClosing )
    {
      setMobileOpen( !mobileOpen );
    }
  };

  const logout = () =>
  {
    auth.resetUserData();
    <Navigate to="/login" />
  }

  const convertTextToUpperCaseByWord = ( text ) =>
  {
    return text
      .split( /(?=[A-Z])/ )
      .map( ( word ) => word.charAt( 0 ).toUpperCase() + word.slice( 1 ) )
      .join( ' ' );
  };

  const drawer = (
    <div>
      <Toolbar />
      <Typography gutterBottom variant="h6" component="div" sx={ { p: 2 } }>
        { auth.getUser().name } { auth.getUser().lastname } - { convertTextToUpperCaseByWord( auth.getUser().role ) }
      </Typography>
      <List>
        {/* <ListItem key={ 'HomeKey' } disablePadding>
          <NavLink to={ '/home' } style={ { textDecoration: 'none', width: '100%' } }>
            <ListItemButton>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary='Home' />
            </ListItemButton>
          </NavLink>
        </ListItem> */}

        { IsValidShowResourceByRol( [
          Roles.Teacher,
          Roles.DirectCurricularArea,
          Roles.AcademicDirection,
          Roles.FacultyCouncil,
          Roles.AcademicSecretary,
          Roles.AcademicDirectionOfHeadquarters ] ) &&
          <>
            <ListItem key={ 'ManageInterfaceControlKey' } disablePadding>
              <NavLink to={ '/manage-interface-control' } style={ { textDecoration: 'none', width: '100%' } }>
                <ListItemButton>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary='Gestionar control de interfaz' />
                </ListItemButton>
              </NavLink>
            </ListItem>
          </>
        }

        { IsValidShowResourceByRol( [ Roles.Teacher, Roles.FacultyCouncil, Roles.Admin ] ) &&
          <>
            <ListItem key={ 'requestByProposedEditionKey' } disablePadding>
              <NavLink to={ '/requests-by-proposed-edition' } style={ { textDecoration: 'none', width: '100%' } }>
                <ListItemButton>
                  <ListItemIcon>
                    <LowPriorityIcon />
                  </ListItemIcon>
                  <ListItemText primary='Solicitudes por propuesta de edición' />
                </ListItemButton>
              </NavLink>
            </ListItem>

            <ListItem key={ 'pendingRequestsKey' } disablePadding>
              <NavLink to={ '/pending-requests' } style={ { textDecoration: 'none', width: '100%' } }>
                <ListItemButton>
                  <ListItemIcon>
                    <LowPriorityIcon />
                  </ListItemIcon>
                  <ListItemText primary='Solicitudes pendientes' />
                </ListItemButton>
              </NavLink>
            </ListItem>
          </>
        }

        <ListItem key={ 'subjectApprovedKey' } disablePadding>
          <NavLink to={ '/subject-approved' } style={ { textDecoration: 'none', width: '100%' } }>
            <ListItemButton>
              <ListItemIcon>
                <SubjectIcon />
              </ListItemIcon>
              <ListItemText primary='Asignaturas' />
            </ListItemButton>
          </NavLink>
        </ListItem>

        { IsValidShowResourceByRol( [
          Roles.DirectCurricularArea,
          Roles.Admin,
          Roles.AcademicDirection,
          Roles.FacultyCouncil,
          Roles.AcademicSecretary,
          Roles.AcademicDirectionOfHeadquarters ] ) &&
          <>
            <ListItem key={ 'subjectProposalKey' } disablePadding>
              <NavLink to={ '/subject-proposal' } style={ { textDecoration: 'none', width: '100%' } }>
                <ListItemButton>
                  <ListItemIcon>
                    <PostAddIcon />
                  </ListItemIcon>
                  <ListItemText primary='Presentar propuesta de asignatura' />
                </ListItemButton>
              </NavLink>
            </ListItem>


            <ListItem key={ 'subjectsTeacherKey' } disablePadding>
              <NavLink to={ '/subjects-teacher' } style={ { textDecoration: 'none', width: '100%' } }>
                <ListItemButton>
                  <ListItemIcon>
                    <LibraryBooksIcon />
                  </ListItemIcon>
                  <ListItemText primary='Mis propuestas de asignatura' />
                </ListItemButton>
              </NavLink>
            </ListItem>

            <ListItem key={ 'subjectsByProposedEditionKey' } disablePadding>
              <NavLink to={ '/subjects-by-proposed-edition' } style={ { textDecoration: 'none', width: '100%' } }>
                <ListItemButton>
                  <ListItemIcon>
                    <EditNoteIcon />
                  </ListItemIcon>
                  <ListItemText primary='Mis propuestas de edición' />
                </ListItemButton>
              </NavLink>
            </ListItem>

            <ListItem key={ 'subjectsDraftsKey' } disablePadding>
              <NavLink to={ '/subject-drafts' } style={ { textDecoration: 'none', width: '100%' } }>
                <ListItemButton>
                  <ListItemIcon>
                    <EditNoteIcon />
                  </ListItemIcon>
                  <ListItemText primary='Mis borradores' />
                </ListItemButton>
              </NavLink>
            </ListItem>
          </>
        }

        <ListItem key={ 'logoautKey' } disablePadding>
          <ListItemButton onClick={ () => logout() }>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary='Cerrar sesión' />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
      }}>
      <Header />
      <Box sx={ { flex: '1 0 auto', display: { tabletPortrait: 'flex' } } }>
        <AppBar
          position="absolute"
          component="div"
          id="app-bar-navigation"
          sx={ {
            display: { tabletPortrait: 'none' },
            backgroundColor: '#666',
            zIndex: 3,
            width: { tabletPortrait: `calc(100% - ${ drawerWidth }px)` },
            top: '40px',
            height: '56px',
            ml: { tabletPortrait: `${ drawerWidth }px` },
          } }
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={ handleDrawerToggle }
              sx={ { mr: 2, display: { tabletPortrait: 'none' } } }
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography variant="h6" noWrap component="div">
              Responsive drawer
            </Typography> */}
          </Toolbar>
        </AppBar>

        <Box
          component="nav"
          id='navigation-menu'
          sx={ { width: { tabletPortrait: drawerWidth }, flexShrink: { tabletPortrait: 0 } } }
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */ }
          <Drawer
            container={ container }
            variant="temporary"
            open={ mobileOpen }
            onTransitionEnd={ handleDrawerTransitionEnd }
            onClose={ handleDrawerClose }
            ModalProps={ {
              keepMounted: true, // Better open performance on mobile.
            } }
            sx={ {
              display: { xs: 'block', tabletPortrait: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            } }
          >
            { drawer }
          </Drawer>
          <Drawer
            variant="permanent"
            sx={ {
              display: { xs: 'none', tabletPortrait: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, position: 'relative', 'z-index': 1 },
            } }
            open
          >
            { drawer }
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={ { flexGrow: 1, p: 3, width: { tabletPortrait: `calc(100% - ${ drawerWidth }px)` } } }>
          <Toolbar sx={ { display: { tabletPortrait: 'none' } } } />
          { content }
        </Box>
      </Box>
      <FooterLayout />
    </Box>
  )
}
