import { useState, useEffect } from 'react';
import Sources from '../../../enums/Sources.enum';
import { useAuth } from '../../../auth/providers/AuthProvider';
import { getSubjectStateByRol } from '../../../helpers/Subject.helper';
import createAxiosInstance from '../../../utils/AxiosConfig';
import Roles from '../../../enums/Roles.enum';

export const UseLogicSubjectRequests = (source) =>
{
  const auth = useAuth();
  const user = auth.getUser();
  const [ requests, setRequests ] = useState( [] );
  const [ filteredRequests, setFilteredRequests ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( true );
  const [ openDialog, setOpenDialog ] = useState( false );
  const [ term, setTerm ] = useState( '' );
  const [ searchCriteria, setSearchCriteria ] = useState( 'NOMBRE' );
  const [ searchCriteriaData, setSearchCriteriaData ] = useState( [
    {
      value: 'NOMBRE',
      label: 'Nombre',
    },
    // {
    //   value: 'facultad',
    //   label: 'Facultad',
    // },
    // {
    //   value: 'sede',
    //   label: 'Sede',
    // }
  ] );

  useEffect( () =>
  {
    const searchCriteriaDataByRol = () => {
      if (user.role.toLowerCase() === Roles.DirectCurricularArea.toLowerCase()) {
        setSearchCriteriaData([
          {
            value: 'NOMBRE',
            label: 'Nombre',
          }
        ])
      }
    }

    const selectEndpoint = () => {
      // solicitudes por propuesta de edición
      if (source === Sources.SubjectByProposedEdition) {
        return 'edited-subject/subject-by-state';
      }
      // Solicitudes por propuesta de creación (asginaturas pendientes)
      return 'subjects/states-by-token';
    }

    const fetchRequests = async () =>
    {
      try
      {
        const subjectByState = getSubjectStateByRol( user.role );
        const response = await createAxiosInstance().get( `/${selectEndpoint()}/${ subjectByState.review }` );
        setFilteredRequests( response.data );
        setRequests( response.data );
        setIsLoading( false );
      } catch ( error )
      {
        setIsLoading( false );
        handleClickOpenDialog();
      }
    };
    // searchCriteriaDataByRol();
    fetchRequests();
  }, [] );

  const handleClickOpenDialog = () =>
  {
    setOpenDialog( true );
  };

  const handleCloseDialog = () =>
  {
    setOpenDialog( false );
  };

  const handleSearchCriteria = ( e ) =>
  {
    setSearchCriteria( e.target.value );
    handleFilteredSubjects( e.target.value );
  }

  const handleSearchTerm = ( e ) =>
  {
    e.preventDefault();
    handleFilteredSubjects( searchCriteria );
  }

  const normalizeString = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  }

  const handleFilteredSubjects = ( searchCriteria ) =>
  {
    const filterdSubjects = requests.filter( subject =>
      normalizeString(subject[ searchCriteria ]).includes( normalizeString(term) )
    );
    setFilteredRequests( filterdSubjects );
  }


  return {
    filteredRequests,
    isLoading,
    handleCloseDialog,
    openDialog,
    setTerm,
    handleSearchCriteria,
    handleSearchTerm,
    searchCriteriaData
  }
}