import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import
{
  RichTextEditorProvider, RichTextField
} from 'mui-tiptap';
import { Grid, Box, Typography } from '@mui/material';
import RichTextFieldControls from './RichTextFieldControls';
import { useState } from 'react';
import { validationsPerField } from '../../../shared/config/validations-per-field';

export default function SubjectProgramForm ( { formData, setFormData, subjectByProposedEdition, validate } )
{
  const regex = /<[^>]+>/g;

  const [ description, setDescription ] = useState( formData.DESCRIPCIONASIGNATURA.replace( regex, '' ).trim() );
  const [ goals, setGoals ] = useState( formData.OBJETIVOS.replace( regex, '' ).trim() );
  const [ teachingMethodology, setTeachingMethodology ] = useState( formData.METODOLOGIA.replace( regex, '' ).trim() );

  const subjectDescriptionEditor = useEditor( {
    extensions: [ StarterKit ],
    content: formData && formData.DESCRIPCIONASIGNATURA ? formData.DESCRIPCIONASIGNATURA : '',
    onUpdate: ( { editor } ) =>
    {
      const content = editor.getHTML();
      const contentTextPlain = editor.getText().trim();

      setDescription( contentTextPlain );
      setFormData( ( prevFormData ) => ( {
        ...prevFormData,
        [ 'DESCRIPCIONASIGNATURA' ]: content
      } ) );
    },
  } );

  const goalsEditor = useEditor( {
    extensions: [ StarterKit ],
    content: formData && formData.OBJETIVOS ? formData.OBJETIVOS : '',
    onUpdate: ( { editor } ) =>
    {
      const content = editor.getHTML();
      const contentTextPlain = editor.getText().trim();

      setGoals( contentTextPlain );
      setFormData( ( prevFormData ) => ( {
        ...prevFormData,
        [ 'OBJETIVOS' ]: content
      } ) );
    },
  } );

  const teachingMethodologyEditor = useEditor( {
    extensions: [ StarterKit ],
    content: formData && formData.METODOLOGIA ? formData.METODOLOGIA : '',
    onUpdate: ( { editor } ) =>
    {
      const content = editor.getHTML();
      const contentTextPlain = editor.getText().trim();

      setTeachingMethodology( contentTextPlain );
      setFormData( ( prevFormData ) => ( {
        ...prevFormData,
        [ 'METODOLOGIA' ]: content
      } ) );
    },
  } );

  return (
    <Grid item xs={ 12 }>
      <Grid
        container spacing={ { xs: 3 } } sx={ { mb: 5 } }>
        <Grid item xs={ 12 }>
          <Typography gutterBottom variant="h7" component="div">
            Descripción de la asignatura*
          </Typography>
          <RichTextEditorProvider editor={ subjectDescriptionEditor }>
            <RichTextField controls={ <RichTextFieldControls></RichTextFieldControls> } />
          </RichTextEditorProvider>
          <Box display={ 'flex' } justifyContent={ 'space-between' } gap={ '4px' } marginTop={ '4px' } flexWrap={ 'wrap' }>
            <Typography variant="body2" color={ !description && validate || ( description && (description.length < validationsPerField.subjectDescription.min || description.length > validationsPerField.subjectDescription.max) ) ? 'error' : '' }>
              Cantidad min { validationsPerField.subjectDescription.min } y max de caracteres debe ser de { validationsPerField.subjectDescription.max }
            </Typography>
            <Typography variant="body2">
              Cantidad total: { description.length }
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={ 12 } >
          <Box sx={ { position: 'relative' } }>
            <Typography gutterBottom variant="h7" component="div">
              Objetivos*
            </Typography>
            <RichTextEditorProvider editor={ goalsEditor }>
              <RichTextField controls={ <RichTextFieldControls></RichTextFieldControls> } />
            </RichTextEditorProvider>
            <Box display={ 'flex' } justifyContent={ 'space-between' } gap={ '4px' } marginTop={ '4px' } flexWrap={ 'wrap' }>
              <Typography variant="body2" color={ !goals && validate || ( goals && (goals.length < validationsPerField.subjectGoals.min || goals.length > validationsPerField.subjectGoals.max) ) ? 'error' : '' }>
                Cantidad min { validationsPerField.subjectGoals.min } y max de caracteres debe ser de { validationsPerField.subjectGoals.max }
              </Typography>
              <Typography variant="body2">
                Cantidad total: { goals.length }
              </Typography>
            </Box>
            { subjectByProposedEdition &&
              <div style={ { background: 'rgba(0, 0, 0, 0.26)', 'position': 'absolute', 'left': '0', 'top': '0', 'width': '100%', 'height': '100%', zIndex: 3 } }>  </div>
            }
          </Box>
        </Grid>
        <Grid item xs={ 12 } >
          <Box sx={ { position: 'relative' } }>
            <Typography gutterBottom variant="h7" component="div">
              Metodología de enseñanza*
            </Typography>
            <RichTextEditorProvider editor={ teachingMethodologyEditor }>
              <RichTextField controls={ <RichTextFieldControls></RichTextFieldControls> } />
            </RichTextEditorProvider>
            <Box display={ 'flex' } justifyContent={ 'space-between' } gap={ '4px' } marginTop={ '4px' } flexWrap={ 'wrap' }>
              <Typography variant="body2" color={ !teachingMethodology && validate || ( teachingMethodology && (teachingMethodology.length < validationsPerField.subjectTeachingMethodology.min || teachingMethodology.length > validationsPerField.subjectTeachingMethodology.max) ) ? 'error' : '' }>
                Cantidad min { validationsPerField.subjectTeachingMethodology.min } y max de caracteres debe ser de { validationsPerField.subjectTeachingMethodology.max }
              </Typography>
              <Typography variant="body2">
                Cantidad total: { teachingMethodology.length }
              </Typography>
            </Box>
            { subjectByProposedEdition &&
              <div style={ { background: 'rgba(0, 0, 0, 0.26)', 'position': 'absolute', 'left': '0', 'top': '0', 'width': '100%', 'height': '100%', zIndex: 3 } }>  </div>
            }
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
