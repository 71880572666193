import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useState } from 'react';
import SubjectRequirementForm from './SubjectRequirementForm';
import AlertDialog from '../../../shared/components/AlertDialog';
function SubjectRequirementsList ( { formDataEvent, setFormDataEvent, subjects, validate, subjectByProposedEdition } )
{

  const [ openDialog, setOpenDialog ] = useState( false );
  const [ dialogMessage, setDialogMessage ] = useState( {
    title: '',
    type: 'info',
    description: '',
    primaryButtonText: '',
    redirectTo: '',
  } );

  const requirementData = {
    requirementCode: '',
    requirementSubjectName: '',
    requirementType: '',
    isValid: false,
  };

  const frontendModeloToApiModelMapper = ( requirements ) =>
  {
    return requirements.map( requirement =>
    {
      return {
        CODIGO_ASIGNATURA: requirement.requirementCode,
        NOMBRE_ASIGNATURA: requirement.requirementSubjectName,
        TIPO_REQUISITO: requirement.requirementType,
        isValid: requirement.isValid,
      }
    } );
  }

  const apiModelToFrontendModelMapper = ( requirements ) =>
  {
    return requirements.map( requirement =>
    {
      return {
        requirementCode: requirement.CODIGO_ASIGNATURA,
        requirementSubjectName: requirement.NOMBRE_ASIGNATURA,
        requirementType: requirement.TIPO_REQUISITO,
        isValid: true,
      }
    } );
  }

  const [ requirements, setRequirements ] = useState( formDataEvent &&
    formDataEvent.requisitos &&
    formDataEvent.requisitos.length ? apiModelToFrontendModelMapper(formDataEvent.requisitos) : [ requirementData ] );

  const handleAddRequirementEvent = ( requirement, index ) =>
  {
    requirements[ index ] = requirement;
    setRequirements( requirements );

    // const validRequirements = requirements.filter( requirement => requirement.isValid );
    updateFormData( requirements );
  };

  const updateFormData = ( requirements ) =>
  {
    setFormDataEvent( ( prevFormData ) => ( {
      ...prevFormData,
      [ 'requisitos' ]: frontendModeloToApiModelMapper( requirements ),
    } ) );
  }

  const canAddRequirements = () =>
  {
    return requirements.every( requirement => requirement.isValid );
  }

  const hasRepeatCodes = () => {
    const uniqueCodes = new Set();
    let hasDuplicates = false;

    requirements.forEach(item => {
      if (uniqueCodes.has(item.requirementCode)) {
        hasDuplicates = true;
      } else {
        uniqueCodes.add(item.requirementCode);
      }
    });

    return hasDuplicates;
  }

  const addRequirementClick = () =>
  {
    if ( canAddRequirements() && !hasRepeatCodes())
    {
      setRequirements( [ ...requirements, requirementData ] );
    } else
    {
      if (hasRepeatCodes()) {
        setDialogMessage(
          {
            title: 'Los codigos no pueden estar duplicados',
            type: 'info',
            description: '',
            primaryButtonText: 'Aceptar',
            redirectTo: '',
          }
        );
      } else {
        setDialogMessage(
          {
            title: 'No puede agregar mas requisitos sin haber rellenado los campos',
            type: 'info',
            description: '',
            primaryButtonText: 'Aceptar',
            redirectTo: '',
          }
        );
      }
      setOpenDialog( true );
    }
  }

  const handleDeleteEvent = ( index ) =>
  {
    const newRequirements = [ ...requirements ];
    newRequirements.splice( index, 1 );
    setRequirements( [ ...newRequirements ] );

    const validRequirements = newRequirements.filter( week => week.isValid );
    updateFormData( validRequirements );
  }

  const handleDialog = () =>
  {
    setOpenDialog( false );
  }

  const handleRequirements = () =>
  {
    console.log( 'formData', formDataEvent );
    console.log( 'requirements', requirements );
  }

  return (
    <Grid
      container spacing={ { xs: 3 } }>
      { requirements.map( ( requirement, index ) => (
        <Grid item xs={ 12 } key={ index }>
          <SubjectRequirementForm
            subjectByProposedEdition={subjectByProposedEdition}
            sendRequirementEvent={ handleAddRequirementEvent }
            deleteRequirementEvent={ handleDeleteEvent }
            requirement={ { ...requirement } }
            index={ index }
            requirements={ requirements }
            subjects={ subjects }
            validate={ validate }>
          </SubjectRequirementForm>
        </Grid>
      ) ) }
      <Grid item xs={ 12 }>
        <Button startIcon={ <AddCircleIcon /> } sx={ { textTransform: 'initial' } } type='button' onClick={ addRequirementClick } disabled={subjectByProposedEdition}>
          Añadir requisito
        </Button>
        {/* <Button startIcon={ <AddCircleIcon /> } sx={ { textTransform: 'initial' } } type='button' onClick={ handleRequirements }>
          ver todo
        </Button> */ }
      </Grid>
      <AlertDialog
        openDialog={ openDialog }
        handleCloseDialog={ handleDialog }
        title={ dialogMessage.title }
        type={ dialogMessage.type }
        primaryButtonText={ dialogMessage.primaryButtonText }
        description={ dialogMessage.description }>
      </AlertDialog>
    </Grid>
  )
}

SubjectRequirementsList.propTypes = {
  setFormDataEvent: PropTypes.func.isRequired,
  formDataEvent: PropTypes.object.isRequired,
};

export default SubjectRequirementsList;