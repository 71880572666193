export const FooterLayout = () => {
	return (
		<>
			<footer className="container-fluid d-print-table" style={{position: 'relative', flexShrink: 0 }}>
				<div className="row">
					<div className="col-sm-6 col-md-3 d-print-none">
						<nav className="gobiernoLinea">
							<a href="http://www.legal.unal.edu.co" target="_top">Régimen Legal</a>
							<a href="http://personal.unal.edu.co" target="_top">Talento humano</a>
							<a href="https://portaladquisiciones.unal.edu.co" target="_top">Contratación</a>
							<a href="http://personal.unal.edu.co" target="_top">Ofertas de empleo</a>
							<a href="http://launalcuenta.unal.edu.co/" target="_top">Rendición de cuentas</a>
							<a href="http://docentes.unal.edu.co/concurso-profesoral/" target="_top">Concurso docente</a>
							<a href="http://www.pagovirtual.unal.edu.co/" target="_top">Pago virtual</a>
							<a href="http://controlinterno.unal.edu.co/" target="_top">Control interno</a>
							<a href="http://siga.unal.edu.co" target="_top">Calidad</a>
							<a href="https://unal.edu.co/buzon-de-notificaciones/" target="_self">Buzón de notificaciones</a>
						</nav>
					</div>
					<div className="col-sm-6 col-md-3 d-print-none">
						<nav className="gobiernoLinea">
							<a href="https://smartkey.xertica.com/cloudkey/a/unal.edu.co/user/login" target="_top">Correo institucional</a>
							<a href="/mapa-del-sitio">Mapa del sitio</a>
							<a href="http://redessociales.unal.edu.co" target="_top">Redes sociales</a>
							<a href="/faq">Preguntas frecuentes</a>
							<a href="http://quejasyreclamos.unal.edu.co" target="_self">Quejas y reclamos</a>
							<a href="https://unal.edu.co/atencion-en-linea/" target="_self">Servicios en línea</a>
							<a href="/egresados">Encuesta</a>
							<a href="/contactenos">Contáctenos</a>
							<a href="http://estadisticas.unal.edu.co/" target="_top">Estadísticas</a>
							<a href="/glosario">Glosario</a>
						</nav>
					</div>
					<div className="col-sm-7 col-md-4 contder">
						<div className="col contacto">
							<b>Contacto página web:</b><br/>
							Carrera 45 # 26-85<br/>
							Edif. Uriel Gutiérrez<br/>
							Bogotá D.C.,  Colombia<br/>
							(+57 601)  316 5000
						</div>
						<div className="col derechos">
							<a href="https://unal.edu.co/fileadmin/user_upload/docs/legal.pdf" target="_blank">© Copyright 2021</a><br/>
							Algunos derechos reservados.<br/>
							<a href="/contactenos">Contáctenos</a><br/>
							<a href="/acerca-de-este-sitio-web">Acerca de este sitio web</a><br/>
							Actualización: 22/03/2024
						</div>
					</div>

					<div className="col-sm-5 col-md-2 asoc-img d-print-none">
						<div className="container ">
							<div className="row row-cols-2">
								<div className="col">
									<a href="http://orgullo.unal.edu.co/">
										<img className="img-fluid" alt="Orgullo UN" src="https://unal.edu.co/_assets/ca8e1ca9a551c3e3ffc233b30e54ba93/images/log_orgullo.png" width="94" height="37" />
										<img className="d-none d-print-block img-fluid" alt="Orgullo UN" src="https://unal.edu.co/_assets/ca8e1ca9a551c3e3ffc233b30e54ba93/images/log_orgullo_black.png" width="94" height="37" />
									</a>
								</div>
								<div className="col">
									<a href="https://www.gov.co/">
										<img className="img-fluid" alt="Portal Único del Estado Colombiano" src="https://unal.edu.co/_assets/ca8e1ca9a551c3e3ffc233b30e54ba93/images/log_gobiern.png" width="94" height="37" />
									</a>
								</div>
								<div className="col">
									<a className="imgAgencia" href="http://agenciadenoticias.unal.edu.co">
										<img className="img-fluid" alt="Agencia de Noticias" src="https://unal.edu.co/_assets/ca8e1ca9a551c3e3ffc233b30e54ba93/images/log_agenc.png" width="94" height="37"/>
										<img className="d-none d-print-block" alt="Agencia de Noticias" src="https://unal.edu.co/_assets/ca8e1ca9a551c3e3ffc233b30e54ba93/images/log_agenc_black.png" width="94" height="37"/>
									</a>
								</div>
								<div className="col">
									<a href="http://www.contaduria.gov.co/">
										<img className="img-fluid" alt="Contaduría General de la República" src="https://unal.edu.co/_assets/ca8e1ca9a551c3e3ffc233b30e54ba93/images/log_contra.png" width="94" height="37" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}
