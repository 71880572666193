import { Box, Alert, Typography } from '@mui/material';


export const WarningAlert = () => {
    return (
        <>
            <Box sx={{ mt: 3}}>
                <Alert severity="warning" icon={false} fontSize="inherit">
                    <Typography variant="body1">
                        Estimado profesor: Tenga presente que este formato diligenciado y aprobado por el Consejo de Facultad o Comité Académico Administrativo
                        (sedes de presencia nacional) constituye el programa - asignatura, que es un documento oficial. Además, el texto que introduzca se publicará en el portal UNAL
                        para información de los estudiantes y la comunidad académica en general. Le recomendamos atentamente que diligencie el formato con el mayor esmero posible.
                    </Typography>
                </Alert>
            </Box>
        </>
    )
}
