import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginService } from '../services/login.js';
import { useAuth } from '../providers/AuthProvider';

export const LoginForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorResponse, setErrorResponse] = useState('');
  const auth = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data, status } = await loginService(email, password);
      if (status === 201) {
        setErrorResponse('');
        if (!!data.token) {
          const dataMappper = {
            ...data,
            idUab: data.iduab,
          };
          auth.saveUser(dataMappper);
          navigate('/', {
            replace: true,
          });
        }
      }
    } catch (error) {
      setErrorResponse(
        error.statusCode === 401
          ? 'Datos incorrectos'
          : 'Error del servidor, intentalo más tarde'
      );
    }
  };

  return (
    <>
      {!!errorResponse && (
        <div className='alert alert-danger'> {errorResponse} </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <input
            type='email'
            id='email'
            name='email'
            placeholder='Correo Institucional'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className='form-group'>
          <input
            type='password'
            id='password'
            name='password'
            placeholder='Contraseña'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type='submit' className="auth-action_button">Entrar</button>
      </form>
    </>
  );
};
