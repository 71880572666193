import { useAuth } from '../../auth/providers/AuthProvider';
import { Typography } from '@mui/material';

export const HomeView = () => {
	const auth =  useAuth();
	return (
		<>
			<Typography variant='h4' component='h2'> HomeView </Typography>
		</>
	)
}
