import InfoIcon from '@mui/icons-material/Info';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { forwardRef } from 'react';
import { CustomDialog } from '../helpers/styles/CustomDialog';
import { UseLogicManageInterfaceControl } from './hooks/UseLogicManageInterfaceControl';
import { pxToRem } from '../utils/unitsConversion';
import { Controller } from 'react-hook-form';
import { TextField, Button, Typography, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { interfaceList } from '../shared/config/control-interfaces-data';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import AlertDialog from '../shared/components/AlertDialog';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Bogota');

const Transition = forwardRef( function Transition ( props, ref )
{
  return <Slide direction="up" ref={ ref } { ...props } />;
} );

export default function ManageInterfaceControl() {
  const {
    interfaces,
    isLoading,
    handleDialog,
    dialogMessage,
    openDialog,
    openCreateInterfaceDialog,
    handleCreateInterface,
    handleCreateInterfaceCloseDialog,
    handleEditInterface,
    handleDeleteInterface,
    control,
    errors,
    handleSubmit,
    onSubmit,
    Campuses,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    idEstado,
    setIdEstado,
  } = UseLogicManageInterfaceControl();
  
  return (
    <Box>
      { isLoading ? (
        <Box sx={ { display: 'flex', justifyContent: 'center' } }>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Typography variant='h4' component='h2'>Gestionar control de interfaz</Typography>
          <br></br>
          <Box sx={{ textAlign: 'right' }}>
            <Button
              color='primary'
              variant='outlined'
              size='medium'
              onClick={ () => handleCreateInterface() } >Agregar interfaz</Button>
          </Box>

          { interfaces.length ? (
            <TableContainer component={ Paper }
              sx={ {
                mt: 3,
                overflowX: 'auto',
              } }>
              <Table sx={ { minWidth: 650 } } size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Función</TableCell>
                    <TableCell align="left">Descripción(Razón)</TableCell>
                    <TableCell align="left">Vigencia inicial</TableCell>
                    <TableCell align="left">Vigencia final</TableCell>
                    <TableCell align="left">Sede</TableCell>
                    <TableCell align="left">Estado</TableCell>
                    <TableCell align="left">Fecha de modificación</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { interfaces.map( ( interfaceControl ) => (
                    <TableRow
                      key={ interfaceControl.id }
                      sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                    >
                      <TableCell component="th" scope="row">
                        { interfaceList.find(i => i.value === interfaceControl.idinterface) ?
                            interfaceList.find(i => i.value === interfaceControl.idinterface).label : '-' }
                      </TableCell>
                      <TableCell align="left">{ interfaceControl.descripcionInterface }</TableCell>
                      <TableCell align="left">{ dayjs.utc( interfaceControl.vigenciaInicial ).format( 'YYYY-MM-DD' ) ?? '-' }</TableCell>
                      <TableCell align="left">{ dayjs.utc( interfaceControl.vigenciaFinal ).format( 'YYYY-MM-DD' ) ?? '-' }</TableCell>
                      <TableCell align="left">{ Campuses.find(campus => campus.ID === interfaceControl.idSede).NOMBRE ?? '-' }</TableCell>
                      <TableCell align="left">{ interfaceControl.idEstado ? 'Activo' : 'Inactivo' }</TableCell>
                      <TableCell align="left">{ dayjs.utc( interfaceControl.fechaIdEstado ).format( 'YYYY-MM-DD' ) ?? '-' }</TableCell>
                      <TableCell align="left">
                        <Button variant="contained" size="small" sx={{ m: 1, whiteSpace: 'nowrap' }} color='error'
                          onClick={ () => handleDeleteInterface(interfaceControl.id) }>Eliminar</Button>
                        <Button variant="contained" size="small" sx={{ whiteSpace: 'nowrap' }}
                          onClick={ () => handleEditInterface(interfaceControl) }>Actualizar</Button>
                      </TableCell>
                    </TableRow>
                  ) ) }
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box sx={ { mt: 3 } }>
              <Alert severity="info">No se encontraron resultados</Alert>
            </Box>
          ) }
        </Box>
      ) }

      <AlertDialog
				openDialog={ openDialog }
				handleCloseDialog={ handleDialog }
				title={ dialogMessage.title }
				type={ dialogMessage.type }
				primaryButtonText={ dialogMessage.primaryButtonText }
				description={ dialogMessage.description }>
			</AlertDialog>

      <CustomDialog
        maxWidth='lg'
        open={ openCreateInterfaceDialog }
        onClose={ handleCreateInterfaceCloseDialog }
        color='info'
        TransitionComponent={ Transition }
        keepMounted
        aria-labelledby="alert-return-request-dialog-title"
        aria-describedby="alert-return-request-dialog-description"
      >
        <DialogTitle id="alert-return-request-dialog-title" align='center'>
          <InfoIcon />
          <Typography variant="h4" component={ 'span' }>
            Crear control de interfaz
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, pb: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ mb: 3 }}>
                <Controller
                  name="idinterface"
                  control={control}
                  defaultValue=''
                  rules={{ required: 'El id es requerido' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      value={field.value}
                      label="Función*"
                      error={!!errors.idinterface}
                      helperText={errors.idinterface?.message}
                      fullWidth
                    >
                    { interfaceList.map( ( option ) => (
                      <MenuItem key={ option.value } value={ option.value }>
                        { option.label }
                      </MenuItem>
                    ) ) }
                    </TextField>
                  )}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Controller
                  name="descripcionInterface"
                  control={control}
                  rules={{ required: 'La descripción es requerida' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      value={ field.value }
                      minRows={ 3 }
                      label="Descripción(razón)*"
                      error={!!errors.descripcionInterface}
                      helperText={errors.descripcionInterface?.message}
                      fullWidth
                    />
                  )}
                />
              </Box>

              <Box sx={{ mb: 3 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker label="Vigencia inicio" sx={{ width: '100%' }} value={ startDate } onChange={(newValue) => setStartDate(newValue)} />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>

              <Box sx={{ mb: 3 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker label="Vigencia final" sx={{ width: '100%' }} value={ endDate } onChange={(newValue) => setEndDate(newValue)} />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>

              { Campuses && Campuses.length > 0 &&
                <Box sx={{ mb: 3 }}>
                  <Controller
                    name="idSede"
                    defaultValue=''
                    control={control}
                    rules={{ required: 'La sede es requerida' }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Sede*"
                        error={!!errors.idSede}
                        helperText={errors.idSede?.message}
                        fullWidth
                      >
                      { Campuses.map( ( option ) => (
                        <MenuItem key={ option.ID } value={ option.ID }>
                          { option.NOMBRE }
                        </MenuItem>
                      ) ) }
                      </TextField>
                    )}
                  />
                </Box>
              }

              <Box sx={{ mb: 3 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="idEstado"
                      onChange={ ( e ) => setIdEstado( !!e.target.checked ) }
                      checked={ !!idEstado }
                    />
                  }
                  label="Estado(activo o inactivo)"
                />
              </Box>

              { isLoading ? (
                <Box sx={ { display: 'flex', justifyContent: 'center' } }>
                  <CircularProgress />
                </Box>
              ) : (
                <Box sx={{ textAlign: 'center' }}>
                  <Button
                    color='error'
                    variant='outlined'
                    size='medium'
                    sx={{ mr: 3 }}
                    onClick={ () => handleCreateInterfaceCloseDialog() }
                  >Cancelar</Button>
                  <Button type="submit" variant="outlined" color="primary">Enviar</Button>
                </Box>
              ) }
            </form>
          </Box>
        </DialogContent>
      </CustomDialog>
    </Box>
  )
}
