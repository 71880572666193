import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { PregradoStudyPlansForm } from './PregradoStudyPlansForm';
import { PosgradoStudyPlansForm } from './PosgradoStudyPlansForm';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AlertDialog from '../../../shared/components/AlertDialog';
import SubjectRequirementsList from './SubjectRequirementsList';

export const StudyPlansList = ( { formDataEvent, setFormDataEvent, NIVEL, subjectByProposedEdition, subjects, validate, requirementsValidate } ) =>
{

	const planData = {
		codigoPlan: '',
		nombrePlan: '',
		// componente: '',
		agrupacion: '',
		tipologia: '',
		isValid: false,
	};

	const [ plans, setPlans ] = useState( formDataEvent && formDataEvent.planes && formDataEvent.planes.length ? formDataEvent.planes : [ planData ] );
	const [ openDialog, setOpenDialog ] = useState( false );
	const [ dialogMessage, setDialogMessage ] = useState( {
		title: '',
		type: 'info',
		description: '',
		primaryButtonText: '',
		redirectTo: '',
	} );

	const handleAddPlanEvent = ( plan, index ) =>
	{
		plans[ index ] = plan;
		setPlans( plans );
		const validPlans = plans.filter( plan => plan.isValid );
		updateFormData( validPlans );
	};

	const updateFormData = ( plans ) =>
	{
		setFormDataEvent( ( prevFormData ) => ( {
			...prevFormData,
			[ 'planes' ]: plans
		} ) );
	}

	const canAddPlans = () =>
	{
		return plans.every( plan => plan.isValid );
	}

	const addPlanClick = () =>
	{
		if ( canAddPlans() )
		{
			setPlans( [ ...plans, planData ] );
		} else
		{
			setDialogMessage(
				{
					title: 'No puede agregar mas planes de estudio sin haber diligenciado los campos',
					type: 'info',
					description: '',
					primaryButtonText: 'Aceptar',
					redirectTo: '',
				}
			);
			setOpenDialog( true );
		}
	}

	const handleDeleteEvent = ( index ) =>
	{
		const newPlans = [ ...plans ];
		newPlans.splice( index, 1 );
		setPlans( [ ...newPlans ] );

		const validPlans = newPlans.filter( plan => plan.isValid );
		updateFormData( validPlans );
	}

	const handleDialog = () =>
	{
		setOpenDialog( false );
	}

	const handlePlans = () =>
	{
		console.log( 'formData', formDataEvent );
		console.log( 'Planes', plans );
	}

	return (
		<Grid
			sx={ {
				'& .MuiTextField-root': { width: '100%' },
			} }
		>

			<Typography variant="h6" sx={ { mb: 3 } } >
				5. Planes de estudio:
			</Typography>

			{ NIVEL == 'pregrado' &&
				<Grid container spacing={ { xs: 3 } }>
					{ plans.map( ( plan, index ) => (
						<Grid item sm={ 12 } key={ index }>
							<PregradoStudyPlansForm
								sendPlanEvent={ handleAddPlanEvent }
								deletePlanEvent={ handleDeleteEvent }
								subjectByProposedEdition={ subjectByProposedEdition }
								plan={ { ...plan } }
								index={ index }
								plans={ plans }
								validate={ validate } />
						</Grid>
					) ) }
					<Grid item xs={ 12 }>
						<Button startIcon={ <AddCircleIcon /> } sx={ { textTransform: 'initial' } } type='button' onClick={ addPlanClick }>
							Añadir más planes de estudio
						</Button>
					</Grid>

				</Grid>
			}

			{ NIVEL == 'posgrado' &&
				<Grid
					container spacing={ { xs: 3 } }>
					{ plans.map( ( plan, index ) => (
						<Grid item sm={ 12 } key={ index }>
							<PosgradoStudyPlansForm
								sendPlanEvent={ handleAddPlanEvent }
								deletePlanEvent={ handleDeleteEvent }
								subjectByProposedEdition={ subjectByProposedEdition }
								plan={ { ...plan } }
								index={ index }
								plans={ plans }
								validate={ validate } />
						</Grid>
					) ) }
					<Grid item xs={ 12 }>
						<Button startIcon={ <AddCircleIcon /> } sx={ { textTransform: 'initial' } } type='button' onClick={ addPlanClick }>
							Añadir más planes de estudio
						</Button>
					</Grid>
				</Grid>
			}

			{/* Seccion de requisitos de la asignatura */ }
			<Grid item xs={ 12 } sx={ { mt: 5, mb: 3 } }>
				<Typography gutterBottom variant="h6">
					6. Requisitos de la asignaturas
				</Typography>
				<SubjectRequirementsList
					formDataEvent={ formDataEvent }
					setFormDataEvent={ setFormDataEvent }
					subjects={ subjects }
					validate={ requirementsValidate }
					subjectByProposedEdition={ subjectByProposedEdition }/>
			</Grid>

			<AlertDialog
				openDialog={ openDialog }
				handleCloseDialog={ handleDialog }
				title={ dialogMessage.title }
				type={ dialogMessage.type }
				primaryButtonText={ dialogMessage.primaryButtonText }
				description={ dialogMessage.description }>
			</AlertDialog>
		</Grid>
	)
}
