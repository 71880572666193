import { Box, Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FooterLayout, Header } from '../../../layouts/index.js';

import { ResetPasswordForm } from '../../components/ResetPasswordForm.jsx';
import './Authentication.css';

export const ResetPassword = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Box
        sx={{
          flex: '1 0 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth='lg'>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <div className='login-container'>
              <div className='login-form'>
                <img src='./logo.png' alt='Logo' className='logo' />
                <h3>Cambiar contraseña</h3>
                <ResetPasswordForm />
              </div>
            </div>
          </Grid>
        </Container>
      </Box>
      <FooterLayout />
    </Box>
  );
};
