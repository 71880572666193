import axios from 'axios';
import Cookies from 'js-cookie';
import { getEnvVariables } from '../helpers/getEnvVariables';

const createAxiosInstance = (url) => {
  const envVariables = getEnvVariables();

  const instance = axios.create({
    baseURL: url ? url: `${ envVariables.API_URL }`,
    timeout: 5000,
  });

  const token = Cookies.get('X-SRF-TOKEN');
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  return instance;
};


export default createAxiosInstance;