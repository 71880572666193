import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import DinaraApp from './DinaraApp';
import './global.css'

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <BrowserRouter>
            <DinaraApp />
        </BrowserRouter>
    </React.StrictMode>
);