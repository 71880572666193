import createAxiosInstance from '../../utils/AxiosConfig';

export const userService = async () => {
	try {
		const { data, status} = await createAxiosInstance().get(`/users/token`);
		if (status === 200) {
			return data;
		}
	} catch ({ response }) {
		throw response.data;
	}
};

