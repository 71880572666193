import { useCallback } from 'react';
import { Typography, Grid, TextField, FilledInput, FormControl, OutlinedInput, InputAdornment, FormHelperText } from '@mui/material';

export const DurationAndAttendancePercentage = ( { formData, setFormData, subjectByProposedEdition, validate } ) =>
{
	const handleChange = useCallback( ( event ) =>
	{
		const { name, value } = event.target;

		setFormData( ( prevFormData ) =>
		{
			const formattedValue = value.replace( /\D/g, '' );
			let form = {
				...prevFormData,
			}

			if ( name === 'HAP' )
			{
				if ( formattedValue === '' || ( /^\d{0,2}$/.test( formattedValue ) && ( +formattedValue <= 40 ) ) )
				{
					form = {
						...form,
						[ name ]: +formattedValue
					}
				}
			}

			if ( name === 'HAI' )
			{
				if ( formattedValue === '' || ( /^\d{0,2}$/.test( formattedValue ) && ( +formattedValue <= 40 ) ) )
				{
					form = {
						...form,
						[ name ]: +formattedValue
					}
				}
			}

			if ( name === 'NUM_SEMANAS' )
			{
				if ( formattedValue === '' || ( /^\d{0,2}$/.test( formattedValue ) && ( +formattedValue <= 24 ) ) )
				{
					form = {
						...form,
						[ name ]: +formattedValue
					}
				}
			}

			if ( name === 'PORCENTAJE_ASISTENCIA' )
			{
				if ( formattedValue === '' || ( /^\d{0,3}$/.test( formattedValue ) && ( +formattedValue <= 100 ) ) )
				{
					form = {
						...form,
						[ name ]: +formattedValue
					}
				}
			}

			setFields( form );

			return {
				...form,
			}
		} );
	}, [] );

	const setFields = ( form ) =>
	{
		form.THS = setTHS( form );
		form.THP = setTHP( form );
		form.NUM_CREDITOS = setCreditsNumber( form );
		form.HAPXSEMANA = setHAPXSEMANA( form );
		form.MINIMOHORAS = setMinimoHoras( form );

		return form;
	}

	const setTHS = ( form ) =>
	{
		return ( +form.HAP ) + ( +form.HAI );
	}

	const setTHP = ( form ) =>
	{
		return ( +form.THS ) * ( +form.NUM_SEMANAS );
	}

	const setCreditsNumber = ( form ) =>
	{
		const creditos = ( +form.THP ) / 48; // Realizas el cálculo original
		const decimal = creditos % 1; // Obtienes la parte decimal del número

		if (decimal < 0.05) {
			return Math.floor(creditos); // Redondea hacia abajo si está debajo de 0.05
		} else {
			return Math.ceil(creditos); // Redondea hacia arriba si está por encima de 0.05
		}
	}

	const setHAPXSEMANA = ( form ) =>
	{
		return ( +form.NUM_SEMANAS ) * ( +form.HAP );
	}

	const setMinimoHoras = ( form ) =>
	{
		return ( ( +form.PORCENTAJE_ASISTENCIA ) * ( +form.HAPXSEMANA ) / 100 );
	}

	return (
		<>
			<Grid
				sx={ {
					'& .MuiTextField-root': { width: '100%' },
				} }
			>

				<Typography variant="h6" sx={ { mt: 5, mb: 3 } } >
					2. Duración:
				</Typography>

				<Grid>
					<Grid container spacing={ { xs: 3 } } columns={ { xs: 1, sm: 8, md: 12 } }>
						<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
							<FormControl sx={ { m: 1, width: '100%' } } variant="outlined">
								<OutlinedInput
									disabled={ subjectByProposedEdition }
									fullWidth
									id="outlined-adornment-hap"
									name="HAP"
									error={ !formData.HAP && validate }
									inputProps={ {
										maxLength: 2,
										inputMode: 'numeric',
									} }
									value={ formData.HAP ?? '' }
									endAdornment={ <InputAdornment position="end">HAP*</InputAdornment> }
									onChange={ handleChange }
									aria-describedby="outlined-hap-helper-text"
								/>
								<FormHelperText id="outlined-hap-helper-text">Horas de Actividad Presencial a la semana o intensidad horaria</FormHelperText>
							</FormControl>
						</Grid>

						<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
							<FormControl sx={ { m: 1, width: '100%' } } variant="outlined">
								<OutlinedInput
									disabled={ subjectByProposedEdition }
									fullWidth
									id="outlined-adornment-hai"
									name="HAI"
									error={ !formData.HAI && validate }
									value={ formData.HAI ?? 0 }
									inputProps={ {
										maxLength: 2,
										inputMode: 'numeric',
									} }
									endAdornment={ <InputAdornment position="end">HAI*</InputAdornment> }
									onChange={ handleChange }
									aria-describedby="outlined-hai-helper-text"
								/>
								<FormHelperText id="outlined-hai-helper-text">Horas de Actividad autónoma o Independiente a la semana</FormHelperText>
							</FormControl>
						</Grid>

						<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
							<FormControl sx={ { m: 1, width: '100%' } } variant="filled">
								<FilledInput
									disabled
									fullWidth
									id="filled-adornment-ths"
									name="THS"
									value={ formData.THS }
									type="number"
									endAdornment={ <InputAdornment position="end">THS</InputAdornment> }
									onChange={ handleChange }
									aria-describedby="outlined-ths-helper-text"
								/>
								<FormHelperText id="outlined-ths-helper-text">Total de Horas de actividad académica por Semana</FormHelperText>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>


				<Grid sx={ { mt: 5 } } >
					<Grid container spacing={ { xs: 3 } } columns={ { xs: 1, sm: 8, md: 12 } }>
						<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
							<FormControl sx={ { m: 1, width: '100%' } } variant="outlined">
								<OutlinedInput
									disabled={ subjectByProposedEdition }
									fullWidth
									id="outlined-adornment-noSemanas"
									name="NUM_SEMANAS"
									inputProps={ {
										maxLength: 2,
										inputMode: 'numeric',
									} }
									error={ !formData.NUM_SEMANAS && validate }
									value={ formData.NUM_SEMANAS ?? 0 }
									endAdornment={ <InputAdornment position="end">Semanas*</InputAdornment> }
									onChange={ handleChange }
									aria-describedby="outlined-hap-helper-text"
								/>
								<FormHelperText id="outlined-noSemanas-helper-text">Número de semanas por periodo académico o por semestre</FormHelperText>
							</FormControl>
						</Grid>

						<Grid item xs={ 2 } sm={ 4 } md={ 4 } >
							<FormControl sx={ { m: 1, width: '100%' } } variant="filled">
								<FilledInput
									disabled
									fullWidth
									id="filled-adornment-thp"
									name="THP"
									value={ formData.THP ?? 0 }
									type="number"
									endAdornment={ <InputAdornment position="end">THP</InputAdornment> }
									onChange={ handleChange }
									aria-describedby="outlined-thp-helper-text"
								/>
								<FormHelperText id="outlined-thp-helper-text">Total Horas por Periodo académico</FormHelperText>
							</FormControl>
						</Grid>

						<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
							<FormControl sx={ { m: 1, width: '100%' } } variant="filled"
								error={!formData?.NUM_CREDITOS && validate}>
								<FilledInput
									disabled
									fullWidth
									id="filled-disabled"
									name="noCreditos"
									value={ formData.NUM_CREDITOS ?? 0 }
									onChange={ handleChange }
									type="number"
									endAdornment={ <InputAdornment position="end">Créditos</InputAdornment> }
									aria-describedby="filled-noCreditos-helper-text"
								/>
								<FormHelperText id="outlined-noCreditos-helper-text">Número de créditos por asignatura</FormHelperText>
								<FormHelperText id="outlined-noCreditos-helper-text" color="error">{!formData?.NUM_CREDITOS && validate ? 'El número de creditos no puede ser cero' : ''}</FormHelperText>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid sx={ {
				'& .MuiTextField-root': { width: '100%' },
			} }
			>
				<Typography variant="h6" sx={ { mt: 5, mb: 3 } } >
					3. Porcentaje de asistencia:
				</Typography>

				<Grid>
					<Grid container spacing={ { xs: 3 } } columns={ { xs: 1, sm: 8, md: 12 } }>
						<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
							<TextField
								disabled={ subjectByProposedEdition }
								variant='outlined'
								inputProps={ {
									maxLength: 3,
									inputMode: 'numeric',
								} }
								label="Porcentaje"
								id="outlined-start-adornment"
								sx={ { m: 1, width: '100%' } }
								name="PORCENTAJE_ASISTENCIA"
								onChange={ handleChange }
								value={ formData.PORCENTAJE_ASISTENCIA ?? 0 }
								error={ !formData.PORCENTAJE_ASISTENCIA && validate }
								InputProps={ {
									endAdornment: <InputAdornment position="start">%*</InputAdornment>,
								} }
							/>
						</Grid>

						<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
							<FormControl sx={ { m: 1, width: '100%' } } variant="filled">
								<FilledInput
									fullWidth
									disabled
									id="filled-adornment-hai"
									name="HAPXSEMANA"
									value={ formData.HAPXSEMANA ?? 0 }
									type="number"
									endAdornment={ <InputAdornment position="start">Horas presenciales</InputAdornment> }
									onChange={ handleChange }
								/>
							</FormControl>
						</Grid>

						<Grid item xs={ 2 } sm={ 4 } md={ 4 }>
							<FormControl sx={ { m: 1, width: '100%' } } variant="filled">

								<FilledInput
									fullWidth
									disabled
									id="filled-adornment-ths"
									name="MINIMOHORAS"
									value={ formData.MINIMOHORAS ?? 0 }
									type="number"
									endAdornment={ <InputAdornment position="start">Mínimo de horas</InputAdornment> }
									onChange={ handleChange }
								/>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}
