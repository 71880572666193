import axios from 'axios';
import { getEnvVariables  }  from '../../helpers/getEnvVariables';

export const loginService = async (email, password) => {
	const envVariables = getEnvVariables();
	try {
		const response = await axios.post(`${envVariables.API_URL}/auth/login`, { email, password });
		if (response.status === 201) {
			return response;
		}
	} catch ({ response }) {
		throw response.data;
	}
};