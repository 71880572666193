import { Alert } from '@mui/material';
import { useState } from 'react';
import createAxiosInstance from '../../utils/AxiosConfig';

export const RecoverPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [responseMsg, setResponseMsg] = useState({
    message: '',
    type: 'error',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const request = {
      email,
    };
    try {
      const { data } = await createAxiosInstance().post(
        `/auth/password-recovery`,
        request
      );
      setResponseMsg({ message: data.message, type: 'success' });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setResponseMsg({
        message:
          error.response &&
          (error.response.status === 401 || error.statusCode === 401)
            ? error.response.data?.message
            : 'Error del servidor, intentalo más tarde',
        type: 'error',
      });
    }
  };

  return (
    <>
      {responseMsg && responseMsg.message && (
        <Alert severity={responseMsg.type} sx={{ mb: 3 }}>
          {responseMsg.message}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <input
            type='email'
            id='email'
            name='email'
            placeholder='Correo Institucional'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type='submit' className="auth-action_button" disabled={(!email) || isLoading}>
          {isLoading ? 'Cargando...' : 'Reestablecer contraseña'}
        </button>
      </form>
    </>
  );
};
