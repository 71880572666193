import { useState } from 'react'
import SubjectWeekForm from './SubjectWeekForm';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PropTypes from 'prop-types';
import AlertDialog from '../../../shared/components/AlertDialog';

function SubjectWeeksList ( { formDataEvent, setFormDataEvent, subjectByProposedEdition, validate } )
{

  const [ openDialog, setOpenDialog ] = useState( false );
  const [ dialogMessage, setDialogMessage ] = useState( {
    title: '',
    type: 'info',
    description: '',
    primaryButtonText: '',
    redirectTo: '',
  } );

  const weekData = {
    CONTENIDODETALLADO: '',
    detailContentTextPlain: '',
    CONTENIDOESPECIFICO: '',
    specificContentTextPlain: '',
    isValid: false,
  };

  const contentMap = (content) => {
    return content.map(c => {
      return {
        ...c,
        detailContentTextPlain: c.CONTENIDODETALLADO ? c.CONTENIDODETALLADO.replace(/<[^>]*>/g, '') : '',
        specificContentTextPlain: c.CONTENIDOESPECIFICO ? c.CONTENIDOESPECIFICO.replace(/<[^>]*>/g, '') : '',
        isValid: !!(c.CONTENIDODETALLADO && c.CONTENIDOESPECIFICO),
      }
    })
  }

  const [ weeks, setWeeks ] = useState( formDataEvent &&
      formDataEvent.DContenidos &&
      formDataEvent.DContenidos.length > 0 ?
      contentMap(formDataEvent.DContenidos) : [ weekData ] );

  const handleAddWeekEvent = ( week, index ) =>
  {
    weeks[ index ] = week;
    setWeeks( weeks );

    const validWeeks = weeks.filter( week => week.isValid );
    updateFormData( validWeeks );
  };

  const updateFormData = ( weeks ) =>
  {
    setFormDataEvent( ( prevFormData ) => ( {
      ...prevFormData,
      [ 'DContenidos' ]: weeks
    } ) );
  }

  const canAddWeeks = () =>
  {
    return weeks.every( week => week.isValid );
  }

  const addWeekClick = () =>
  {
    if ( canAddWeeks() )
    {
      setWeeks( [ ...weeks, weekData ] );
    } else
    {
      setDialogMessage(
        {
          title: 'No puede agregar mas contenidos sin haber rellenado los campos',
          type: 'info',
          description: '',
          primaryButtonText: 'Aceptar',
          redirectTo: '',
        }
      );
      setOpenDialog( true );
    }
  }

  const handleDeleteEvent = ( index ) =>
  {
    const newWeeks = [ ...weeks ];
    newWeeks.splice( index, 1 );
    setWeeks( [] );
    setTimeout(() => {
      setWeeks( [...newWeeks] );
    }, 0);
    const validWeeks = newWeeks.filter( week => week.isValid );
    updateFormData( validWeeks );
  }

  const handleDialog = () =>
  {
    setOpenDialog( false );
  }

  const handleWeeks = () =>
  {
    console.log( 'formData', formDataEvent );
    console.log( 'weeks', weeks );
  }

  return (
    <Grid
      container spacing={ { xs: 3 } }>
      { weeks.map( ( week, index ) => (
        <Grid item xs={ 12 } key={ index }>
          <SubjectWeekForm
            subjectByProposedEdition={ subjectByProposedEdition}
            sendWeekEvent={ handleAddWeekEvent }
            deleteWeekEvent={ handleDeleteEvent }
            week={ { ...week } }
            index={ index }
            weeks={ weeks }
            validate={ validate }/>
        </Grid>
      ) ) }
      <Grid item xs={ 12 }>
        <Button startIcon={ <AddCircleIcon /> } sx={ { textTransform: 'initial' } } type='button' onClick={ addWeekClick } disabled={ subjectByProposedEdition }>
          Añadir más semanas
        </Button>
       {/*  <Button startIcon={ <AddCircleIcon /> } sx={ { textTransform: 'initial' } } type='button' onClick={ handleWeeks }>
          ver todo
        </Button> */}
      </Grid>
      <AlertDialog
        openDialog={ openDialog }
        handleCloseDialog={ handleDialog }
        title={ dialogMessage.title }
        type={ dialogMessage.type }
        primaryButtonText={ dialogMessage.primaryButtonText }
        description={ dialogMessage.description }>
      </AlertDialog>
    </Grid>
  )
}

SubjectWeeksList.propTypes = {
  setFormDataEvent: PropTypes.func.isRequired,
  formDataEvent: PropTypes.object.isRequired,
  subjectByProposedEdition: PropTypes.bool,
  validate: PropTypes.bool,
};

export default SubjectWeeksList;