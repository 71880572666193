import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { pxToRem } from '../../utils/unitsConversion';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';

function SubjectsFilter ( { searchCriteriaData, handleSearchTerm, setTerm, handleSearchCriteria } )
{
  return (
    <Box
      component="form"
      sx={ {
        display: 'flex',
        mt: 3
      } }
      onSubmit={ handleSearchTerm }
      noValidate
      autoComplete="off"
    >
      <TextField fullWidth id="subjectFilterTerm" label="Filtrar por" size="small"
        InputProps={ {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Filtrar asignaturas"
                type='submit'
                edge="end"
                sx={ { fontSize: pxToRem( 20 ) } }
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )
        } } onChange={ ( event ) => setTerm( event.target.value ) } />
      <TextField
        id="subjectSearchCriteria"
        select
        label="Criterios de busqueda"
        defaultValue="NOMBRE"
        helperText=""
        size="small"
        sx={ { minWidth: '180px' } }
        onChange={ ( event ) => { handleSearchCriteria( event ); } } >
        { searchCriteriaData.map( ( option ) => (
          <MenuItem key={ option.value } value={ option.value }>
            { option.label }
          </MenuItem>
        ) ) }
      </TextField>
    </Box>
  )
}


SubjectsFilter.propTypes = {
  searchCriteriaData: PropTypes.array,
  handleSearchTerm: PropTypes.func.isRequired,
  setTerm: PropTypes.func.isRequired,
  handleSearchCriteria: PropTypes.func.isRequired,
};

export default SubjectsFilter;