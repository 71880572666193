import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useState } from 'react';
import SubjectBibliographyForm from './SubjectBibliographyForm';
import AlertDialog from '../../../shared/components/AlertDialog';

function SubjectBibliographyList ( { formDataEvent, setFormDataEvent, validate } )
{

  const [ openDialog, setOpenDialog ] = useState( false );
  const [ dialogMessage, setDialogMessage ] = useState( {
    title: '',
    type: 'info',
    description: '',
    primaryButtonText: '',
    redirectTo: '',
  } );

  const bibliographyData = {
    tipoBibliografia: '',
    descripcion: '',
    // autor: '',
    // titulo: '',
    // nombre_editorial: '',
    // año: '',
    isValid: false,
  };

  const [ bibliographies, setBibliographies ] = useState( formDataEvent &&
    formDataEvent.BIBLIOGRAFIAS && JSON.parse(formDataEvent.BIBLIOGRAFIAS).length ? JSON.parse(formDataEvent.BIBLIOGRAFIAS) : [ bibliographyData ] );

  const handleAddBibliographyEvent = ( bibliography, index ) =>
  {
    bibliographies[ index ] = bibliography;
    setBibliographies( bibliographies );

    const validBibliographies = bibliographies.filter( bibliography => bibliography.isValid );
    updateFormData( validBibliographies );
  };

  const updateFormData = ( bibliographies ) =>
  {
    setFormDataEvent( ( prevFormData ) => ( {
      ...prevFormData,
      [ 'BIBLIOGRAFIAS' ]: JSON.stringify(bibliographies),
    } ) );
  }

  const canAddBibliographies = () =>
  {
    return bibliographies.every( bibliography => bibliography.isValid );
  }

  const addBibliographyClick = () =>
  {
    if ( canAddBibliographies() )
    {
      setBibliographies( [ ...bibliographies, bibliographyData ] );
    } else
    {
      setDialogMessage(
        {
          title: 'No puede agregar mas bibliografias sin haber rellenado los campos',
          type: 'info',
          description: '',
          primaryButtonText: 'Aceptar',
          redirectTo: '',
        }
      );
      setOpenDialog( true );
    }
  }

  const handleDeleteEvent = ( index ) =>
  {
    const newBibliographies = [ ...bibliographies ];
    newBibliographies.splice( index, 1 );
    setBibliographies( [ ...newBibliographies ] );

    const validBibliographies = newBibliographies.filter( week => week.isValid );
    updateFormData( validBibliographies );
  }

  const handleDialog = () =>
  {
    setOpenDialog( false );
  }

  const handleBibliographies = () =>
  {
    console.log( 'formData', formDataEvent );
    console.log( 'bibliographies', bibliographies );
  }

  return (
    <Grid
      container spacing={ { xs: 3 } }>
      { bibliographies.map( ( bibliography, index ) => (
        <Grid item xs={ 12 } key={ index }>
          <SubjectBibliographyForm
            sendBibliographyEvent={ handleAddBibliographyEvent }
            deleteBibliographyEvent={ handleDeleteEvent }
            bibliography={ { ...bibliography } }
            index={ index }
            bibliographies={ bibliographies }
            validate={ validate }></SubjectBibliographyForm>
        </Grid>
      ) ) }
      <Grid item xs={ 12 }>
        <Button startIcon={ <AddCircleIcon /> } sx={ { textTransform: 'initial' } } type='button' onClick={ addBibliographyClick }>
          Añadir bibliografia
        </Button>
        {/* <Button startIcon={ <AddCircleIcon /> } sx={ { textTransform: 'initial' } } type='button' onClick={ handleBibliographies }>
          ver todo
        </Button> */ }
      </Grid>
      <AlertDialog
        openDialog={ openDialog }
        handleCloseDialog={ handleDialog }
        title={ dialogMessage.title }
        type={ dialogMessage.type }
        primaryButtonText={ dialogMessage.primaryButtonText }
        description={ dialogMessage.description }>
      </AlertDialog>
    </Grid>
  )
}

SubjectBibliographyList.propTypes = {
  setFormDataEvent: PropTypes.func.isRequired,
  formDataEvent: PropTypes.object.isRequired,
  validate: PropTypes.bool,
};

export default SubjectBibliographyList;
