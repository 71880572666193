import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { RichTextEditorProvider, RichTextField } from "mui-tiptap";
import RichTextFieldControls from "../../RichTextFieldControls";
import { useEffect, useState } from "react";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

export const LearningAchievementSubjectFormByStudyPlan = ({
  sendDataForm,
  itemCount,
  index,
  learningAchievement,
  handleDelete,
  subjectByProposedEdition,
  formValidate,
}) => {
  const regex = /<[^>]+>/g;

  const [macroAspectField, setMacroAspectField] = useState({
    plainText: '',
    textHtml: '',
  });

  useEffect(() => {
    setMacroAspectField({
      plainText: learningAchievement?.aspectoMacro?.replace(regex, "")?.trim(),
      textHtml: learningAchievement?.aspectoMacro,
    });
  }, []);

  const macroAspectEditor = useEditor({
    extensions: [StarterKit],
    content: learningAchievement?.aspectoMacro,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      const text = editor.getText()?.trim();
      setMacroAspectField({
        plainText: text,
        textHtml: html,
      });
      sendDataForm(
        {
          ...learningAchievement,
          aspectoMacro: html,
        },
        index
      );
    },
  });

  // const handleLearningAchievementChange = (event) => {
  //   const { value } = event.target;
  //   setPlanField(value);
  //   sendDataForm(
  //     {
  //       ...learningAchievement,
  //       nombrePlan: value,
  //       resultadoAprendizaje: getLearningAchievementsByStydyPlan(value),
  //       aspectoMacro: macroAspectField.textHtml,
  //     },
  //     index
  //   );
  // };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { width: "100%" },
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={{ xs: 3 }}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h7" component="div" sx={{ mb: 2 }}>
            RAA {index + 1}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Typography gutterBottom variant="h7" component="div">
            RAA {index + 1}
          </Typography>
          <TextField
            select
            required
            id="learningAchievementSubjectID"
            name="learningAchievementSubjectID"
            label="Resultado de aprendizaje"
            value={planField}
            size="small"
            error={
              planField === '' && formValidate
            }
            helperText={
              planField === '' && formValidate
                ? "El campo es requerido"
                : ""
            }
            onChange={(e) => handleLearningAchievementChange(e)}
          >
            {learningAchievementsByStydyPlanFormData.filter(learning => learning?.nombrePlan).map((learning, index) => (
              <MenuItem key={index} value={learning.nombrePlan}>
                {learning.nombrePlan}
              </MenuItem>
            ))}
          </TextField>
        </Grid> */}
        <Grid item xs={12}>
          <Typography gutterBottom variant="h7" component="div">
            Aspecto macro*
          </Typography>
          <RichTextEditorProvider editor={macroAspectEditor}>
            <RichTextField
              controls={<RichTextFieldControls></RichTextFieldControls>}
            />
          </RichTextEditorProvider>
          {macroAspectField?.plainText?.length === 0 && formValidate && (
            <Typography variant="body2" gutterBottom color="error">
              El campo es requerido
            </Typography>
          )}
        </Grid>
        {itemCount > 1 && (
          <Grid item xs={12}>
            <Button
              startIcon={<RemoveCircleIcon />}
              color="error"
              sx={{ textTransform: "initial" }}
              type="button"
              onClick={(e) => handleDelete(index)}
            >
              {subjectByProposedEdition ? 'Deshabilitar': 'Eliminar'}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
