import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import Sources from '../../enums/Sources.enum';
import PropTypes from 'prop-types';
import { UseLogicSubjectRequests } from './hooks/UseLogicSubjectRequests';
import AlertDialog from '../../shared/components/AlertDialog';
import SubjectsFilter from '../../shared/components/SubjectsFilter';
import SubjectResults from '../../shared/components/SubjectResults';

function SubjectRequests ( { source, title } )
{
  const {
    filteredRequests,
    isLoading,
    handleCloseDialog,
    openDialog,
    setTerm,
    handleSearchCriteria,
    handleSearchTerm,
    searchCriteriaData,
  } = UseLogicSubjectRequests(source);

  const navigate = useNavigate();

  const redirectTo = ( request ) =>
  {
    navigate( source === Sources.SubjectByProposedEdition ? `/request-for-proposed-edition` : `/pending-request`, {
      replace: false,
      state: {
        request,
      }
    } );
  }

  return (
    <Box>
      { isLoading ? (
        <Box sx={ { display: 'flex', justifyContent: 'center' } }>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant='h4' component='h2'>{ title }</Typography>
          <SubjectsFilter searchCriteriaData={ searchCriteriaData }
            handleSearchTerm={ handleSearchTerm }
            setTerm={ setTerm }
            handleSearchCriteria={ handleSearchCriteria }></SubjectsFilter>

          { filteredRequests.length ? (
            <SubjectResults items={ filteredRequests } redirectTo={ redirectTo }></SubjectResults>
          ) : (
            <Box sx={ { mt: 3 } }>
              <Alert severity="info">No se encontraron resultados</Alert>
            </Box>
          ) }
          <br></br>
          <Alert severity="info">{ filteredRequests.length } Solicitudes pendientes de gestionar</Alert>
        </>
      ) }
      <AlertDialog openDialog={ openDialog }
        handleCloseDialog={ handleCloseDialog }
        title='Solicitudes'
        type='error'
        primaryButtonText='Aceptar'
        description='Ocurrio un error al obtener las solicitudes por propuesta de edición'></AlertDialog>
    </Box>
  )
}

SubjectRequests.propTypes = {
  source: PropTypes.string,
  title: PropTypes.string,
};

export default SubjectRequests;