
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CustomDialog } from '../../helpers/styles/CustomDialog';
import { pxToRem } from '../../utils/unitsConversion'
import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';

const Transition = forwardRef( function Transition ( props, ref )
{
  return <Slide direction="up" ref={ ref } { ...props } />;
} );

const generateRandomId = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';
  for (let i = 0; i < 10; i++) {
    id += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return id;
};


function AlertDialog ( { openDialog, handleCloseDialog, type, primaryButtonText, title, description, handleSecondaryAction, secondaryButtonText } )
{

  const id = generateRandomId();

  return (
    <CustomDialog
      open={ openDialog }
      onClose={ handleCloseDialog }
      color={ type }
      TransitionComponent={ Transition }
      keepMounted
      aria-labelledby={ 'alert-cancel-dialog-title' + id }
      aria-describedby={"alert-cancel-dialog-description" + id }
    >
      { title &&
        <DialogTitle id={ 'alert-cancel-dialog-title' + id } align='center'>
          <InfoIcon />
          <Typography variant="h4" component={ 'span' }>
            { title }
          </Typography>
        </DialogTitle>
      }
      { description &&
        <DialogContent align='center'>
          <DialogContentText id={"alert-cancel-dialog-description" + id } component="div">
            <Typography variant="body1">
              { description }
            </Typography>
          </DialogContentText>
        </DialogContent>
      }
      <DialogActions sx={ { justifyContent: 'center', gap: pxToRem( 16 ) } }>
        <Button
          color='primary'
          variant='outlined'
          size='medium'
          onClick={ () => handleCloseDialog() }
        >{primaryButtonText}</Button>
        { secondaryButtonText &&
          <Button
            color='success'
            variant='outlined'
            size='medium'
            onClick={ () => handleSecondaryAction( ) }>{ secondaryButtonText }</Button>
         }
      </DialogActions>
    </CustomDialog>
  )
}

AlertDialog.propTypes = {
  openDialog: PropTypes.bool,
  handleCloseDialog: PropTypes.func.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  primaryButtonText: PropTypes.string,
  description: PropTypes.string,
  handleSecondaryAction: PropTypes.func,
  secondaryButtonText: PropTypes.string,
};

export default AlertDialog;