import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { pxToRem } from '../../utils/unitsConversion';


/**
 * Estilos generales, por variante y tamaño
 */
export const CustomDialog = styled( Dialog )(
  ( { theme, color } ) => ( {
    '.MuiPaper-root': {
      // maxWidth: pxToRem( 480 ),
      boxShadow: '0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F',
      borderRadius: pxToRem( 6 ),
      '.MuiDialogTitle-root': {
        padding: `${ pxToRem( 40 ) } ${ pxToRem( 32 ) } ${ pxToRem( 16 ) }`,
        '.MuiTypography-root': {
          display: 'block',
        },
        'svg': {
          fontSize: pxToRem( 96 ),
          marginBottom: pxToRem( 24 ),
          color: theme.palette[ color ].main,
        }
      },
      '.MuiDialogContent-root': {
        padding: `0 ${ pxToRem( 32 ) } ${ pxToRem( 24 ) }`,
      },
      '.MuiDialogActions-root': {
        padding: `0 ${ pxToRem( 32 ) } ${ pxToRem( 40 ) }`,
      }
    }
  } )
);