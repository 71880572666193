import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Slide,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { CustomDialog } from '../../helpers/styles/CustomDialog';
import { pxToRem } from '../../utils/unitsConversion';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import createAxiosInstance from '../../utils/AxiosConfig';
import AlertDialog from '../../shared/components/AlertDialog';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function DialogConfirmSubjectAsDraft({
  id,
  generalInformForm,
  studyPlansForm,
  learningAchievementsForm,
  subjectProgForm,
  contentAndDescForm,
}) {
  const navigate = useNavigate();
  const [openDialogDraft, setOpenDialogDraft] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [appError, setAppError] = useState({
    message: '',
    type: 'error',
    errors: [],
  });
  const [dialogMessage, setDialogMessage] = useState({
    title: '',
    type: 'info',
    description: '',
    primaryButtonText: '',
    redirectTo: '',
  });

  const handleCloseDialogDraft = () => {
    setOpenDialogDraft(false);
  };

  const handleDialog = () => {
    setOpenDialogDraft(false);
    if (dialogMessage.redirectTo) {
      navigate(dialogMessage.redirectTo, { replace: true });
    }
  };

  const buildLearningAchievementsRequest = (learningAchievementsForm) => {
    let result = [];
    const learningAchievementsByPlan =
      learningAchievementsForm.resultadosAprendizajePorPlan;
    const learningAchievementsSubject =
      learningAchievementsForm.resultadosAprendizajeAsignatura;

    result = result
      .concat(learningAchievementsByPlan)
      .concat(learningAchievementsSubject);
    return result;
  };

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      let response;
      const learningAchievementsResult = {
        resultadosAprendizaje: buildLearningAchievementsRequest(
          learningAchievementsForm
        ),
      };
      let form = Object.assign(
        {},
        generalInformForm,
        studyPlansForm,
        learningAchievementsResult,
        subjectProgForm,
        contentAndDescForm
      );
      //   console.log(form);
      if (id) {
        response = await createAxiosInstance().patch(
          `/subjects/actualizacion-simple-borrador/${id}`,
          form
        );
      } else {
        response = await createAxiosInstance().post('/subjects/borrador', form);
      }
      const { status } = response;
      if (status === 200 || status === 201) {
        setIsLoading(false);
        setOpenDialogDraft(false);

        setDialogMessage({
          title: 'Datos procesados correctamente',
          type: 'info',
          description: 'La asignatura a sido guardada en modo borrador',
          primaryButtonText: 'Aceptar',
          redirectTo: '/subject-drafts',
        });
        setOpenDialog(true);
      }
    } catch (error) {
      setAppError({
        message: 'Error al procesar la solicitud',
        type: 'error',
        errors: [],
      });
      setIsLoading(false);
    }
  };

  const handleSaveDraft = () => {
    setOpenDialogDraft(true);
  };

  return (
    <>
      <Button
        type='button'
        variant='contained'
        color='secondary'
        onClick={() => handleSaveDraft()}
      >
        {id ? 'Actualizar borrador' : 'Guardar borrador'}
      </Button>
      <CustomDialog
        open={openDialogDraft}
        onClose={handleCloseDialogDraft}
        color='warning'
        maxWidth='lg'
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby='alert-return-request-dialog-title'
        aria-describedby='alert-return-request-dialog-description'
      >
        <DialogTitle id='alert-return-request-dialog-title' align='center'>
          <InfoIcon />
          <Typography variant='h4' component={'span'}>
            Borrador de asignatura
          </Typography>
        </DialogTitle>
        <DialogContent align='center'>
          <DialogContentText
            id='alert-return-request-dialog-description'
            component='div'
          >
            {appError && appError.message && (
              <Alert severity={appError.type} sx={{ mb: 3, textAlign: 'left' }}>
                {appError.message}
                {appError.errors.length > 0 && (
                  <List>
                    {appError.errors.map((error, index) => (
                      <ListItem key={index} sx={{ p: 0 }}>
                        <ListItemText primary={error} />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Alert>
            )}
            <Typography variant='body1' sx={{ mb: 3 }}>
              ¿Está seguro de que desea guardar la asignatura como borrador?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: pxToRem(16) }}>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Button
                color='primary'
                variant='outlined'
                size='medium'
                onClick={() => handleCloseDialogDraft()}
              >
                Cancelar
              </Button>
              <Button
                color='success'
                variant='outlined'
                size='medium'
                onClick={() => handleConfirm()}
              >
                Aceptar
              </Button>
            </>
          )}
        </DialogActions>
      </CustomDialog>
      <AlertDialog
        openDialog={openDialog}
        handleCloseDialog={handleDialog}
        title={dialogMessage.title}
        type={dialogMessage.type}
        primaryButtonText={dialogMessage.primaryButtonText}
        description={dialogMessage.description}
      ></AlertDialog>
    </>
  );
}

DialogConfirmSubjectAsDraft.propTypes = {
  id: PropTypes.number,
  generalInformForm: PropTypes.object,
  studyPlansForm: PropTypes.object,
  subjectProgForm: PropTypes.object,
  contentAndDescForm: PropTypes.object,
};

export default DialogConfirmSubjectAsDraft;
