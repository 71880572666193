import createAxiosInstance from '../../../utils/AxiosConfig';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const UseLogicSubjectsByProposedEdition = () =>
{
  const [ subjects, setSubjects ] = useState( [] );
  const [ filteredSubjects, setFilteredSubjects ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( true );
  const [ openDialog, setOpenDialog ] = useState( false );
  const [ openFeedbackDialog, setOpenFeedbackDialog ] = useState( false );
  const [ feedback, setFeedback ] = useState( false );

  const [ term, setTerm ] = useState( '' );
  const [ searchCriteria, setSearchCriteria ] = useState( 'NOMBRE' );

  const navigate = useNavigate();

  useEffect( () =>
  {
    const fetchSubjects = async () =>
    {
      try
      {
        const response = await createAxiosInstance().get( `/edited-subject/subjects-by-teachers/token` );

        setFilteredSubjects( response.data );
        setSubjects( response.data );

        setIsLoading( false );
      } catch ( error )
      {
        setIsLoading( false );
        handleClickOpenDialog();
      }
    };

    fetchSubjects();
  }, [] );

  const handleClickOpenDialog = () =>
  {
    setOpenDialog( true );
  };

  const handleCloseDialog = () =>
  {
    setOpenDialog( false );
  };

  const handleSearchCriteria = ( e ) =>
  {
    setSearchCriteria( e.target.value );
    handleFilteredSubjects( e.target.value );
  }

  const handleSearchTerm = ( e ) =>
  {
    e.preventDefault();
    handleFilteredSubjects( searchCriteria );
  }

  const normalizeString = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  }

  const handleFilteredSubjects = ( searchCriteria ) =>
  {
    const filterdSubjects = subjects.filter( subject =>
      normalizeString(subject[ searchCriteria ]).includes( normalizeString(term) )
    );
    setFilteredSubjects( filterdSubjects );
  }

  const handleOpenFeedbackDialog = (feedback) =>
  {
    setFeedback( feedback );
    setOpenFeedbackDialog( true );
  };

  const handleCloseFeedbackDialog = () =>
  {
    setOpenFeedbackDialog( false );
  };

  const handleSubjectEdit = ( subject ) => {
    navigate( `/subject-proposal-edit`, {
      replace: true,
      state: {
        subject,
        subjectByProposedEdition: true,
        editSubjectByEditingProposal: true,
      }
    } );
  }

  return {
    filteredSubjects,
    isLoading,
    handleCloseDialog,
    openDialog,
    setTerm,
    handleSearchCriteria,
    handleSearchTerm,
    handleSubjectEdit,
    openFeedbackDialog,
    handleOpenFeedbackDialog,
    handleCloseFeedbackDialog,
    feedback,
  }
}