import AcUnitIcon from '@mui/icons-material/AcUnit';
import BusinessIcon from '@mui/icons-material/Business';
import CalculateIcon from '@mui/icons-material/Calculate';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClassIcon from '@mui/icons-material/Class';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LockClockIcon from '@mui/icons-material/LockClock';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SourceIcon from '@mui/icons-material/Source';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Sources from '../../enums/Sources.enum';
import { useRef, useState } from 'react';
import PrintContent from './PrintContent';
dayjs.extend( utc );
dayjs.extend( timezone );
dayjs.tz.setDefault( 'America/Bogota' );

export default function SubjectDetail ( { subject, source } )
{

  const [ descriptionAccordionExpanded, setDescriptionAccordionExpanded ] = useState(false);
  const [ plansAccordionExpanded, setPlansAccordionExpanded ] = useState(false);
  const [ learningAchievementsByPlanAccordionExpanded, setLearningAchievementsByPlanAccordionExpanded ] = useState(false);
  const [ learningAchievementsSubjectAccordionExpanded, setLearningAchievementsSubjectAccordionExpanded ] = useState(false);
  const [ durationAccordionExpanded, setDurationAccordionExpanded ] = useState(false);
  const [ attendancePercentageAccordionExpanded, setAttendancePercentageAccordionExpanded ] = useState(false);
  const [ bibliographyAccordionExpanded, setBibliographyAccordionExpanded ] = useState(false);
  const [ goalsAccordionExpanded, setGoalsAccordionExpanded ] = useState(false);
  const [ teachingWeeksAccordionExpanded, setTeachingWeeksAccordionExpanded ] = useState(false);
  const [ teachingMethodologyAccordionExpanded, setTeachingMethodologyAccordionExpanded ] = useState(false);
  const [ teachingRequirementsAccordionExpanded, setTeachingRequirementsAccordionExpanded ] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const limitCharacterDescription = 500;
  const plainTextDescription = subject.DESCRIPCIONASIGNATURA.replace( /<[^>]+>/g, '' );
  const truncatedDescription = plainTextDescription.length > limitCharacterDescription ? plainTextDescription.substring( 0, limitCharacterDescription ) + '...' : plainTextDescription;
  const printRef = useRef();

  const handlePrint = () => {
    setIsPrinting(true);

    document.querySelector('header').classList.add('no-print');
    document.querySelector('footer').classList.add('no-print');
    document.querySelector('#app-bar-navigation').classList.add('no-print');
    document.querySelector('#navigation-menu').classList.add('no-print');

    setTimeout(() => {
      window.print();
    }, 100);

    setTimeout(() => {
      document.querySelector('header').classList.remove('no-print');
      document.querySelector('footer').classList.remove('no-print');
      document.querySelector('#app-bar-navigation').classList.remove('no-print');
      document.querySelector('#navigation-menu').classList.remove('no-print');
      setIsPrinting(false);
    }, 200);
  };

  return (
    <Box>
       <Box sx={{display: isPrinting ? 'block' : 'none'}}>
          <PrintContent
            ref={printRef}
            subject={subject}
            truncatedDescription={truncatedDescription}
          />
      </Box>
      <Card sx={{display: isPrinting ? 'none' : 'block'}}>
        <CardContent>
          <Typography gutterBottom variant="h4" component="h1">
            { subject.NOMBRE }
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={ { mb: 6 } } component="div">
            <span dangerouslySetInnerHTML={ { __html: truncatedDescription } } />
            { source === Sources.SubjectByProposedEdition &&
              <Tooltip title="La descripción pudo ser modificado para esta propuesta de edición" color='info'>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            }
          </Typography>

          {/* Button to trigger print */}
          { source !== Sources.DraftSubjects &&
            <Box sx={{mb: 3}}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handlePrint()}
              >
                Imprimir
              </Button>
            </Box>
          }

          <Box sx={ { flexGrow: 1, mb: 2 } }>
            <Grid container spacing={ { xs: 2, md: 3 } } columns={ { xs: 4, sm: 8, md: 12 } }>
              <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                <Typography gutterBottom variant="body1" component="div">
                  <SourceIcon color="primary" /> Código
                </Typography>
                <Typography variant="h5" color="text.secondary">
                  { subject.CODIGO_ASIGNATURA ? subject.CODIGO_ASIGNATURA : '-' }
                </Typography>
              </Grid>
              <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                <Typography gutterBottom variant="body1" component="div">
                  <MenuBookIcon color="primary" /> Facultad
                </Typography>
                <Typography variant="h5" color="text.secondary">
                  { subject.FACULTAD ? subject.FACULTAD : '-' }
                </Typography>
              </Grid>
              <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                <Typography gutterBottom variant="body1" component="div">
                  <BusinessIcon color="primary" /> Sede
                </Typography>
                <Typography variant="h5" color="text.secondary">
                  { subject.SEDE ? subject.SEDE : '-' }
                </Typography>
              </Grid>
              <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                <Typography gutterBottom variant="body1" component="div">
                  <AcUnitIcon color="primary" /> Unidad académica básica
                </Typography>
                <Typography variant="h5" color="text.secondary">
                  { subject.UNIDADACADEMICABASICA ? subject.UNIDADACADEMICABASICA : '-' }
                </Typography>
              </Grid>
              <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                <Typography gutterBottom variant="body1" component="div">
                  <CalculateIcon color="primary" /> Nivel
                </Typography>
                <Typography variant="h5" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
                  { subject.NIVEL ? subject.NIVEL : '-' }
                </Typography>
              </Grid>
              <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                <Typography gutterBottom variant="body1" component="div">
                  <LibraryBooksIcon color="primary" /> Asignatura libre eleccion
                </Typography>
                <Typography variant="h5" color="text.secondary">
                  { subject.ASIGNATURALIBRELECCION ? 'Si' : 'No' }
                </Typography>
              </Grid>
              <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                <Typography gutterBottom variant="body1" component="div">
                  <PlaylistAddCheckIcon color="primary" /> Obligatoria
                </Typography>
                <Typography variant="h5" color="text.secondary">
                  { subject.OBLIGATORIA ? 'Si' : 'No' }
                </Typography>
              </Grid>
              <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                <Typography gutterBottom variant="body1" component="div">
                  <CalendarMonthIcon color="primary" /> Fecha de solicitud
                </Typography>
                <Typography variant="h5" color="text.secondary">
                  { subject.createdAt ? dayjs.utc( subject.createdAt ).format( 'YYYY-MM-DD' ) : '-' }
                </Typography>
              </Grid>
              <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                <Typography gutterBottom variant="body1" component="div">
                  <ClassIcon color="primary" /> Tipo de calificación
                </Typography>
                <Typography variant="h5" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
                  { subject.TIPOCALIFICACION ? subject.TIPOCALIFICACION : '-' }
                </Typography>
              </Grid>
              <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                <Typography gutterBottom variant="body1" component="div">
                  <WorkHistoryIcon color="primary" /> Validable
                  { source === Sources.SubjectByProposedEdition &&
                    <Tooltip title="El campo validable pudo ser modificado para esta propuesta de edición" color='info'>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </Typography>
                <Typography variant="h5" color='text.secondary'>
                  { subject.VALIDABLE ? 'Si' : 'No' }
                </Typography>
              </Grid>
              { subject.VIGENCIA &&
                <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                  <Typography gutterBottom variant="body1" component="div">
                    <LockClockIcon color="primary" /> Vigencia
                  </Typography>
                  <Typography variant="h5" color="text.secondary">
                    { subject.VIGENCIA }
                  </Typography>
                </Grid>
              }

              { subject.actNumber && subject.actDate &&
                <>
                  <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                    <Typography gutterBottom variant="body1" component="div">
                      <SourceIcon color="primary" /> Número de acta director area curricular
                    </Typography>
                    <Typography variant="h5" color="text.secondary">
                      { subject.actNumber }
                    </Typography>
                  </Grid>
                  <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                    <Typography gutterBottom variant="body1" component="div">
                      <CalendarMonthIcon color="primary" /> Fecha de acta director area curricular
                    </Typography>
                    <Typography variant="h5" color="text.secondary">
                      { dayjs.utc( subject.actDate ).format( 'YYYY-MM-DD' ) }
                    </Typography>
                  </Grid>
                </>
              }

              { subject.actNumeroConsejoFacultad && subject.actDateConsejoFacultad &&
                <>
                  <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                    <Typography gutterBottom variant="body1" component="div">
                      <SourceIcon color="primary" /> Número de acta consejo de facultad
                    </Typography>
                    <Typography variant="h5" color="text.secondary">
                      { subject.actNumeroConsejoFacultad  }
                    </Typography>
                  </Grid>
                  <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                    <Typography gutterBottom variant="body1" component="div">
                      <CalendarMonthIcon color="primary" /> Fecha de acta consejo de facultad
                    </Typography>
                    <Typography variant="h5" color="text.secondary">
                      { dayjs.utc( subject.actDateConsejoFacultad ).format( 'YYYY-MM-DD' ) }
                    </Typography>
                  </Grid>
                </>
              }
            </Grid>
          </Box>

          <Box>

            { plainTextDescription.length > limitCharacterDescription &&
              <Accordion expanded={descriptionAccordionExpanded} onChange={(event, isExpanded) => setDescriptionAccordionExpanded(isExpanded)}>
                <AccordionSummary
                  expandIcon={ <ExpandMoreIcon /> }
                  aria-controls="panel-content-description"
                  id="panel-content-description">
                  <Typography gutterBottom variant="h4" component="div" sx={ { marginBottom: 0 } }>
                    Descripción { source === Sources.SubjectByProposedEdition &&
                      <Tooltip title="La descripción pudo ser modificada para esta propuesta de edición" color='info'>
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" color="text.secondary" component="div">
                    { subject.DESCRIPCIONASIGNATURA ?
                      <div dangerouslySetInnerHTML={ { __html: subject.DESCRIPCIONASIGNATURA } } /> :
                      'No hay descripción' }
                  </Typography>
                </AccordionDetails>
              </Accordion>
            }

            <Accordion expanded={plansAccordionExpanded} onChange={(event, isExpanded) => setPlansAccordionExpanded(isExpanded)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="study-plans-content"
                id="study-plans-header"
              >
                <Typography gutterBottom variant="h4" component="div" sx={ { marginBottom: 0 } }>
                  Planes de estudio
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                { subject.studyPlan?.length > 0 ? (
                  <>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="Planes de estudio">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Código</TableCell>
                            <TableCell align="left">Nombre</TableCell>
                            <TableCell align="left">Tipologia</TableCell>
                            <TableCell align="left">Agrupación</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        { subject.studyPlan.map( ( plan ) => (
                            <TableRow key={plan.codigoPlan}>
                              <TableCell align="left">{plan.codigoPlan ? plan.codigoPlan : '-'}</TableCell>
                              <TableCell align="left">{plan.nombrePlan ? plan.nombrePlan : '-'}</TableCell>
                              <TableCell align="left">{plan.tipologia ? plan.tipologia : '-'}</TableCell>
                              <TableCell align="left">{plan.agrupacion ? plan.agrupacion : '-'}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : (
                  <Typography variant="body1" color="text.secondary">
                    No hay planes de estudio
                  </Typography>
                ) }
              </AccordionDetails>
            </Accordion>

            { subject.studyPlan?.length > 0 &&
              <Accordion expanded={learningAchievementsByPlanAccordionExpanded} onChange={(event, isExpanded) => setLearningAchievementsByPlanAccordionExpanded(isExpanded)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="learning-achievements-by-plan-content"
                  id="learning-achievements-by-plan-header"
                >
                  <Typography gutterBottom variant="h4" component="div" sx={ { marginBottom: 0 } }>
                    Resultados de aprendizaje por programa
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  { subject.resultadoAprendizaje?.length > 0 ? (
                    <>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="Resultados de aprendizaje por programa">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Nombre plan</TableCell>
                              <TableCell align="left">Objetivo resultado de aprendizaje</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                          { subject.resultadoAprendizaje.filter(learning => !learning.aspectoMacro || (learning.aspectoMacro && learning.aspectoMacro.search('items') !== -1)).map( ( learning ) => (
                            <>
                              <TableRow key={learning.id}>
                                <TableCell align="left">{learning.nombrePlan ? learning.nombrePlan : '-'}</TableCell>
                                <TableCell align="left"><div dangerouslySetInnerHTML={ { __html: learning.resultadoAprendizaje } } style={ { display: 'block' } } /></TableCell>
                              </TableRow>
                              { learning.aspectoMacro && learning.aspectoMacro?.search('items') !== -1 &&
                                <TableRow key={learning.id+'items'}>
                                  <TableCell align="left" colSpan='2'>
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell align="center" colSpan='2'>Resultados de aprendizaje asignatura</TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell align="left">RAA</TableCell>
                                            <TableCell align="left">Aspecto macro</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        { JSON.parse(learning.aspectoMacro).items.map( ( macroAspect, index ) => (
                                            <TableRow key={ learning.id + '-' + index }>
                                              <TableCell align="left">{index +1}</TableCell>
                                              <TableCell align="left">
                                                <div dangerouslySetInnerHTML={ { __html: macroAspect.aspectoMacro } } style={ { display: 'block' } } />
                                              </TableCell>
                                            </TableRow>
                                          ) ) }
                                        </TableBody>
                                      </Table>
                                  </TableCell>
                                </TableRow>
                              }
                            </>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <Typography variant="body1" color="text.secondary">
                      No hay resultados
                    </Typography>
                  ) }
                </AccordionDetails>
              </Accordion>
            }

            { subject.studyPlan?.length === 0 &&        
              <Accordion expanded={learningAchievementsSubjectAccordionExpanded} onChange={(event, isExpanded) => setLearningAchievementsSubjectAccordionExpanded(isExpanded)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="learning-achievements-by-plan-content"
                  id="learning-achievements-by-plan-header"
                >
                  <Typography gutterBottom variant="h4" component="div" sx={ { marginBottom: 0 } }>
                    Resultados de aprendizaje asignatura
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  { subject.resultadoAprendizaje?.length > 0 ? (
                    <>
                      <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} aria-label="Resultados de aprendizaje asignatura">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">Aspecto macro</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            { subject.resultadoAprendizaje.filter(learning => learning.aspectoMacro).map( ( learning, index ) => (
                              <>
                                <TableRow key={learning.id}>
                                  <TableCell align="left"><div dangerouslySetInnerHTML={ { __html: learning.aspectoMacro } } style={ { display: 'block' } } /></TableCell>
                                </TableRow>
                              </>
                              ))}
                            </TableBody>
                          </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <Typography variant="body1" color="text.secondary">
                      No hay resultados
                    </Typography>
                  ) }
                </AccordionDetails>
              </Accordion>
            }

            <Accordion expanded={durationAccordionExpanded} onChange={(event, isExpanded) => setDurationAccordionExpanded(isExpanded)}>
              <AccordionSummary
                expandIcon={ <ExpandMoreIcon /> }
                aria-controls="panel-content-duration"
                id="panel-content-duration">
                <Typography gutterBottom variant="h4" component="div" sx={ { marginBottom: 0 } }>
                  Duración
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={ { xs: 2, md: 3 } } columns={ { xs: 4, sm: 8, md: 12 } }>
                  <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                    <Typography gutterBottom variant="body1" component="div">
                      Horas de actividad presencial a la semana o intensidad horaria
                    </Typography>
                    <Typography variant="h5" color="text.secondary">
                      { subject.HAP  }
                    </Typography>
                  </Grid>
                  <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                    <Typography gutterBottom variant="body1" component="div">
                      Horas de actividad autónoma o independiente a la semana
                    </Typography>
                    <Typography variant="h5" color="text.secondary">
                      { subject.HAI  }
                    </Typography>
                  </Grid>
                  <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                    <Typography gutterBottom variant="body1" component="div">
                      Total de horas de actividad académica por Semana
                    </Typography>
                    <Typography variant="h5" color="text.secondary">
                      { subject.THS  }
                    </Typography>
                  </Grid>
                  <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                    <Typography gutterBottom variant="body1" component="div">
                      Número de semanas por periodo académico o por semestre
                    </Typography>
                    <Typography variant="h5" color="text.secondary">
                      { subject.NUM_SEMANAS  }
                    </Typography>
                  </Grid>
                  <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                    <Typography gutterBottom variant="body1" component="div">
                      Total horas por periodo académico
                    </Typography>
                    <Typography variant="h5" color="text.secondary">
                      { subject.THP  }
                    </Typography>
                  </Grid>
                  <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                    <Typography gutterBottom variant="body1" component="div">
                      Número de créditos por asignatura
                    </Typography>
                    <Typography variant="h5" color="text.secondary">
                      { subject.NUM_CREDITOS  }
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={attendancePercentageAccordionExpanded} onChange={(event, isExpanded) => setAttendancePercentageAccordionExpanded(isExpanded)}>
              <AccordionSummary
                expandIcon={ <ExpandMoreIcon /> }
                aria-controls="panel-content-attendancePercentage"
                id="panel-content-attendancePercentage">
                <Typography gutterBottom variant="h4" component="div" sx={ { marginBottom: 0 } }>
                  Porcentaje de asistencia
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={ { xs: 2, md: 3 } } columns={ { xs: 4, sm: 8, md: 12 } }>
                  <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                    <Typography gutterBottom variant="body1" component="div">
                      Porcentaje
                    </Typography>
                    <Typography variant="h5" color="text.secondary">
                      { subject.PORCENTAJE_ASISTENCIA  }
                    </Typography>
                  </Grid>
                  <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                    <Typography gutterBottom variant="body1" component="div">
                      Horas presenciales
                    </Typography>
                    <Typography variant="h5" color="text.secondary">
                      { subject.HAPXSEMANA  }
                    </Typography>
                  </Grid>
                  <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
                    <Typography gutterBottom variant="body1" component="div">
                      Mínimo de horas
                    </Typography>
                    <Typography variant="h5" color="text.secondary">
                      { subject.MINIMOHORAS  }
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={bibliographyAccordionExpanded} onChange={(event, isExpanded) => setBibliographyAccordionExpanded(isExpanded)}>
              <AccordionSummary
                expandIcon={ <ExpandMoreIcon /> }
                aria-controls="panel-content-bibliography"
                id="panel-content-bibliography">
                <Typography gutterBottom variant="h4" component="div" sx={ { marginBottom: 0 } }>
                  Bibliografías { source === Sources.SubjectByProposedEdition &&
                    <Tooltip title="Las bibliografias pudieron ser modificadas para esta propuesta de edición" color='info'>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" color="text.secondary" component="div">
                  { subject.BIBLIOGRAFIAS ? (
                    <>
                      { JSON.parse( subject.BIBLIOGRAFIAS ).map( ( bibliografia, index ) => (
                        <Box key={ index }>
                          <Typography variant="h6" component="div"><strong>Bibliografía { index + 1 } </strong></Typography>
                          <List dense={ true } key={ index }>
                            <ListItem>
                              <ListItemText>
                                <Typography variant="body1" color="text.secondary"><strong>Tipo de bibliografía: </strong> { bibliografia.tipoBibliografia ? bibliografia.tipoBibliografia : '-' }</Typography>
                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemText>
                                <Typography variant="body1" color="text.secondary"><strong>Bibliografía: </strong> { bibliografia.descripcion ? bibliografia.descripcion : '-' }</Typography>
                              </ListItemText>
                            </ListItem>
                            { bibliografia.autor &&
                              <ListItem>
                                <ListItemText>
                                  <Typography variant="body1" color="text.secondary"><strong>Autor: </strong> { bibliografia.autor ? bibliografia.autor : '-' }</Typography>
                                </ListItemText>
                              </ListItem>
                            }
                            { bibliografia.titulo &&
                              <ListItem>
                                <ListItemText>
                                  <Typography variant="body1" color="text.secondary"><strong>Titulo: </strong> { bibliografia.titulo ? bibliografia.titulo : '-' }</Typography>
                                </ListItemText>
                              </ListItem>
                            }
                            { bibliografia.nombre_editorial &&
                              <ListItem>
                                <ListItemText>
                                  <Typography variant="body1" color="text.secondary"><strong>Nombre editorial: </strong> { bibliografia.nombre_editorial ? bibliografia.nombre_editorial : '-' }</Typography>
                                </ListItemText>
                              </ListItem>
                            }
                            { bibliografia.año &&
                              <ListItem>
                                <ListItemText>
                                  <Typography variant="body1" color="text.secondary"><strong>Año: </strong> { bibliografia.año ? bibliografia.año : '-' }</Typography>
                                </ListItemText>
                              </ListItem>
                            }
                          </List>
                        </Box>
                      ) ) }
                    </>
                  ) : (
                    <Typography variant="body1" color="text.secondary">
                      No hay bibliografias
                    </Typography>
                  ) }
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={goalsAccordionExpanded} onChange={(event, isExpanded) => setGoalsAccordionExpanded(isExpanded)}>
              <AccordionSummary
                expandIcon={ <ExpandMoreIcon /> }
                aria-controls="panel-content-goals"
                id="panel-content-goals">
                <Typography gutterBottom variant="h4" component="div" sx={ { marginBottom: 0 } }>
                  Objetivos
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" color="text.secondary" component="div">
                  { subject.OBJETIVOS ?
                    <div dangerouslySetInnerHTML={ { __html: subject.OBJETIVOS } } /> :
                    'No hay información' }
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={teachingWeeksAccordionExpanded} onChange={(event, isExpanded) => setTeachingWeeksAccordionExpanded(isExpanded)}>
              <AccordionSummary
                expandIcon={ <ExpandMoreIcon /> }
                aria-controls="panel-content-teaching-weeks"
                id="panel-content-teaching-weeks">
                <Typography gutterBottom variant="h4" component="div" sx={ { marginBottom: 0 } }>
                  Contenido detallado { source === Sources.SubjectByProposedEdition &&
                    <Tooltip title="Los contenidos detallados pudieron ser modificado para esta propuesta de edición" color='info'>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                { subject.content && subject.content.length ? (
                  <>
                    { subject.content.map( ( week, index ) => (
                      <Box key={ index }>
                        <Typography variant="h6" component="div"><strong>Semana { index + 1 } </strong></Typography>
                        <List dense={ true } key={ index }>
                          <ListItem>
                            <ListItemText>
                              <Typography variant="body1" color="text.secondary" component="div"><strong style={ { display: 'block', marginBottom: '16px' } }>Contenido especifico: </strong> <div dangerouslySetInnerHTML={ { __html: week.CONTENIDOESPECIFICO } } style={ { display: 'block' } } /></Typography>
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              <Typography variant="body1" color="text.secondary" component="div">
                                <strong style={ { display: 'block', marginBottom: '16px' } }>Contenido detallado: </strong> <div dangerouslySetInnerHTML={ { __html: week.CONTENIDODETALLADO } } style={ { display: 'block' } } /></Typography>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Box>
                    ) ) }
                  </>
                ) : (
                  <Typography variant="body1" color="text.secondary">
                    No hay semanas
                  </Typography>
                ) }
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={teachingMethodologyAccordionExpanded} onChange={(event, isExpanded) => setTeachingMethodologyAccordionExpanded(isExpanded)}>
              <AccordionSummary
                expandIcon={ <ExpandMoreIcon /> }
                aria-controls="panel-content-teaching-methodology"
                id="panel-content-teaching-methodology">
                <Typography gutterBottom variant="h4" component="div" sx={ { marginBottom: 0 } }>
                  Metodologia de enseñanza
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" color="text.secondary" component='div'>
                  { subject.METODOLOGIA ?
                    <div dangerouslySetInnerHTML={ { __html: subject.METODOLOGIA  } } /> : 'No hay información' }
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={teachingRequirementsAccordionExpanded} onChange={(event, isExpanded) => setTeachingRequirementsAccordionExpanded(isExpanded)}>
              <AccordionSummary
                expandIcon={ <ExpandMoreIcon /> }
                aria-controls="panel-content-teaching-requirements"
                id="panel-content-teaching-requirements">
                <Typography gutterBottom variant="h4" component="div" sx={ { marginBottom: 0 } }>
                  Requisitos { source === Sources.SubjectByProposedEdition &&
                    <Tooltip title="Los requisitos pudieron ser modificados para esta propuesta de edición" color='info'>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                { subject.subjectsRequirement && subject.subjectsRequirement.length > 0 ? (
                  <>
                    { subject.subjectsRequirement.map( ( requirement, index ) => (
                      <Box key={ index }>
                        <Typography variant="h6" component="div"><strong>Requisito { index + 1 } </strong></Typography>
                        <List dense={ true } key={ index }>
                          <ListItem>
                            <ListItemText>
                              <Typography variant="body1" color="text.secondary"><strong>Codigo de la asignatura: </strong> { requirement.CODIGO_ASIGNATURA ? requirement.CODIGO_ASIGNATURA : '-' }</Typography>
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              <Typography variant="body1" color="text.secondary"><strong>Nombre de la asignatura: </strong> { requirement.NOMBRE_ASIGNATURA ? requirement.NOMBRE_ASIGNATURA : '-' }</Typography>
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              <Typography variant="body1" color="text.secondary"><strong>Tipo de requisito: </strong> { requirement.TIPO_REQUISITO ? requirement.TIPO_REQUISITO : '-' }</Typography>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Box>
                    ) ) }
                  </>
                ) : (
                  <Typography variant="body1" color="text.secondary">
                    No hay requisitos
                  </Typography>
                ) }
              </AccordionDetails>
            </Accordion>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
