/**
 * Docente
  director Area Curricular
  secretaria Academica cambia a consejo de facultad
  direccion Academica De Sede
  admin
  direccion Academica
  consejo de Facultad
*/
const Roles = {
  Teacher: 'docente',
  DirectCurricularArea: 'directorAreaCurricular',
  AcademicSecretary: 'secretariaAcademica',
  AcademicDirectionOfHeadquarters: 'direccionAcademicaDeSede',
  Admin: 'admin',
  AcademicDirection: 'direccionAcademica',
  FacultyCouncil: 'consejoDeFacultad',
}

export default Roles;
