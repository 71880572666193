import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import Roles from '../../enums/Roles.enum';
import { getSubjectStateByRol } from '../../helpers/Subject.helper';
import { CustomDialog } from '../../helpers/styles/CustomDialog';
import { pxToRem } from '../../utils/unitsConversion';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import Sources from '../../enums/Sources.enum';

const Transition = forwardRef( function Transition ( props, ref )
{
  return <Slide direction="up" ref={ ref } { ...props } />;
} );

function DialogApproveSubject (
  { openApproveRequestOpenDialog,
    handleApproveRequestCloseDialog,
    user,
    actNumberFieldValue,
    handleActNumberFieldChange,
    handleActNumberFieldBlur,
    actNumberFieldTouched,
    actDate,
    handleActDate,
    isLoading,
    handleApproveRequest,
    request,
    source,
  } )
{
  return (
    <CustomDialog
      open={ openApproveRequestOpenDialog }
      onClose={ handleApproveRequestCloseDialog }
      color='info'
      maxWidth="xs"
      TransitionComponent={ Transition }
      keepMounted
      aria-labelledby="alert-return-request-dialog-title"
      aria-describedby="alert-return-request-dialog-description"
    >
      <DialogTitle id="alert-return-request-dialog-title" align='center'>
        <InfoIcon />
        { user.role.toLowerCase() === Roles.AcademicDirectionOfHeadquarters.toLowerCase() ? (
          <Typography variant="h4" component={ 'span' }>
            {source === Sources.SubjectByProposedEdition ? 'La asignatura será editada' : 'Codificar y Registrar en SIA' }
          </Typography>
        ) : (
          <Typography variant="h4" component={ 'span' } sx={ { mb: 3 } } >
            La solicitud será remitida a { getSubjectStateByRol( user.role ).approverName }
          </Typography>
        ) }

      </DialogTitle>

      { user.role.toLowerCase() === Roles.AcademicDirectionOfHeadquarters.toLowerCase() && source !== Sources.SubjectByProposedEdition &&
        <DialogContent align='center'>
          <DialogContentText id="alert-return-request-dialog-description" component="div">
            <Typography variant="body1" sx={ { mb: 3 } }>
              Al crear la asignatura esta pasará a ser vigente para ser programada en los diferentes periodos académicos y será relacionada con el número de acta que se diligenció en la Secretaría de Facultad
            </Typography>
            <Typography variant="body2" sx={ { mb: 3 } }>
              <b>Nota: El siguiente paso es gestionar el acto administrativo, de acuerdo con la normatividad vigente.</b>
            </Typography>
          </DialogContentText>
        </DialogContent>
      }

      { (user.role.toLowerCase() === Roles.DirectCurricularArea.toLowerCase() ||
        user.role.toLowerCase() === Roles.AcademicSecretary.toLowerCase()) && source !== Sources.SubjectByProposedEdition &&
        <DialogContent align='center'>
          <DialogContentText id="alert-return-request-dialog-description" component="div">
            <Typography variant="body1" sx={ { mb: 3 } }>
              Ingrese el número de acta de aprobación
            </Typography>
            <Box sx={{ mb: 3 }}>
              <TextField
                id="actNumber"
                label="Número de acta"
                multiline
                fullWidth
                value={ actNumberFieldValue }
                onChange={ handleActNumberFieldChange }
                onBlur={ handleActNumberFieldBlur }
                required
                minLength={ 5 }
                maxLength={ 20 }
                error={ actNumberFieldTouched && ( actNumberFieldValue.length < 5 || actNumberFieldValue.length > 20 ) }
                helperText={
                  actNumberFieldTouched && (
                    ( actNumberFieldValue.length < 5 && 'Debe tener al menos 5 caracteres.' ) ||
                    ( actNumberFieldValue.length > 20 && 'Debe tener menos de 20 caracteres.' )
                  )
                }
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker label="Fecha del acta" sx={{ width: '100%' }}
                    value={ actDate }
                    onChange={(newValue) => handleActDate(newValue)} />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </DialogContentText>
        </DialogContent>
      }
      <DialogActions sx={ { justifyContent: 'center', gap: pxToRem( 16 ) } }>
        { isLoading ? (
          <Box sx={ { display: 'flex', justifyContent: 'center' } }>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Button
              color='primary'
              variant='outlined'
              size='medium'
              onClick={ () => handleApproveRequestCloseDialog() }
            >Cancelar</Button>

            { (user.role.toLowerCase() === Roles.DirectCurricularArea.toLowerCase() ||
              user.role.toLowerCase() === Roles.AcademicSecretary.toLowerCase()) && source !== Sources.SubjectByProposedEdition ? (
              <Button
                disabled={ ( actNumberFieldValue.length < 5 || actNumberFieldValue.length > 20 ) }
                color='success'
                variant='outlined'
                size='medium'
                onClick={ () => handleApproveRequest( request.IDIDENTIFICADORASIGNATURA ) }
              >{user.role.toLowerCase() === Roles.DirectCurricularArea.toLowerCase() ? 'Recomendar': 'Aprobar'}</Button>
            ) : (
              <Button
                color='success'
                variant='outlined'
                size='medium'
                onClick={ () => handleApproveRequest( request.IDIDENTIFICADORASIGNATURA ) }
              >
                Enviar
              </Button>
            ) }

          </>
        ) }
      </DialogActions>
    </CustomDialog>
  )
}

DialogApproveSubject.propTypes = {
  openApproveRequestOpenDialog: PropTypes.bool,
  handleApproveRequestCloseDialog: PropTypes.func.isRequired,
  user: PropTypes.object,
  actNumberFieldValue: PropTypes.string,
  handleActNumberFieldChange: PropTypes.func.isRequired,
  handleActNumberFieldBlur: PropTypes.func.isRequired,
  actNumberFieldTouched: PropTypes.bool,
  actDate: PropTypes.object,
  handleActDate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  handleApproveRequest: PropTypes.func.isRequired,
  request: PropTypes.object,
};

export default DialogApproveSubject;