import { Navigate, Routes, Route } from 'react-router-dom';
import { HomeView } from '../home/views/HomeView'
import { ProtectedRoute, ProtectedRouteByRole, PublicRoute } from './';
import Shell from '../layouts/Shell';
import SubjectsAppoved from '../subject/subjects-approved/SubjectsApproved';
import PendingRequests from '../requests/pending-requests/PendingRequests';
import PendingRequest from '../requests/pending-request/PendingRequest';
import SubjectView from '../subject/subject-view/SubjectView';
import { SubjectProposal } from '../subject/subject-proposal/SubjectProposal';
import Roles from '../enums/Roles.enum';
import SubjectsTeacher from '../subject/subjects-teacher/SubjectsTeacher';
import SubjectProposalEdit from '../subject/subject-proposal/components/SubjectProposalEdit';
import RequestsByProposedEdition from '../requests/requests-by-proposed-edition/RequestsByProposedEdition';
import RequestByProposedEdition from '../requests/request-by-proposed-edition/RequestByProposedEdition';
import SubjectsByProposedEdition from '../subject/subjects-by-proposed-edition/SubjectsByProposedEdition';
import ManageInterfaceControl from '../interface-control/ManageInterfaceControl';
import { Authentication } from '../auth/views/Authentication/Authentication';
import { ResetPassword } from '../auth/views/Authentication/ResetPassword';

import SubjectsDrafts from '../subject/drafts/SubjectsDrafts'

export const AppRouter = () =>
{

	return (
		<Routes>
			<Route element={ <PublicRoute /> } >
				<Route path="login" element={ <Authentication /> } />
				<Route path="reset-password" element={ <ResetPassword /> } />
			</Route>
			<Route element={ <ProtectedRoute /> } >
				<Route path="home" element={
					<Shell
						content={ <HomeView /> } /> } />

				<Route path="subject-approved" element={
					<Shell
						content={ <SubjectsAppoved /> } /> } />

				<Route path="subject-view/:id" element={
					<Shell
						content={ <SubjectView /> } /> } />

				<Route element={ <ProtectedRouteByRole rolesNotAllowed={ [
					Roles.Teacher,
					Roles.DirectCurricularArea,
					Roles.AcademicDirection,
					Roles.FacultyCouncil,
					Roles.AcademicSecretary,
					Roles.AcademicDirectionOfHeadquarters ] } /> } >
					<Route path="manage-interface-control" element={
						<Shell
							content={ <ManageInterfaceControl /> } /> } />
				</Route>

				<Route element={ <ProtectedRouteByRole rolesNotAllowed={ [
					Roles.DirectCurricularArea,
					Roles.Admin,
					Roles.AcademicDirection,
					Roles.FacultyCouncil,
					Roles.AcademicSecretary,
					Roles.AcademicDirectionOfHeadquarters ] } /> } >

					<Route path="subject-proposal" element={
						<Shell
							content={ <SubjectProposal /> } /> } />

					<Route path="subject-proposal-edit" element={
						<Shell
							content={ <SubjectProposalEdit /> } /> } />

					<Route path="subjects-teacher" element={
						<Shell
							content={ <SubjectsTeacher /> } /> } />

					<Route path="subjects-by-proposed-edition" element={
						<Shell
							content={ <SubjectsByProposedEdition /> } /> } />

					<Route path="subject-drafts" element={
						<Shell
							content={ <SubjectsDrafts /> } /> } />
				</Route>

				<Route element={ <ProtectedRouteByRole rolesNotAllowed={ [Roles.Teacher, Roles.FacultyCouncil, Roles.Admin ] } /> } >
					<Route path="requests-by-proposed-edition" element={
						<Shell
							content={ <RequestsByProposedEdition /> } /> } />

					<Route path="request-for-proposed-edition" element={
						<Shell
							content={ <RequestByProposedEdition /> } /> } />

					<Route path="pending-requests" element={
						<Shell
							content={ <PendingRequests /> } /> } />

					<Route path="pending-request" element={
						<Shell
							content={ <PendingRequest /> } /> } />
				</Route>

			</Route>
			<Route path="/*" element={ <Navigate to="/login" /> } />
		</Routes>
	)
}