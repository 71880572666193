import { AuthProvider } from './auth/providers/AuthProvider';
import { AppRouter } from './router/AppRouter';
import { AppTheme } from './theme/AppTheme'

const DinaraApp = ( ) => {
    return (
        <AuthProvider>
            <AppTheme>
              <AppRouter />
            </AppTheme>
        </AuthProvider>
    );
}

export default DinaraApp;