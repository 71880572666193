import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, debounce, List, ListItem, ListItemText, MenuItem, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import createAxiosInstance from '../../../utils/AxiosConfig';

export const PregradoStudyPlansForm = ({ sendPlanEvent, deletePlanEvent, plan, index, plans, subjectByProposedEdition, validate }) => {

	const [formState, setFormState] = useState({
		isValid: false,
		fields: {
			codigoPlan: {
				isValid: false,
				isTouched: false,
			},
			nombrePlan: {
				isValid: false
			},
			agrupacion: {
				isValid: true
			},
			tipologia: {
				isValid: false
			},
		}
	});

	const [plansData, setPlansData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [filter, setFilter] = useState('');
	const [selectedPlan, setSelectedPlan] = useState(null);

	useEffect(() => {
		setIsValidForm(plan);
		setIsValidField('codigoPlan', plan.codigoPlan);
		setIsValidField('nombrePlan', plan.nombrePlan);
		setIsValidField('agrupacion', plan.agrupacion);
		setIsValidField('tipologia', plan.tipologia);
		if (filter.trim() !== '' && filter.trim().length > 2) {
			fetchPlans(filter);
		}
	}, [plan, filter]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		plan[name] = value;
		handleProcessForm(name, value, plan, index);
	};

	const handleProcessForm = (name, value, plan, index) => {
		setIsValidField(name, value);
		setIsValidForm(plan);

		plan.isValid = isValidPlan(plan);

		sendPlanEvent(plan, index);
	}

	const deletePlanClick = () => {
		deletePlanEvent(index);
	}

	const handleBlur = (e) => {
		const { name } = e.target;
		setIsTouchedField(name);
	}

	const setIsTouchedField = (name) => {
		const updatedFields = { ...formState.fields };
		updatedFields[name].isTouched = true;
		setFormState({
			...formState,
			fields: updatedFields,
		});
	}

	const setIsValidField = (name, value) => {
		const updatedFields = { ...formState.fields };
		updatedFields[name].isValid = !!value.trim();

		setFormState({
			...formState,
			fields: updatedFields,
		});
	}

	const isValidPlan = () => {
		return !!(plan.codigoPlan && plan.nombrePlan && plan.tipologia)
	}

	const setIsValidForm = (plan) => {
		const isValid = isValidPlan(plan);
		setFormState({
			...formState,
			isValid
		});
	}

	const fetchPlans = debounce(async (filter) => {
		setLoading(true);
		try {
		  const response = await createAxiosInstance('https://buscadorsgadinara.com/').get(`/api/v1/planestudiodisponible/find-plans-by-code?filter=${filter}`);
		  setPlansData(Array.isArray(response.data) ? response.data : []);
		} catch (error) {
		  console.error('Error fetching plans:', error);
		} finally {
		  setLoading(false);
		}
	  }, 300);
	
	  const handleFilterChange = (event) => {
		setFilter(event.target.value);
	  };
	
	  const handleSelectPlan = (selectedPlan) => {
		setSelectedPlan(plan);
		setFilter('');  // Limpia el filtro una vez seleccionado un plan
		setPlansData([]);   // Limpia las coincidencias después de seleccionar
		plan['codigoPlan'] = selectedPlan?.ID.toString();
		handleProcessForm('codigoPlan', plan['codigoPlan'], plan, index);
		plan['nombrePlan'] = selectedPlan?.PLANDEESTUDIO;
		handleProcessForm('nombrePlan', plan['nombrePlan'], plan, index);
	  };

	const tipologyData = [
		{
			value: 'Disciplinar',
			label: 'Disciplinar',
		},
		{
			value: 'Fundamentación',
			label: 'Fundamentación',
		},
	];

	return (
		<Box
			component="form"
			sx={{
				'& .MuiTextField-root': { width: '100%' },
			}}
			noValidate
			autoComplete="off"
		>
			{ !formState.isValid &&
				<Typography variant="caption" display="block" gutterBottom color="primary">
					Por favor rellena todos los campos
				</Typography>}
			<Grid
				container spacing={{ xs: 3 }}>
				<Grid item  sm={12}>
					<Typography variant="body1" display="block" gutterBottom sx={{ mb: 2 }}>
						Plan de estudios {index + 1}
					</Typography>
					<Grid container spacing={{ xs: 3 }}>
						<Grid item xs={12} sm={4}>
							<TextField
								label="Buscar"
								fullWidth
								value={filter}
								onChange={handleFilterChange}
								size='small'
								helperText='Busca los planes de estudio por código y nombre'
							/>
							{loading && <CircularProgress />}
							{!loading && plansData.length > 0 && (
								<Paper style={{ maxHeight: '200px', overflowY: 'auto' }}>
									<List>
										{plansData.map((plan) => (
										<ListItem component="button" key={plan.ID} onClick={() => handleSelectPlan(plan)}>
											<ListItemText primary={plan.CODIGO_ASIGNATURA} secondary={plan.PLANDEESTUDIO} />
										</ListItem>
										))}
									</List>
								</Paper>
							)}

							{filter.trim() !== '' && filter.trim().length > 2 && plansData.length === 0 &&
								<Typography variant="overline" display="block" gutterBottom color="secondary" sx={{ml: 2}}>
									No se han encontrado resultados...
								</Typography>
							}
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								disabled={ true }
								required
								id="studyPlanFormCodigo"
								name="codigoPlan"
								label="Código"
								onChange={(e) => handleChange(e)}
								onBlur={(e) => handleBlur(e)}
								error={
									(formState.fields.codigoPlan.isTouched &&
									!formState.fields.codigoPlan.isValid) || (!formState.fields.codigoPlan.isValid && validate)
								}
								helperText={
									(formState.fields.codigoPlan.isTouched &&
										!formState.fields.codigoPlan.isValid) || (!formState.fields.codigoPlan.isValid && validate) ?
										'El campo es requerido' :
										''
								}
								value={plan.codigoPlan ?? ''}
								size='small'
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								disabled={ true }
								required
								id="studyPlanFormNombre"
								name="nombrePlan"
								label="Nombre del plan de estudios"
								size='small'
								value={plan.nombrePlan ?? ''}
								onChange={(e) => handleChange(e)}
								// onBlur={(e) => handleBlur(e)}
								error={
									(formState.fields.nombrePlan.isTouched &&
									!formState.fields.nombrePlan.isValid) || (!formState.fields.nombrePlan.isValid && validate)
								}
								helperText={
									(formState.fields.nombrePlan.isTouched &&
										!formState.fields.nombrePlan.isValid) || (!formState.fields.nombrePlan.isValid && validate) ?
										'El campo es requerido' :
										''
								}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								id="studyPlanFormComponente"
								name="agrupacion"
								disabled={plan.id && subjectByProposedEdition}
								label="Agrupación"
								size='small'
								value={plan.agrupacion ?? ''}
								onChange={(e) => handleChange(e)}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								required
								id="studyPlanFormComponente"
								name="tipologia"
								disabled={plan.id && subjectByProposedEdition}
								label="Fundamentación | Disciplinar"
								size='small'
								select
								value={plan.tipologia ?? ''}
								onChange={(e) => handleChange(e)}
								onBlur={(e) => handleBlur(e)}
								error={
									(formState.fields.tipologia.isTouched &&
									!formState.fields.tipologia.isValid) || (!formState.fields.tipologia.isValid && validate)
								}
								helperText={
									(formState.fields.tipologia.isTouched &&
										!formState.fields.tipologia.isValid) || (!formState.fields.tipologia.isValid && validate) ?
										'El campo es requerido' :
										''
								}
							>
							{ tipologyData.map( ( option ) => (
								<MenuItem key={ option.value } value={ option.value }>
									{ option.label }
								</MenuItem>
							) ) }
							</TextField>
						</Grid>
					</Grid>
				</Grid>
				
				{plans && plans.length > 1 &&
					<Grid item xs={12}>
						<Button startIcon={<RemoveCircleIcon />} color="error" sx={{ textTransform: 'initial' }} type='button' onClick={deletePlanClick} disabled={plan.id && subjectByProposedEdition}>
							Eliminar
						</Button>
					</Grid>
				}
			</Grid>
		</Box>
	)
}
