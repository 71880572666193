import { Box, Button, Grid, Typography } from "@mui/material";
import { LearningAchievementByStudyPlanForm } from "./components/LearningAchievementByStudyPlanForm";
import { useEffect, useState } from "react";
import { LearningAchievementSubjectForm } from "./components/LearningAchievementSubjectForm";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AlertDialog from "../../../../shared/components/AlertDialog";

export const LearningAchievements = ({
  formDataEvent,
  setFormDataEvent,
  plans,
  subjectByProposedEdition,
  learningAchievementsValidateEmitter,
  validate,
}) => {
  const regex = /<[^>]+>/g;
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState({
    title: "",
    type: "info",
    description: "",
    primaryButtonText: "",
    redirectTo: "",
  });

  // resultados de aprendizaje por plan
  const [
    learningAchievementsByPlanFormData,
    setLearningAchievementsByPlanFormData,
  ] = useState([]);

  // resultado de aprendizaje de la asignatura
  const [
    learningAchievementsSubjectFormData,
    setLearningAchievementsSubjectFormData,
  ] = useState([]);

  useEffect(() => {
    initLearningAchievementsByPlanFormData();
    initLearningAchievementsSubjectFormData();
  }, []);

  const handleDialog = () => {
    setOpenDialog(false);
  };

  const initLearningAchievementsByPlanFormData = () => {
    let result = [];

    // si ya llegan los resultados de aprendizaje por plan, guardados en la base de datos los seteamos
    if (formDataEvent.resultadosAprendizajePorPlan?.length) {

      // dejar solo los resultados de aprendizaje que coincidan con los planes de estudio
      result = formDataEvent.resultadosAprendizajePorPlan.filter(learning => plans.some(plan => plan.nombrePlan === learning.nombrePlan));

      // result = formDataEvent.resultadosAprendizajePorPlan.map((learning, index) => {
      //   return {
      //     ...learning,
      //     nombrePlan: plans[index]?.nombrePlan
      //   }
      // })


      if (plans.length !== result.length) {
        const noAddPlans = plans.filter(plan => !result.some(learning => learning.nombrePlan === plan.nombrePlan));
        const dataToAdd = noAddPlans.map(plan => {
          return {
            nombrePlan: plan.nombrePlan,
            resultadoAprendizaje: "",
          }
        });

        result = result.concat(dataToAdd);
      }

      setLearningAchievementsByPlanFormData(result);
      setFormDataEvent((prevLearningAchievementFormData) => {
        return {
          ...prevLearningAchievementFormData,
          resultadosAprendizajePorPlan: result,
        };
      });
      return;
    }

    // si no hay resultados de aprendizaje por plan guardados pero si planes, construimos la data
    if (plans && plans.length) {
      plans.forEach((plan) => {
        result.push({
          resultadoAprendizaje: "",
          nombrePlan: plan.nombrePlan,
        });
      });
      setLearningAchievementsByPlanFormData(result);
      return;
    }

    // setLearningAchievementsByPlanFormData([
    //   {
    //     resultadoAprendizaje: "",
    //     nombrePlan: "",
    //   },
    // ]);
  };

  const initLearningAchievementsSubjectFormData = () => {
    let result = [];

    // reiniciar los resultados de aprendizaje asignatura si tiene planes
    if (plans && plans.length) {
      setLearningAchievementsSubjectFormData(result);
      setFormDataEvent((prevLearningAchievementFormData) => {
        return {
          ...prevLearningAchievementFormData,
          resultadosAprendizajeAsignatura: [],
        };
      });
      return;
    }

    // si ya llegan los resultados de aprendizaje por plan, guardados en la base de datos los seteamos
    if (formDataEvent.resultadosAprendizajeAsignatura?.length) {
      result = formDataEvent.resultadosAprendizajeAsignatura;
      setLearningAchievementsSubjectFormData(result);
      return;
    } else {
      setLearningAchievementsSubjectFormData([
        {
          aspectoMacro: "",
          resultadoAprendizaje: ""
        },
      ]);
    }
  };

  const handleLearningAchievementByStydyPlan = (data, index) => {
    setLearningAchievementsByPlanFormData(
      (prevLearningAchievementByStydyPlanData) => {
        prevLearningAchievementByStydyPlanData[index] = data;
        updateLearningAchievementsFormData(
          prevLearningAchievementByStydyPlanData
        );
        return prevLearningAchievementByStydyPlanData;
      }
    );
  };

  // const canAddLearningAchievementsByPlan = () => {
  //   const isValid = learningAchievementsByPlanFormData?.every(
  //     (learning) =>
  //       learning?.nombrePlan &&
  //       learning?.resultadoAprendizaje?.replace(regex, "")?.trim()
  //   );
  //   return isValid;
  // };

  // agregar resultados de aprendizaje por plan
  // const handleAddLearningAchievementsByPlanClick = () => {
  //   if (canAddLearningAchievementsByPlan()) {

  //     learningAchievementsValidateEmitter(false);

  //     const dataToAdded = [
  //       ...learningAchievementsByPlanFormData,
  //       {
  //         resultadoAprendizaje: "",
  //         nombrePlan: "",
  //       },
  //     ];

  //     setLearningAchievementsByPlanFormData(dataToAdded);

  //     setFormDataEvent((prevLearningAchievementFormData) => {
  //       return {
  //         ...prevLearningAchievementFormData,
  //         resultadosAprendizajePorPlan: dataToAdded,
  //       };
  //     });

  //   } else {
  //     setDialogMessage({
  //       title:
  //         "No puede agregar mas resultados de aprendizaje por programa sin haber rellenado los campos",
  //       type: "info",
  //       description: "",
  //       primaryButtonText: "Aceptar",
  //       redirectTo: "",
  //     });
  //     setOpenDialog(true);
  //   }
  // };

  const handleLearningAchievementSubject = (data, index) => {
    setLearningAchievementsSubjectFormData((prevLearningAchievementSubject) => {
      prevLearningAchievementSubject[index] = data;

      setFormDataEvent((prevLearningAchievementFormData) => {
        return {
          ...prevLearningAchievementFormData,
          resultadosAprendizajeAsignatura: prevLearningAchievementSubject,
        };
      });
      return prevLearningAchievementSubject;
    });
  };

  const canAddLearningAchievementsSubject = () => {
    const isValid = learningAchievementsSubjectFormData?.every(
      (learning) =>
        learning?.aspectoMacro?.replace(regex, "")?.trim()
    );
    return isValid;
  };

  const handleAddLearningAchievementsSubjectClick = () => {
    if (canAddLearningAchievementsSubject()) {
      learningAchievementsValidateEmitter(false);
      const dataToAdded = [
        ...learningAchievementsSubjectFormData,
        {
          aspectoMacro: "",
          resultadoAprendizaje: "null",
        },
      ];
      setLearningAchievementsSubjectFormData(dataToAdded);

      setFormDataEvent((prevLearningAchievementFormData) => {
        return {
          ...prevLearningAchievementFormData,
          resultadosAprendizajeAsignatura: dataToAdded,
        };
      });
    } else {
      setDialogMessage({
        title:
          "No puede agregar mas resultados de aprendizaje asignatura sin haber rellenado los campos",
        type: "info",
        description: "",
        primaryButtonText: "Aceptar",
        redirectTo: "",
      });
      setOpenDialog(true);
    }
  };

  const handleDeleteLearningAchievementsSubjectClick = (index) => {
    learningAchievementsSubjectFormData.splice(index, 1);
    setLearningAchievementsSubjectFormData([]);
    setTimeout(() => {
      setLearningAchievementsSubjectFormData(
        learningAchievementsSubjectFormData
      );
      setFormDataEvent((prevLearningAchievementFormData) => {
        return {
          ...prevLearningAchievementFormData,
          resultadosAprendizajeAsignatura: learningAchievementsSubjectFormData,
        };
      });
    }, 0);
  };

  // actualizar resultados de aprendizaje asignatura de acuerdo a los planes
  const updateLearningAchievementsFormData = (learningAchievementsByPlan) => {
    const filtered = learningAchievementsSubjectFormData.map((learning) => {
      const foundLearningAchievements = learningAchievementsByPlan.find(
        (l) => l.nombrePlan === learning.nombrePlan
      );
      return {
        ...learning,
        nombrePlan: foundLearningAchievements ? learning.nombrePlan : "",
        resultadoAprendizaje: foundLearningAchievements ? foundLearningAchievements.resultadoAprendizaje : '',
        // aspectoMacro: learning.aspectoMacro,
      };
    });
    setLearningAchievementsSubjectFormData(filtered);

    setFormDataEvent((prevLearningAchievementFormData) => {
      return {
        ...prevLearningAchievementFormData,
        resultadosAprendizajeAsignatura: filtered,
        resultadosAprendizajePorPlan: learningAchievementsByPlan,
      };
    });
  };

  return (
    <Grid
      sx={{
        "& .MuiTextField-root": { width: "100%" },
      }}
    >
      {learningAchievementsByPlanFormData &&
        learningAchievementsByPlanFormData.length > 0 && (
          <Box sx={{ mb: 6 }}>
            <Typography variant="h6" sx={{ mb: 3, textAlign: "center" }}>
              Resultados de aprendizaje por programa
            </Typography>
            <Grid container spacing={{ xs: 3 }}>
              {learningAchievementsByPlanFormData.map(
                (learningAchievement, index) => (
                  <Grid item sm={12} key={index}>
                    <LearningAchievementByStudyPlanForm
                      learningAchievementsValidateEmitter={learningAchievementsValidateEmitter}
                      formValidate={validate}
                      subjectByProposedEdition={subjectByProposedEdition}
                      learningAchievement={learningAchievement}
                      sendDataForm={handleLearningAchievementByStydyPlan}
                      index={index}
                    />
                    <hr></hr>
                  </Grid>
                )
              )}
            </Grid>
            {/* <Grid item xs={12} sx={{ mt: 3 }}>
              <Button
                startIcon={<AddCircleIcon />}
                sx={{ textTransform: "initial" }}
                type="button"
                onClick={handleAddLearningAchievementsByPlanClick}
                disabled={subjectByProposedEdition}
              >
                Añadir
              </Button>
            </Grid> */}
          </Box>
        )}

      {(!plans || !plans.length) && (
          <Box>
            <Typography variant="h6" sx={{ mb: 3, textAlign: "center" }}>
              Resultados de aprendizaje asignatura
            </Typography>
            <Grid container spacing={{ xs: 3 }}>
              {learningAchievementsSubjectFormData.map(
                (learningAchievement, index) => (
                  <Grid item sm={12} key={index}>
                    <LearningAchievementSubjectForm
                      subjectByProposedEdition={subjectByProposedEdition}
                      formValidate={validate}
                      learningAchievement={learningAchievement}
                      itemCount={learningAchievementsSubjectFormData.length}
                      sendDataForm={handleLearningAchievementSubject}
                      handleDelete={
                        handleDeleteLearningAchievementsSubjectClick
                      }
                      index={index}
                    />
                  </Grid>
                )
              )}
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Button
                  startIcon={<AddCircleIcon />}
                  sx={{ textTransform: "initial" }}
                  type="button"
                  onClick={handleAddLearningAchievementsSubjectClick}
                  disabled={subjectByProposedEdition}
                >
                  Añadir
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      <AlertDialog
        openDialog={openDialog}
        handleCloseDialog={handleDialog}
        title={dialogMessage.title}
        type={dialogMessage.type}
        primaryButtonText={dialogMessage.primaryButtonText}
        description={dialogMessage.description}
      ></AlertDialog>
    </Grid>
  );
};
