import createAxiosInstance from '../../utils/AxiosConfig';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend( utc );
dayjs.extend( timezone );
dayjs.tz.setDefault( 'America/Bogota' );

export const UseLogicManageInterfaceControl = () =>
{
  const [ interfaces, setInterfaces ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( false );
  const [ isModeEdit, setIsModeEdit ] = useState( false );
  const [ id, setId ] = useState( '' );
  const { control, handleSubmit, formState: { errors }, reset } = useForm();
  const [ Campuses, setCampuses ] = useState( [] );
  const [ openDialog, setOpenDialog ] = useState( false );
  const [ dialogMessage, setDialogMessage ] = useState( {
    title: '',
    type: 'info',
    description: '',
    primaryButtonText: '',
    redirectTo: '',
  } );

  const todayDate = dayjs.utc().subtract( 1, 'day' ).format( 'YYYY-MM-DD' );
  const futureDate = dayjs.utc().add( 4, 'month' ).format( 'YYYY-MM-DD' );
  const [ startDate, setStartDate ] = useState( dayjs.utc( todayDate ) );
  const [ endDate, setEndDate ] = useState( dayjs.utc( futureDate ) );
  const [ idEstado, setIdEstado ] = useState( true );

  const [ openCreateInterfaceDialog, setCreateInterfaceDialog ] = useState( false );

  const fetchInterfaces = async ( loadCampuses ) =>
  {
    setIsLoading( true );
    try
    {
      const { status, data } = await createAxiosInstance().get( `/t-control-interface` );
      setIsLoading( false );
      if ( loadCampuses )
      {
        fetchCampuses();
      }
      if ( status === 200 )
      {
        setInterfaces( data );
      }
    } catch ( error )
    {
      setIsLoading( false );
      setDialogMessage(
        {
          title: 'Ocurrio un error al momento de obtener los controles de interfaz',
          type: 'error',
          description: '',
          primaryButtonText: 'Aceptar',
          redirectTo: '',
        }
      );
      setOpenDialog( true );
    }
  }

  const fetchCampuses = async () =>
  {
    setIsLoading( true );
    try
    {
      const { status, data } = await createAxiosInstance().get( `/sede` );

      if ( status === 200 )
      {
        setCampuses( data );
      }
      setIsLoading( false );
    } catch ( error )
    {
      setIsLoading( false );
      setDialogMessage(
        {
          title: 'Ocurrio un error al momento de obtener las sedes',
          type: 'error',
          description: '',
          primaryButtonText: 'Aceptar',
          redirectTo: '',
        }
      );
      setOpenDialog( true );
    }
  };

  const resetData = {
    idinterface: '',
    descripcionInterface: '',
    idSede: '',
  };

  useEffect( () =>
  {
    fetchInterfaces( true );
  }, [] );

  const handleDialog = () =>
  {
    setOpenDialog( false );
  }

  const handleCreateInterface = () =>
  {
    setIsModeEdit( false );
    handleCreateInterfaceOpenDialog();
    resetForm();
  }

  const handleCreateInterfaceOpenDialog = () =>
  {
    setCreateInterfaceDialog( true );
  };


  const handleCreateInterfaceCloseDialog = () =>
  {
    setCreateInterfaceDialog( false );
  };

  const handleDeleteInterface = async ( id ) =>
  {
    setIsLoading( true );
    try
    {
      const { status } = await createAxiosInstance().delete( `/t-control-interface/${ id }` );
      setIsLoading( false );
      if ( status === 200 || status === 201 )
      {
        handleCreateInterfaceCloseDialog();
        setDialogMessage( {
          title: 'Control de interfaz eliminada correctamente!',
          type: 'success',
          description: '',
          primaryButtonText: 'Aceptar',
          redirectTo: ''
        } );
        setOpenDialog( true );
        const filteredInterfaces = interfaces.filter( i => i.id !== id );
        setInterfaces( [ ...filteredInterfaces ] );
      }
    } catch ( error )
    {
      setIsLoading( false );
      setDialogMessage(
        {
          title: 'Ocurrio un error al eliminar el control de interfaz',
          type: 'error',
          description: '',
          primaryButtonText: 'Aceptar',
          redirectTo: '',
        }
      );
      setOpenDialog( true );
    }
  }

  const handleEditInterface = ( interfaceControl ) =>
  {
    setIsModeEdit( true );
    handleCreateInterfaceOpenDialog();
    reset( {
      idinterface: interfaceControl.idinterface,
      descripcionInterface: interfaceControl.descripcionInterface,
      idSede: interfaceControl.idSede,
    } );
    setId( interfaceControl.id );
    const startDateFormat = dayjs.utc( interfaceControl.vigenciaInicial ).format( 'YYYY-MM-DD' );
    const endDateFormat = dayjs.utc( interfaceControl.vigenciaFinal ).format( 'YYYY-MM-DD' );
    setStartDate( dayjs.utc( startDateFormat ) );
    setEndDate( dayjs.utc( endDateFormat ) );
    setIdEstado( !!interfaceControl.idEstado );
  }

  const resetForm = () =>
  {
    reset( resetData );
    setId( '' );
    setIdEstado( false );
    setStartDate( dayjs.utc( todayDate ) );
    setEndDate( dayjs.utc( futureDate ) );
  }

  const createInterface = async ( request ) =>
  {
    setIsLoading( true );
    try
    {
      const { status } = await createAxiosInstance().post( `/t-control-interface`, request );
      setIsLoading( false );
      if ( status === 200 || status === 201 )
      {
        handleCreateInterfaceCloseDialog();
        setDialogMessage( {
          title: 'Control de interfaz creada correctamente!',
          type: 'success',
          description: '',
          primaryButtonText: 'Aceptar',
          redirectTo: ''
        } );
        setOpenDialog( true );
        resetForm();
        fetchInterfaces();
      }
    } catch ( error )
    {
      setIsLoading( false );
      setDialogMessage(
        {
          title: 'Ocurrio un error al momento de crear el control de interfaz',
          type: 'error',
          description: '',
          primaryButtonText: 'Aceptar',
          redirectTo: '',
        }
      );
      setOpenDialog( true );
    }
  }

  const updateInterface = async ( requestData ) =>
  {
    setIsLoading( true );
    try
    {
      const request = {
        ...requestData,
        id,
      }
      const { status } = await createAxiosInstance().patch( `/t-control-interface/${ request.id }`, request );
      setIsLoading( false );
      if ( status === 200 || status === 201 )
      {
        handleCreateInterfaceCloseDialog();
        setDialogMessage( {
          title: 'Control de interfaz actualizado correctamente!',
          type: 'success',
          description: '',
          primaryButtonText: 'Aceptar',
          redirectTo: ''
        } );
        setOpenDialog( true );
        resetForm();
        fetchInterfaces();
      }
    } catch ( error )
    {
      setIsLoading( false );
      handleCreateInterfaceCloseDialog();
      setDialogMessage(
        {
          title: 'Ocurrio un error al momento de actualizar el control de interfaz',
          type: 'error',
          description: '',
          primaryButtonText: 'Aceptar',
          redirectTo: '',
        }
      );
      setOpenDialog( true );
    }
  }

  const onSubmit = ( data ) =>
  {
    const request = {
      idinterface: data.idinterface,
      descripcionInterface: data.descripcionInterface,
      vigenciaInicial: dayjs.utc( startDate ).format( 'YYYY-MM-DD' ),
      vigenciaFinal: dayjs.utc( endDate ).format( 'YYYY-MM-DD' ),
      idSede: data.idSede,
      fechaIdEstado: todayDate,
      idEstado: idEstado ? 1 : 0,
    }

    if ( isModeEdit )
    {
      updateInterface( request );
    } else
    {
      createInterface( request );
    }
  };

  return {
    interfaces,
    isLoading,
    handleDialog,
    dialogMessage,
    openDialog,
    openCreateInterfaceDialog,
    handleCreateInterface,
    handleCreateInterfaceCloseDialog,
    handleEditInterface,
    handleDeleteInterface,
    control,
    errors,
    handleSubmit,
    onSubmit,
    Campuses,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    idEstado,
    setIdEstado,
  }
}
