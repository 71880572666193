import Roles from '../enums/Roles.enum';
import SubjectByState from '../enums/SubjectByState.enum';
import SubjectStatus from '../enums/SubjectStatus.enum';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { interfaceList } from '../shared/config/control-interfaces-data';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Bogota');

export const getSubjectStateByRol = ( role ) =>
{

  let result = {
    review: '',
    rejected: '',
    approved: '',
    approverName: '',
  }

  switch ( role.toLowerCase() )
  {
    case Roles.DirectCurricularArea.toLowerCase():
      result = {
        review: SubjectByState.ReviewCurriculumAreaDirector, // 2
        rejected: SubjectByState.RejectedByCurriculumAreaDirector, // 3
        approved: SubjectByState.ReviewAcademicSecretary, // 4
        approverName: 'Secretaría de Facultad',
      };
      break;
    case Roles.AcademicSecretary.toLowerCase():
      result = {
        review: SubjectByState.ReviewAcademicSecretary, // 4
        rejected: SubjectByState.RejectedByAcademicSecretary, // 5
        approved: SubjectByState.ReviewAcademicDepartmentSite, // 6
        approverName: 'Dirección Academica de Sede',
      };
      break;
    case Roles.AcademicDirectionOfHeadquarters.toLowerCase():
      result = {
        review: SubjectByState.ReviewAcademicDepartmentSite, // 6
        rejected: SubjectByState.RejectedByAcademicDepartmentSite, // 7
        approved: SubjectByState.SubjectAcceptedByAcademicDepartmentSite, // 8
      };
      break;
    default:
      result = {
        review: '',
        rejected: '',
        approved: '',
        approverName: '',
      };
      break;
  }

  return result;
}

export const getStatusSubject = ( subjectState ) =>
{
  if ( subjectState )
  {
    const stateId = subjectState.id;
    if ( stateId === SubjectByState.ReviewCurriculumAreaDirector
      || stateId === SubjectByState.ReviewAcademicSecretary
      || stateId === SubjectByState.ReviewAcademicDepartmentSite )
    {
      return {
        status: SubjectStatus.Pending,
        message: subjectState.descripcion,
      };
    }

    if ( stateId === SubjectByState.RejectedByAcademicSecretary
      || stateId === SubjectByState.RejectedByAcademicDepartmentSite)
    {
      return {
        statu: SubjectStatus.Rejected,
        message: subjectState.descripcion,
      };
      // return SubjectStatus.Draft;
    }
    
    if (stateId === SubjectByState.SubjectDraft )
    {
      return {
        status: SubjectStatus.DraftSecond,
        message: subjectState.descripcion,
      };
    } 

    if ( stateId === SubjectByState.RejectedByCurriculumAreaDirector )
    {
      return {
        status: SubjectStatus.Rejected,
        message: subjectState.descripcion,
      };
    }

    if ( stateId === SubjectByState.SubjectAcceptedByAcademicDepartmentSite )
    {
      return {
        status: SubjectStatus.Approved,
        message: subjectState.descripcion,
      };
    }
  }

  return {
    status: SubjectStatus.Pending,
    message: subjectState.descripcion,
  };
}

export const getCampusAndFacultyFromUAB = (idUAB, campusesData) => {
  if ( !idUAB && !campusesData && !campusesData.length )
    {
      return null;
    }

    const campus = campusesData.find( campus => campus.Facultades.some( faculty => faculty.UABS.some( uab => uab.id === idUAB ) ) );
    if ( campus )
    {
      const faculty = campus.Facultades.find( faculty => faculty.UABS.some( uab => uab.id === idUAB ) );
      const uab = faculty.UABS.find( uab => uab.id === idUAB );
      return {
        campus: {
          name: campus.NOMBRE,
          id: campus.ID,
        },
        faculty: {
          name: faculty.NOMBRE,
          id: faculty.id,
        },
        UAB: {
          name: uab.NOMBRE,
          id: uab.id,
        },
      };
    }
    return null;
}

export const controlInterfaceCreateSubjectByRol = ( interfaces, userRole ) =>
{
  if ( userRole === Roles.Teacher )
  {
    const subjectCreate = interfaces.find( control => control.idinterface === interfaceList[ 0 ].value );
    if ( subjectCreate && subjectCreate.vigenciaInicial && subjectCreate.vigenciaFinal )
    {

      if ( !subjectCreate.idEstado )
      {
        return {
          reason: subjectCreate.descripcionInterface,
          isValid: false,
        };
      }

      const startDate = dayjs.utc( subjectCreate.vigenciaInicial );
      const endDate = dayjs.utc( subjectCreate.vigenciaFinal );
      const today = dayjs.utc().subtract(1, 'day');
      const isRange = today.isBetween( startDate, endDate );
      return {
        reason: subjectCreate.descripcionInterface,
        isValid: isRange,
      };
    }
    return {
      isValid: true,
    };

  } else
  {
    return {
      isValid: true,
    };
  }
}