import createAxiosInstance from '../../../utils/AxiosConfig';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Sources from '../../../enums/Sources.enum';

export const UseLogicSubjectView = () =>
{
  const [ subject, setSubject ] = useState();
  const [ isLoading, setIsLoading ] = useState( true );
  const [ openDialog, setOpenDialog ] = useState( false );
  const { state } = useLocation();
  const source = state ? state.source : null;
  const { id } = useParams();

  useEffect( () =>
  {
    const fetchSubject = async () =>
    {
      try
      {

        if (source === Sources.SubjectByProposedEdition) {
          const response = await createAxiosInstance().get( `/edited-subject/${ id }` );
          if (!response.data) {
            handleClickOpenDialog();
          } else {
            setSubject( response.data );
          }
        }

        if (source === Sources.SubjectByProposedCreation || source === Sources.DraftSubjects) {
          const response = await createAxiosInstance().get( `/subjects/${ id }` );
          if (!response.data) {
            handleClickOpenDialog();
          } else {
            setSubject( response.data );
          }
        }

        if (source === Sources.SubjectApproved) {
          const response = await createAxiosInstance().get( `/subject-approved/${ id }` );
          if (!response.data) {
            handleClickOpenDialog();
          }
          if (!response.data) {
            handleClickOpenDialog();
          } else {
            setSubject( response.data[0] );
          }
        }

        setIsLoading( false );
      } catch ( error )
      {
        setIsLoading( false );
        handleClickOpenDialog();
      }
    };

    fetchSubject();

  }, [] );

  const handleClickOpenDialog = () =>
  {
    setOpenDialog( true );
  };

  const handleCloseDialog = () =>
  {
    setOpenDialog( false );
  };

  return {
    subject,
    isLoading,
    handleCloseDialog,
    openDialog,
    source,
  }
}