import
{
  MenuButtonBold,
  MenuButtonItalic,
  MenuButtonBlockquote,
  MenuButtonBulletedList,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  MenuButtonRedo,
  MenuButtonUndo,
} from 'mui-tiptap';

export default function RichTextFieldControls ()
{
  return (
    <MenuControlsContainer>
      <MenuSelectHeading />
      <MenuDivider />
      <MenuButtonBold />
      <MenuButtonItalic />
      <MenuButtonBlockquote />
      <MenuButtonBulletedList />
      <MenuButtonRedo />
      <MenuButtonUndo />
      {/* Add more controls of your choosing here */ }
    </MenuControlsContainer>
  )
}