import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import
  {
    RichTextEditorProvider,
    RichTextField
  } from 'mui-tiptap';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import RichTextFieldControls from './RichTextFieldControls';
import { validationsPerField } from '../../../shared/config/validations-per-field';

function SubjectWeekForm ( { sendWeekEvent, deleteWeekEvent, week, index, weeks, subjectByProposedEdition, validate } )
{
  const [ formState, setFormState ] = useState( {
    isValid: false,
    fields: {
      CONTENIDODETALLADO: {
        isValid: false
      },
      CONTENIDOESPECIFICO: {
        isValid: false
      },
    }
  } );

  const specificContentEditor = useEditor( {
    extensions: [ StarterKit ],
    content: week.CONTENIDOESPECIFICO ?? '',
    onBlur: () =>
    {
      setIsTouchedField( 'CONTENIDOESPECIFICO' );
    },
    onUpdate: ( { editor } ) =>
    {
      week.CONTENIDOESPECIFICO = editor.getHTML();
      week.specificContentTextPlain = editor.getText();

      handleProcessForm( 'CONTENIDOESPECIFICO', week.specificContentTextPlain, week, index, validationsPerField.subjectSpecificContent );
    },
  } );

  const detailContentEditor = useEditor( {
    extensions: [ StarterKit ],
    content: week.CONTENIDODETALLADO ?? '',
    onBlur: () =>
    {
      setIsTouchedField( 'CONTENIDODETALLADO' );
    },
    onUpdate: ( { editor } ) =>
    {
      week.CONTENIDODETALLADO = editor.getHTML();
      week.detailContentTextPlain = editor.getText();

      handleProcessForm( 'CONTENIDODETALLADO', week.detailContentTextPlain, week, index, validationsPerField.subjectDetailContent );
    },
  } );

  useEffect( () =>
  {
    const init = () => {
      setIsValidForm( week );
      setIsValidField('CONTENIDODETALLADO', week.detailContentTextPlain, validationsPerField.subjectDetailContent);
      setIsValidField('CONTENIDOESPECIFICO', week.specificContentTextPlain, validationsPerField.subjectSpecificContent);
    }
    init();
  }, [week] );


  const handleProcessForm = ( name, value, week, index, fieldValidations ) =>
  {
    setIsValidField( name, value, fieldValidations );
    setIsValidForm( week );

    week.isValid = isValidWeek( week );

    sendWeekEvent( week, index );
  }

  const deleteWeekClick = () =>
  {
    deleteWeekEvent( index );
  }

  const setIsTouchedField = ( name ) =>
  {
    const updatedFields = { ...formState.fields };
    updatedFields[ name ].isTouched = true;
    setFormState( {
      ...formState,
      fields: updatedFields,
    } );
  }

  const fieldCompliesWithValidation = (field, fieldValidations) => {
    return field.trim().length >= fieldValidations.min && field.trim().length <= fieldValidations.max;
  }

  const setIsValidField = ( name, value, fieldValidations ) =>
  {
    const updatedFields = { ...formState.fields };
    updatedFields[ name ].isValid = value ? fieldCompliesWithValidation(value, fieldValidations) : false;

    setFormState( {
      ...formState,
      fields: updatedFields,
    } );
  }

  const isValidWeek = () =>
  {
    return !!((fieldCompliesWithValidation(week.detailContentTextPlain, validationsPerField.subjectDetailContent)) &&
        (fieldCompliesWithValidation(week.specificContentTextPlain, validationsPerField.subjectSpecificContent)));
  }

  const setIsValidForm = ( week ) =>
  {
    const isValid = isValidWeek( week );
    setFormState( {
      ...formState,
      isValid
    } );
  }

  return (
    <Box
      component="form"
      sx={ {
        '& .MuiTextField-root': { width: '100%' },
      } }
      noValidate
      autoComplete="off"
    >
      { !formState.isValid &&
        <Typography variant="caption" display="block" gutterBottom color="primary">
          Por favor rellena todos los campos
        </Typography> }
      <Typography gutterBottom variant="h6" component="div" sx={{ mb: 2, mt: 4 }}>
        Semana {index + 1}
      </Typography>
      <Grid container spacing={ { xs: 3 } }>
        <Grid item xs={ 12 } sm={ 6 }>
          <Box sx={ { position: 'relative', mb: 3 } }>
            <Typography gutterBottom variant="h7" component="div">
              Contenido especifico*
            </Typography>
            <RichTextEditorProvider editor={ specificContentEditor }>
              <RichTextField controls={<RichTextFieldControls></RichTextFieldControls>} />
            </RichTextEditorProvider>
            
            { subjectByProposedEdition && 
              <div style={{ background: 'rgba(0, 0, 0, 0.26)', 'position': 'absolute', 'left': '0', 'top': '0', 'width': '100%', 'height': '100%', zIndex: 3 }}>  </div>
            }
            <Box display={ 'flex' } justifyContent={ 'space-between' } gap={ '4px' } marginTop={ '4px' } flexWrap={ 'wrap' }>
              <Typography variant="body2" color={ !week.specificContentTextPlain && validate || (week.specificContentTextPlain && (week.specificContentTextPlain.length < validationsPerField.subjectSpecificContent.min || week.specificContentTextPlain.length > validationsPerField.subjectSpecificContent.max )) ? 'error' : '' }>
                Cantidad min { validationsPerField.subjectSpecificContent.min } y maxima de caracteres debe ser de { validationsPerField.subjectSpecificContent.max }
              </Typography>
              <Typography variant="body2">
                Cantidad total: { week.specificContentTextPlain.length }
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={ 12 } sm={ 6 } >
          <Box sx={ { mb: 3 } }>
            <Typography gutterBottom variant="h7" component="div">
              Contenido detallado*
            </Typography>
            <RichTextEditorProvider editor={ detailContentEditor }>
              <RichTextField controls={<RichTextFieldControls></RichTextFieldControls>} />
            </RichTextEditorProvider>
            <Box display={ 'flex' } justifyContent={ 'space-between' } gap={ '4px' } marginTop={ '4px' } flexWrap={ 'wrap' }>
              <Typography variant="body2" color={ !week.detailContentTextPlain && validate || (week.detailContentTextPlain && ( week.detailContentTextPlain.length < validationsPerField.subjectDetailContent.min || week.detailContentTextPlain.length > validationsPerField.subjectDetailContent.max )) ? 'error' : '' }>
                Cantidad min { validationsPerField.subjectDetailContent.min } y max de caracteres debe ser de { validationsPerField.subjectDetailContent.max }
              </Typography>
              <Typography variant="body2">
                Cantidad total: { week.detailContentTextPlain.length }
              </Typography>
            </Box>
          </Box>
        </Grid>
        { weeks && weeks.length > 1 &&
          <Grid item xs={ 12 }>
            <Button startIcon={ <RemoveCircleIcon /> } color="error" sx={ { textTransform: 'initial' } } type='button' onClick={ deleteWeekClick } disabled={ subjectByProposedEdition }>
              Eliminar
            </Button>
          </Grid>
        }
      </Grid>
    </Box>
  )
}

SubjectWeekForm.propTypes = {
  sendWeekEvent: PropTypes.func.isRequired,
  deleteWeekEvent: PropTypes.func.isRequired,
  weeks: PropTypes.array.isRequired,
  week: PropTypes.shape( {
    CONTENIDODETALLADO: PropTypes.string,
    detailContentTextPlain: PropTypes.string,
    CONTENIDOESPECIFICO: PropTypes.string,
    specificContentTextPlain: PropTypes.string,
    isValid: PropTypes.bool,
  } ).isRequired,
  subjectByProposedEdition: PropTypes.bool,
  validate: PropTypes.bool,
  index: PropTypes.number.isRequired
};

export default SubjectWeekForm;