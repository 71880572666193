import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Autocomplete, Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

function SubjectRequirementForm ( { sendRequirementEvent, deleteRequirementEvent, requirement, index, requirements, subjects, validate, subjectByProposedEdition } )
{

  const requirementTypeData = [
    {
      value: 'correquisito',
      label: 'Correquisitos',
    },
    {
      value: 'prerequisito',
      label: 'Prerequisito',
    },
  ];

  const [ formState, setFormState ] = useState( {
    isValid: false,
    fields: {
      requirementCode: {
        isValid: false,
        isTouched: false,
      },
      requirementSubjectName: {
        isValid: false
      },
      requirementType: {
        isValid: false
      },
    }
  } );

  useEffect( () =>
  {
    setIsValidForm( requirement );
    setIsValidField( 'requirementCode', requirement.requirementCode );
    setIsValidField( 'requirementSubjectName', requirement.requirementSubjectName );
    setIsValidField( 'requirementType', requirement.requirementType );
  }, [ requirement ] );

  const handleChange = ( e ) =>
  {
    const { name, value } = e.target;
    requirement[ name ] = value;

    handleProcessForm( name, value, requirement, index );
  };

  const handleProcessForm = ( name, value, requirement, index ) =>
  {
    setIsValidField( name, value );
    setIsValidForm( requirement );

    requirement.isValid = isValidRequirement( requirement );

    sendRequirementEvent( requirement, index );
  }

  const deleteRequirementClick = () =>
  {
    deleteRequirementEvent( index );
  }

  const handleBlur = ( e ) =>
  {
    const { name } = e.target;
    setIsTouchedField( name );
  }

  const setIsTouchedField = ( name ) =>
  {
    const updatedFields = { ...formState.fields };
    updatedFields[ name ].isTouched = true;
    setFormState( {
      ...formState,
      fields: updatedFields,
    } );
  }

  const setIsValidField = ( name, value ) =>
  {
    const updatedFields = { ...formState.fields };
    if (name === 'requirementCode') {
      updatedFields[ name ].isValid = !!value.trim() && requirements.filter(r => r.requirementCode === value ).length === 1;
    } else {
      updatedFields[ name ].isValid = !!value.trim();
    }

    setFormState( {
      ...formState,
      fields: updatedFields,
    } );
  }

  const isValidRequirement = () =>
  {
    return !!( requirement.requirementCode && requirement.requirementSubjectName && requirement.requirementType ) && !hasRepeatCodes();
  }

  const hasRepeatCodes = () => {
    const uniqueCodes = new Set();
    let hasDuplicates = false;

    requirements.forEach(item => {
      if (uniqueCodes.has(item.requirementCode)) {
        hasDuplicates = true;
      } else {
        uniqueCodes.add(item.requirementCode);
      }
    });

    return hasDuplicates;
  }

  const setIsValidForm = ( week ) =>
  {
    const isValid = isValidRequirement( week );
    setFormState( {
      ...formState,
      isValid
    } );
  }

  const handleChangeCode = useCallback( ( value ) =>
  {

    handleChange( {target: {name: 'requirementCode', value}} );

    setTimeout(() => {
      let subjectName = '';
      const matchingRequirement = subjects.find( ( s ) => s.CODIGO_ASIGNATURA.toLowerCase().toString() === value.toLowerCase() );
      if ( matchingRequirement )
      {
        subjectName = matchingRequirement.NOMBRE;
      }
      requirement.requirementSubjectName = subjectName;
      handleProcessForm(
        'requirementSubjectName',
        subjectName,
        requirement,
        index
      );
    }, 0)
  }, [] );

  const getSubject = () => {
    var subject = subjects.find(s => s.CODIGO_ASIGNATURA === requirement.requirementCode);
    return subject ? subject : null;
  }

  return (
    <Box
      sx={ {
        '& .MuiTextField-root': { width: '100%' },
      } }
    >
      { !formState.isValid &&
        <Typography variant="caption" display="block" gutterBottom color="primary">
          Por favor rellena todos los campos y asegurate de que no hayan codigos repetidos
        </Typography> }
      <Grid
        container spacing={ { xs: 3 } }>
        <Grid item xs={ 12 }>
          <Typography variant="body1" display="block" gutterBottom sx={ { mb: 2 } }>
            Requisito { index + 1 }
          </Typography>
          <Grid container spacing={ { xs: 3 } }>
            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
              <Autocomplete
                disablePortal
                id={'requirementCode'+ index}
                name="requirementCode"
                options={ subjects }
                getOptionLabel={(option) => option.CODIGO_ASIGNATURA}
                getOptionKey={(option) => option.id}
                sx={{ width: '100%' }}
                defaultValue={ getSubject }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onBlur={ () => handleBlur( {target: {name: 'requirementCode' }} ) }
                onInputChange={(event, newInputValue) => {
                  handleChangeCode(newInputValue);
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.id}>
                    {option.CODIGO_ASIGNATURA + ' ' + option.NOMBRE}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size='small'
                    label="Código"
                    error={
                      (!requirement.requirementCode && validate) ||
                      (formState.fields.requirementCode.isTouched &&
                      !formState.fields.requirementCode.isValid)
                    }
                    helperText={
                      formState.fields.requirementCode.isTouched &&
                        !formState.fields.requirementCode.isValid ?
                        'El campo es requerido' :
                        ''
                    }
                  />
                )}
              />
              {/* <TextField
                required
                id="requirementCode"
                name="requirementCode"
                label="Código"
                select
                onChange={ ( e ) => handleChangeCode( e ) }
                onBlur={ ( e ) => handleBlur( e ) }
                error={
                  !requirement.requirementCode && validate ||
                  formState.fields.requirementCode.isTouched &&
                  !formState.fields.requirementCode.isValid
                }
                helperText={
                  formState.fields.requirementCode.isTouched &&
                    !formState.fields.requirementCode.isValid ?
                    'El campo es requerido' :
                    ''
                }
                value={ requirement.requirementCode ?? '' }
                size='small'
              >
              { subjects.map( ( option ) => (
                <MenuItem key={ option.id } value={ option.codigo }>
                  { option.codigo }
                </MenuItem>
              ) ) }
            </TextField> */}
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
              <TextField
                required
                disabled
                id="requirementSubjectName"
                name="requirementSubjectName"
                label="Nombre de asignatura"
                onChange={ ( e ) => handleChange( e ) }
                onBlur={ ( e ) => handleBlur( e ) }
                error={
                  (!requirement.requirementSubjectName && validate) ||
                  (formState.fields.requirementSubjectName.isTouched &&
                  !formState.fields.requirementSubjectName.isValid)
                }
                helperText={
                  formState.fields.requirementSubjectName.isTouched &&
                    !formState.fields.requirementSubjectName.isValid ?
                    'El campo es requerido' :
                    ''
                }
                value={ requirement.requirementSubjectName ?? '' }
                size='small'
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
              <TextField
                required
                disabled={subjectByProposedEdition}
                select
                id="requirementType"
                name="requirementType"
                label="Tipo de requisito"
                onChange={ ( e ) => handleChange( e ) }
                onBlur={ ( e ) => handleBlur( e ) }
                error={
                  (!requirement.requirementType && validate) ||
                  (formState.fields.requirementType.isTouched &&
                  !formState.fields.requirementType.isValid)
                }
                helperText={
                  formState.fields.requirementType.isTouched &&
                    !formState.fields.requirementType.isValid ?
                    'El campo es requerido' :
                    ''
                }
                value={ requirement.requirementType ?? '' }
                size='small'
              >
                { requirementTypeData.map( ( option ) => (
                  <MenuItem key={ option.value } value={ option.value }>
                    { option.label }
                  </MenuItem>
                ) ) }
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        { requirements && requirements.length > 1 &&
          <Grid item xs={ 12 }>
            <Button startIcon={ <RemoveCircleIcon /> } color="error" sx={ { textTransform: 'initial' } } type='button' onClick={ deleteRequirementClick } disabled={subjectByProposedEdition}>
              Eliminar
            </Button>
          </Grid>
        }
      </Grid>
    </Box>
  )
}

SubjectRequirementForm.propTypes = {
  sendRequirementEvent: PropTypes.func.isRequired,
  deleteRequirementEvent: PropTypes.func.isRequired,
  requirements: PropTypes.array.isRequired,
  requirement: PropTypes.shape( {
    requirementCode: PropTypes.string,
    requirementSubjectName: PropTypes.string,
    requirementType: PropTypes.string,
    isValid: PropTypes.bool,
  } ).isRequired,
  index: PropTypes.number.isRequired,
  subjects: PropTypes.array.isRequired,
  validate: PropTypes.bool,
};

export default SubjectRequirementForm;