import FeedbackIcon from '@mui/icons-material/Feedback';
import InfoIcon from '@mui/icons-material/Info';
import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomDialog } from '../../helpers/styles/CustomDialog';
import SubjectResults from '../../shared/components/SubjectResults';
import SubjectsFilter from '../../shared/components/SubjectsFilter';
import { pxToRem } from '../../utils/unitsConversion';
import { UseLogicSubjectsByProposedEdition } from './hooks/UseLogicSubjectsByProposedEdition';
import Sources from '../../enums/Sources.enum';

const Transition = forwardRef( function Transition ( props, ref )
{
  return <Slide direction="up" ref={ ref } { ...props } />;
} );

/**
 * 
 * Las propuestas de edición que a hecho un docente
 */
export default function SubjectsByProposedEdition ()
{

  const {
    filteredSubjects,
    isLoading,
    handleCloseDialog,
    openDialog,
    setTerm,
    handleSearchCriteria,
    handleSearchTerm,
    handleSubjectEdit,
    openFeedbackDialog,
    handleOpenFeedbackDialog,
    handleCloseFeedbackDialog,
    feedback
  } = UseLogicSubjectsByProposedEdition();

  const navigate = useNavigate();

  const searchCriteriaData = [
    {
      value: 'NOMBRE',
      label: 'Nombre',
    },
    // {
    //   value: 'facultad',
    //   label: 'Facultad',
    // },
    // {
    //   value: 'sede',
    //   label: 'Sede',
    // },
    // {
    //   value: 'uab',
    //   label: 'UAB',
    // },
  ];

  const redirectTo = ( id ) =>
  {
    navigate( `/subject-view/${ id }`, {
      replace: false,
      state: {
        source: Sources.SubjectByProposedEdition,
      }
    } );
  }

  return (
    <Box>
      { isLoading ? (
        <Box sx={ { display: 'flex', justifyContent: 'center' } }>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Typography variant='h4' component='h2'> Mis propuestas de edición </Typography>
          <SubjectsFilter searchCriteriaData={searchCriteriaData} handleSearchTerm={handleSearchTerm} setTerm={setTerm} handleSearchCriteria={handleSearchCriteria}></SubjectsFilter>
          { filteredSubjects.length > 0 ? (
            <SubjectResults items={ filteredSubjects }
              redirectTo={ redirectTo }
              handleSubjectEdit={handleSubjectEdit}
              handleOpenFeedbackDialog={handleOpenFeedbackDialog}
              source={Sources.SubjectByProposedEdition}>
            </SubjectResults>
          ) : (
            <Box sx={ { mt: 3 } }>
              <Alert severity="info">No se encontraron resultados</Alert>
            </Box>
          ) }
        </Box>
      ) }

      <CustomDialog
        open={ openDialog }
        onClose={ handleCloseDialog }
        color='error'
        TransitionComponent={ Transition }
        keepMounted
        aria-labelledby="alert-cancel-dialog-title"
        aria-describedby="alert-cancel-dialog-description"
      >
        <DialogTitle id="alert-cancel-dialog-title" align='center'>
          <InfoIcon />
          <Typography variant="h4" component={ 'span' }>
            Asignaturas
          </Typography>
        </DialogTitle>
        <DialogContent align='center'>
          <DialogContentText id="alert-cancel-dialog-description" component="div">
            <Typography variant="body1">
              Error al obtener las asignaturas
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={ { justifyContent: 'center', gap: pxToRem( 16 ) } }>
          <Button
            color='primary'
            variant='outlined'
            size='medium'
            onClick={ () => handleCloseDialog() }
          >Aceptar</Button>
        </DialogActions>
      </CustomDialog>

      <CustomDialog
        open={ openFeedbackDialog }
        onClose={ handleCloseFeedbackDialog }
        color='primary'
        TransitionComponent={ Transition }
        keepMounted
        aria-labelledby="alert-cancel-dialog-title"
        aria-describedby="alert-cancel-dialog-description"
      >
        <DialogTitle id="feedback-dialog" align='center'>
          <InfoIcon />
          <Typography variant="h4" component={ 'span' }>
            Comentarios
          </Typography>
        </DialogTitle>
        <DialogContent align='center'>
          <DialogContentText id="feedback-dialog" component="div">
            <Typography variant="body1">
              Ordenado del más reciente al mas antiguo
            </Typography>
            <List dense={ true }>
              { feedback && feedback.sort((a, b) => b.id - a.id).map( ( f, index ) => (
                <ListItem key={ index }>
                  <ListItemAvatar>
                    <Avatar>
                      <FeedbackIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary={ f.descripcion }
                  />
                </ListItem>
              ) ) }
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={ { justifyContent: 'center', gap: pxToRem( 16 ) } }>
          <Button
            color='primary'
            variant='outlined'
            size='medium'
            onClick={ () => handleCloseFeedbackDialog() }
          >Aceptar</Button>
        </DialogActions>
      </CustomDialog>
    </Box>
  )
}
