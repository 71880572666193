import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../auth/providers/AuthProvider';

export const ProtectedRoute = () =>
{
	const auth = useAuth();
	return auth.isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}

export const ProtectedRouteByRole = ( { rolesNotAllowed } ) =>
{
	const auth = useAuth();
	return rolesNotAllowed.some( role => role.toLowerCase() === auth.getUser().role.toLowerCase() ) ? <Navigate to="/subject-approved" /> : <Outlet />;
}

export const IsValidShowResourceByRol = ( rolesNotAllowed ) =>
{
	const auth = useAuth();
	const isValid = !(rolesNotAllowed.some( role => role.toLowerCase() === auth.getUser().role.toLowerCase() ));
	return isValid;
}