import { forwardRef } from 'react';
import './styles/print.css';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import BusinessIcon from '@mui/icons-material/Business';
import CalculateIcon from '@mui/icons-material/Calculate';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClassIcon from '@mui/icons-material/Class';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LockClockIcon from '@mui/icons-material/LockClock';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SourceIcon from '@mui/icons-material/Source';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Bogota');

const PrintContent = forwardRef(({ subject }, ref) => (
  <Box ref={ref}>
    <Typography gutterBottom variant='h4' component='h1'>
      {subject.NOMBRE}
    </Typography>
    <Typography
      variant='body1'
      color='text.secondary'
      sx={{ mb: 4 }}
      component='div'
    >
      <span
        dangerouslySetInnerHTML={{ __html: subject.DESCRIPCIONASIGNATURA }}
      />
    </Typography>

    <Box sx={{ flexGrow: 1, mb: 2 }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={2} sm={4} md={4}>
          <Typography gutterBottom variant='body1' component='div'>
            <SourceIcon color='primary' /> Código
          </Typography>
          <Typography variant='h5' color='text.secondary'>
            {subject.CODIGO_ASIGNATURA ? subject.CODIGO_ASIGNATURA : '-'}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography gutterBottom variant='body1' component='div'>
            <MenuBookIcon color='primary' /> Facultad
          </Typography>
          <Typography variant='h5' color='text.secondary'>
            {subject.FACULTAD ? subject.FACULTAD : '-'}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography gutterBottom variant='body1' component='div'>
            <BusinessIcon color='primary' /> Sede
          </Typography>
          <Typography variant='h5' color='text.secondary'>
            {subject.SEDE ? subject.SEDE : '-'}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography gutterBottom variant='body1' component='div'>
            <AcUnitIcon color='primary' /> Unidad académica básica
          </Typography>
          <Typography variant='h5' color='text.secondary'>
            {subject.UNIDADACADEMICABASICA
              ? subject.UNIDADACADEMICABASICA
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography gutterBottom variant='body1' component='div'>
            <CalculateIcon color='primary' /> Nivel
          </Typography>
          <Typography
            variant='h5'
            color='text.secondary'
            sx={{ textTransform: 'capitalize' }}
          >
            {subject.NIVEL ? subject.NIVEL : '-'}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography gutterBottom variant='body1' component='div'>
            <LibraryBooksIcon color='primary' /> Asignatura libre eleccion
          </Typography>
          <Typography variant='h5' color='text.secondary'>
            {subject.ASIGNATURALIBRELECCION ? 'Si' : 'No'}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography gutterBottom variant='body1' component='div'>
            <PlaylistAddCheckIcon color='primary' /> Obligatoria
          </Typography>
          <Typography variant='h5' color='text.secondary'>
            {subject.OBLIGATORIA ? 'Si' : 'No'}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography gutterBottom variant='body1' component='div'>
            <CalendarMonthIcon color='primary' /> Fecha de solicitud
          </Typography>
          <Typography variant='h5' color='text.secondary'>
            {subject.createdAt
              ? dayjs.utc(subject.createdAt).format('YYYY-MM-DD')
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography gutterBottom variant='body1' component='div'>
            <ClassIcon color='primary' /> Tipo de calificación
          </Typography>
          <Typography
            variant='h5'
            color='text.secondary'
            sx={{ textTransform: 'capitalize' }}
          >
            {subject.TIPOCALIFICACION ? subject.TIPOCALIFICACION : '-'}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography gutterBottom variant='body1' component='div'>
            <WorkHistoryIcon color='primary' /> Validable
          </Typography>
          <Typography variant='h5' color='text.secondary'>
            {subject.VALIDABLE ? 'Si' : 'No'}
          </Typography>
        </Grid>
        {subject.VIGENCIA && (
          <Grid item xs={2} sm={4} md={4}>
            <Typography gutterBottom variant='body1' component='div'>
              <LockClockIcon color='primary' /> Vigencia
            </Typography>
            <Typography variant='h5' color='text.secondary'>
              {subject.VIGENCIA}
            </Typography>
          </Grid>
        )}

        {subject.actNumber && subject.actDate && (
          <>
            <Grid item xs={2} sm={4} md={4}>
              <Typography gutterBottom variant='body1' component='div'>
                <SourceIcon color='primary' /> Número de acta director area
                curricular
              </Typography>
              <Typography variant='h5' color='text.secondary'>
                {subject.actNumber}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography gutterBottom variant='body1' component='div'>
                <CalendarMonthIcon color='primary' /> Fecha de acta director
                area curricular
              </Typography>
              <Typography variant='h5' color='text.secondary'>
                {dayjs.utc(subject.actDate).format('YYYY-MM-DD')}
              </Typography>
            </Grid>
          </>
        )}

        {subject.actNumeroConsejoFacultad && subject.actDateConsejoFacultad && (
          <>
            <Grid item xs={2} sm={4} md={4}>
              <Typography gutterBottom variant='body1' component='div'>
                <SourceIcon color='primary' /> Número de acta consejo de
                facultad
              </Typography>
              <Typography variant='h5' color='text.secondary'>
                {subject.actNumeroConsejoFacultad}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography gutterBottom variant='body1' component='div'>
                <CalendarMonthIcon color='primary' /> Fecha de acta consejo de
                facultad
              </Typography>
              <Typography variant='h5' color='text.secondary'>
                {dayjs.utc(subject.actDateConsejoFacultad).format('YYYY-MM-DD')}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Box>

    <Box>
      {subject.studyPlan?.length > 0 && (
        <Box>
          <Typography gutterBottom variant='h4' component='h2'>
            Planes de estudio
          </Typography>
          <Box sx={{ mb: 3 }}>
            {subject.studyPlan.map((plan, index) => (
              <Box key={index}>
                <Typography variant='h6' component='div'>
                  Plan {index + 1}
                </Typography>
                <List dense={true} key={index}>
                  <ListItem>
                    <ListItemText>
                      <Typography variant='body1' color='text.secondary'>
                        <strong>Codigo del plan: </strong>{' '}
                        {plan.codigoPlan ? plan.codigoPlan : '-'}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <Typography variant='body1' color='text.secondary'>
                        <strong>Nombre del plan: </strong>{' '}
                        {plan.nombrePlan ? plan.nombrePlan : '-'}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  {plan.agrupacion && (
                    <ListItem>
                      <ListItemText>
                        <Typography variant='body1' color='text.secondary'>
                          <strong>Agrupación: </strong>{' '}
                          {plan.agrupacion ? plan.agrupacion : '-'}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemText>
                      <Typography variant='body1' color='text.secondary'>
                        <strong>Tipologia: </strong>{' '}
                        {plan.tipologia ? plan.tipologia : '-'}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {subject.studyPlan?.length > 0 &&
        subject.resultadoAprendizaje?.length > 0 && (
          <Box>
            <Typography gutterBottom variant='h4' component='h2'>
              Resultados de aprendizaje por programa
            </Typography>
            <Box>
              {subject.resultadoAprendizaje
                .filter(
                  (learning) =>
                    !learning.aspectoMacro ||
                    (learning.aspectoMacro &&
                      learning.aspectoMacro.search('items') !== -1)
                )
                .map((learning, index) => (
                  <Box key={index}>
                    <Typography variant='h6' component='div'>
                      RAP {index + 1}
                    </Typography>
                    <List dense={true} key={index}>
                      <ListItem>
                        <ListItemText>
                          <Typography variant='body1' color='text.secondary'>
                            <strong>Plan: </strong>{' '}
                            {learning.nombrePlan ? learning.nombrePlan : '-'}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>
                          <Typography
                            variant='body1'
                            color='text.secondary'
                            component='div'
                          >
                            <strong
                              style={{ display: 'block', marginBottom: '16px' }}
                            >
                              Objetivo resultado de aprendizaje:{' '}
                            </strong>{' '}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: learning.resultadoAprendizaje,
                              }}
                              style={{ display: 'block' }}
                            />
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      {learning.aspectoMacro &&
                        learning.aspectoMacro?.search('items') !== -1 && (
                          <ListItem>
                            <Box>
                              <Typography variant='h7' component='div'>
                                <strong>
                                  Resultados de aprendizaje asignatura
                                </strong>
                              </Typography>
                              <List dense={true}>
                                {JSON.parse(learning.aspectoMacro).items.map(
                                  (macroAspect, index) => (
                                    <ListItem key={index}>
                                      <ListItemText>
                                        <Typography
                                          variant='body1'
                                          color='text.secondary'
                                          component='div'
                                        >
                                          <strong
                                            style={{
                                              display: 'block',
                                              marginBottom: '16px',
                                            }}
                                          >
                                            RAA {index + 1}
                                          </strong>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: macroAspect.aspectoMacro,
                                            }}
                                            style={{ display: 'block' }}
                                          />
                                        </Typography>
                                      </ListItemText>
                                    </ListItem>
                                  )
                                )}
                              </List>
                            </Box>
                          </ListItem>
                        )}
                    </List>
                  </Box>
                ))}
            </Box>
          </Box>
        )}

      {subject.studyPlan?.length === 0 &&
        subject.resultadoAprendizaje?.length > 0 && (
          <Box>
            <Typography gutterBottom variant='h4' component='h2'>
              Resultados de aprendizaje asignatura
            </Typography>
            <Box>
              {subject.resultadoAprendizaje
                .filter((learning) => learning.aspectoMacro)
                .map((learning, index) => (
                  <Box key={index}>
                    <Typography variant='h6' component='div'>
                      RAA {index + 1}
                    </Typography>
                    <List dense={true} key={index}>
                      {/* <ListItem>
                            <ListItemText>
                                <Typography variant='body1' color='text.secondary'><strong>Plan: </strong> { learning.nombrePlan ? learning.nombrePlan : '-' }</Typography>
                            </ListItemText>
                            </ListItem> */}
                      {/* <ListItem>
                            <ListItemText>
                            <Typography variant='body1' color='text.secondary' component='div'>
                                <strong style={ { display: 'block', marginBottom: '16px' } }>Resultado de aprendizaje: </strong> <div dangerouslySetInnerHTML={ { __html: learning.resultadoAprendizaje } } style={ { display: 'block' } } /></Typography>                          
                            </ListItemText>
                            </ListItem> */}
                      <ListItem>
                        <ListItemText>
                          <Typography
                            variant='body1'
                            color='text.secondary'
                            component='div'
                          >
                            <strong
                              style={{ display: 'block', marginBottom: '16px' }}
                            >
                              Aspecto macro:{' '}
                            </strong>{' '}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: learning.aspectoMacro,
                              }}
                              style={{ display: 'block' }}
                            />
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Box>
                ))}
            </Box>
          </Box>
        )}

      <Box>
        <Typography gutterBottom variant='h4' component='h2'>
          Duración
        </Typography>
        <Box sx={{ mb: 3 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={4}>
              <Typography gutterBottom variant='body1' component='div'>
                Horas de actividad presencial a la semana o intensidad horaria
              </Typography>
              <Typography variant='h5' color='text.secondary'>
                {subject.HAP}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography gutterBottom variant='body1' component='div'>
                Horas de actividad autónoma o independiente a la semana
              </Typography>
              <Typography variant='h5' color='text.secondary'>
                {subject.HAI}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography gutterBottom variant='body1' component='div'>
                Total de horas de actividad académica por Semana
              </Typography>
              <Typography variant='h5' color='text.secondary'>
                {subject.THS}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography gutterBottom variant='body1' component='div'>
                Número de semanas por periodo académico o por semestre
              </Typography>
              <Typography variant='h5' color='text.secondary'>
                {subject.NUM_SEMANAS}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography gutterBottom variant='body1' component='div'>
                Total horas por periodo académico
              </Typography>
              <Typography variant='h5' color='text.secondary'>
                {subject.THP}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography gutterBottom variant='body1' component='div'>
                Número de créditos por asignatura
              </Typography>
              <Typography variant='h5' color='text.secondary'>
                {subject.NUM_CREDITOS}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box>
        <Typography gutterBottom variant='h4' component='h2'>
          Porcentaje de asistencia
        </Typography>
        <Box sx={{ mb: 3 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={4}>
              <Typography gutterBottom variant='body1' component='div'>
                Porcentaje
              </Typography>
              <Typography variant='h5' color='text.secondary'>
                {subject.PORCENTAJE_ASISTENCIA}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography gutterBottom variant='body1' component='div'>
                Horas presenciales
              </Typography>
              <Typography variant='h5' color='text.secondary'>
                {subject.HAPXSEMANA}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography gutterBottom variant='body1' component='div'>
                Mínimo de horas
              </Typography>
              <Typography variant='h5' color='text.secondary'>
                {subject.MINIMOHORAS}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {subject.BIBLIOGRAFIAS && (
        <Box>
          <Typography gutterBottom variant='h4' component='h2'>
            Bibliografías
          </Typography>
          <Box sx={{ mb: 3 }}>
            {JSON.parse(subject.BIBLIOGRAFIAS).map((bibliografia, index) => (
              <Box key={index}>
                <Typography variant='h6' component='div'>
                  Bibliografía {index + 1}
                </Typography>
                <List dense={true} key={index}>
                  <ListItem>
                    <ListItemText>
                      <Typography variant='body1' color='text.secondary'>
                        <strong>Tipo de bibliografía: </strong>{' '}
                        {bibliografia.tipoBibliografia
                          ? bibliografia.tipoBibliografia
                          : '-'}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <Typography variant='body1' color='text.secondary'>
                        <strong>Bibliografía: </strong>{' '}
                        {bibliografia.descripcion
                          ? bibliografia.descripcion
                          : '-'}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  {bibliografia.autor && (
                    <ListItem>
                      <ListItemText>
                        <Typography variant='body1' color='text.secondary'>
                          <strong>Autor: </strong>{' '}
                          {bibliografia.autor ? bibliografia.autor : '-'}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  )}
                  {bibliografia.titulo && (
                    <ListItem>
                      <ListItemText>
                        <Typography variant='body1' color='text.secondary'>
                          <strong>Titulo: </strong>{' '}
                          {bibliografia.titulo ? bibliografia.titulo : '-'}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  )}
                  {bibliografia.nombre_editorial && (
                    <ListItem>
                      <ListItemText>
                        <Typography variant='body1' color='text.secondary'>
                          <strong>Nombre editorial: </strong>{' '}
                          {bibliografia.nombre_editorial
                            ? bibliografia.nombre_editorial
                            : '-'}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  )}
                  {bibliografia.año && (
                    <ListItem>
                      <ListItemText>
                        <Typography variant='body1' color='text.secondary'>
                          <strong>Año: </strong>{' '}
                          {bibliografia.año ? bibliografia.año : '-'}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  )}
                </List>
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {subject.OBJETIVOS && (
        <Box>
          <Typography gutterBottom variant='h4' component='h2'>
            Objetivos
          </Typography>
          <Box sx={{ mb: 3 }}>
            <Typography variant='body1' color='text.secondary' component='div'>
              <div dangerouslySetInnerHTML={{ __html: subject.OBJETIVOS }} />
            </Typography>
          </Box>
        </Box>
      )}

      {subject.content && subject.content.length > 0 && (
        <Box>
          <Typography gutterBottom variant='h4' component='h2'>
            Contenido detallado
          </Typography>
          <Box>
            {subject.content.map((week, index) => (
              <Box key={index}>
                <Typography variant='h6' component='div'>
                  Semana {index + 1}
                </Typography>
                <List dense={true} key={index}>
                  <ListItem>
                    <ListItemText>
                      <Typography
                        variant='body1'
                        color='text.secondary'
                        component='div'
                      >
                        <strong
                          style={{ display: 'block', marginBottom: '16px' }}
                        >
                          Contenido especifico:{' '}
                        </strong>{' '}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: week.CONTENIDOESPECIFICO,
                          }}
                          style={{ display: 'block' }}
                        />
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <Typography
                        variant='body1'
                        color='text.secondary'
                        component='div'
                      >
                        <strong
                          style={{ display: 'block', marginBottom: '16px' }}
                        >
                          Contenido detallado:{' '}
                        </strong>{' '}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: week.CONTENIDODETALLADO,
                          }}
                          style={{ display: 'block' }}
                        />
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {subject.METODOLOGIA && (
        <Box>
          <Typography gutterBottom variant='h4' component='h2'>
            Metodologia de enseñanza
          </Typography>
          <Box sx={{ mb: 3 }}>
            <div dangerouslySetInnerHTML={{ __html: subject.METODOLOGIA }} />
          </Box>
        </Box>
      )}

      {subject.subjectsRequirement &&
        subject.subjectsRequirement.length > 0 && (
          <Box>
            <Typography gutterBottom variant='h4' component='h2'>
              Requisitos
            </Typography>
            <Box>
              {subject.subjectsRequirement.map((requirement, index) => (
                <Box key={index}>
                  <Typography variant='h6' component='div'>
                    Requisito {index + 1}
                  </Typography>
                  <List dense={true} key={index}>
                    <ListItem>
                      <ListItemText>
                        <Typography variant='body1' color='text.secondary'>
                          <strong>Codigo de la asignatura: </strong>{' '}
                          {requirement.CODIGO_ASIGNATURA
                            ? requirement.CODIGO_ASIGNATURA
                            : '-'}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        <Typography variant='body1' color='text.secondary'>
                          <strong>Nombre de la asignatura: </strong>{' '}
                          {requirement.NOMBRE_ASIGNATURA
                            ? requirement.NOMBRE_ASIGNATURA
                            : '-'}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        <Typography variant='body1' color='text.secondary'>
                          <strong>Tipo de requisito: </strong>{' '}
                          {requirement.TIPO_REQUISITO
                            ? requirement.TIPO_REQUISITO
                            : '-'}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
              ))}
            </Box>
          </Box>
        )}
    </Box>
  </Box>
));

export default PrintContent;
