import { useContext, useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AuthContext } from '../context/AuthContext'
import { userService } from '../services/user';


export const AuthProvider = ({ children }) => {

	const navigate = useNavigate();
	const [ isAuthenticated, setIsAuthenticated ] = useState( false );
	const [ accessToken, setAccessToken ] = useState( "" );
	const [ user, setUser ] = useState( {} );
	const [ cookie, setCookie] = useState( {} );

	useEffect( ( ) => { checkAuth() }, []);

	async function checkAuth() {
		if (!!getCookie()) {
			try {
				const data = await userService() ;
				if (!!data) {
					data.token = getCookie();
					saveUser(data);
					navigate('/subject-approved', {
						replace: true
					});
				}
			} catch(error) {
				// throw new Error('Error - No se obtuvieron datos del usuario');
				resetUserData();
			}
			
		}
	}

	const resetUserData = () => {
		setIsAuthenticated( false );
		setUser({ });
		let removeCookie = Cookies.remove('X-SRF-TOKEN');
		setCookie( removeCookie );
	}

	const getAccessToken = () => {
		return accessToken;
	}

	const getUser = () => {
		return user;
	}

	const getCookie = () => {
		return Cookies.get('X-SRF-TOKEN');
	}

	const saveUser = ({ id, email, role, token, name, lastname, idUab }) => {
		setAccessToken( token );
		setIsAuthenticated( true );
		setUser( { id, email, role, name, lastname, idUab });
		// const cookieToken = Cookies.set('X-SRF-TOKEN', token, {   sameSite: 'strict', httpOnly: true, expires: 1 });
		const cookieToken = Cookies.set('X-SRF-TOKEN', token, { expires: 1 })
		setCookie( cookieToken );
	}

	return (
		<AuthContext.Provider value={{ isAuthenticated, getAccessToken, saveUser, getUser, getCookie, resetUserData }}>
			{children}
		</AuthContext.Provider>
	)
}

export const useAuth = () => useContext(AuthContext);
