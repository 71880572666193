import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, MenuItem, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
function SubjectBibliographyForm ( { sendBibliographyEvent, deleteBibliographyEvent, bibliography, index, bibliographies, validate } )
{

  const [ formState, setFormState ] = useState( {
    isValid: false,
    fields: {
      tipoBibliografia: {
        isValid: false,
        isTouched: false,
      },
      descripcion: {
        isValid: false,
        isTouched: false,
      },
      /*
      autor: {
        isValid: false,
        isTouched: false,
      },
      titulo: {
        isValid: false
      },
      nombre_editorial: {
        isValid: false
      },
      año: {
        isValid: false
      },
      */
    }
  } );

  const [descriptionFormat, setDescriptionFormat] = useState('');

  const bibliographicTypes = [
		{
			value: 'Libros',
			label: 'Libros',
      format: 'Autor(es). (Año). Título del libro (edición, si aplica). Editorial.'
		},
		{
			value: 'Artículos de revistas',
			label: 'Artículos de revistas',
      format: 'Autor(es). (Año). Título del artículo. Título de la revista, volumen(número), páginas. DOI (si está disponible).'
		},
    {
			value: 'Artículos de periódicos',
			label: 'Artículos de periódicos',
      format: 'Autor(es). (Año, mes día). Título del artículo. Nombre del periódico, páginas.'
		},
    {
			value: 'Sitios web',
			label: 'Sitios web',
      format: 'Autor(es). (Año, mes día). Título del documento o página. Nombre del sitio web. URL'
		},
    {
			value: 'Tesis y disertaciones',
			label: 'Tesis y disertaciones',
      format: 'Autor(es). (Año). Título de la tesis (Tesis de maestría o disertacióndoctoral). Nombre de la Institución.'
		},
	];

  useEffect( () =>
  {
    setIsValidForm( bibliography );
    setIsValidField( 'tipoBibliografia', bibliography.tipoBibliografia );
    setIsValidField( 'descripcion', bibliography.descripcion );
    /*setIsValidField( 'autor', bibliography.autor );
    setIsValidField( 'titulo', bibliography.titulo );
    setIsValidField( 'nombre_editorial', bibliography.nombre_editorial );
    setIsValidField( 'año', bibliography.año );*/

  }, [ bibliography ] );

  const handleChange = ( e ) =>
  {
    const { name, value } = e.target;
    bibliography[ name ] = value;

    if ( name === 'año' )
    {
      const formattedValue = value.replace( /\D/g, '' ).slice( 0, 4 ); // Elimina los caracteres no numéricos y limita a cuatro dígitos
      bibliography[ name ] = formattedValue;
    }

    if (name === 'tipoBibliografia') {
      const format = bibliographicTypes.find(type => type.value === value).format;
      setDescriptionFormat(format);
    }

    handleProcessForm( name, value, bibliography, index );
  };

  const handleProcessForm = ( name, value, bibliography, index ) =>
  {
    setIsValidField( name, value );
    setIsValidForm( bibliography );

    bibliography.isValid = isValidBibliography( bibliography );

    sendBibliographyEvent( bibliography, index );
  }

  const deleteBibliographyClick = () =>
  {
    deleteBibliographyEvent( index );
  }

  const handleBlur = ( e ) =>
  {
    const { name } = e.target;
    setIsTouchedField( name );
  }

  const setIsTouchedField = ( name ) =>
  {
    const updatedFields = { ...formState.fields };
    updatedFields[ name ].isTouched = true;
    setFormState( {
      ...formState,
      fields: updatedFields,
    } );
  }

  const setIsValidField = ( name, value ) =>
  {
    const updatedFields = { ...formState.fields };
    updatedFields[ name ].isValid = !!(value && value.trim());
    
    if ( name === 'año' ) {
      updatedFields[ name ].isValid = !!(value && value.trim()) && isValidYear(value);
    }

    setFormState( {
      ...formState,
      fields: updatedFields,
    } );
  }

  const isValidYear = (year) => {
    const currentYear = new Date().getFullYear();
    return !isNaN(year) && year >= 1700 && year <= currentYear;
  };

  const isValidBibliography = () =>
  {
    return !!( bibliography.tipoBibliografia && bibliography.descripcion
      /*&& bibliography.autor &&
      bibliography.titulo &&
      bibliography.nombre_editorial &&
      isValidYear(bibliography.año)*/ )
  }

  const setIsValidForm = ( week ) =>
  {
    const isValid = isValidBibliography( week );
    setFormState( {
      ...formState,
      isValid
    } );
  }

  return (
    <Box
      sx={ {
        '& .MuiTextField-root': { width: '100%' },
      } }
    >
      { !formState.isValid &&
        <Typography variant="caption" display="block" gutterBottom color="primary">
          Por favor rellena todos los campos
        </Typography> }
      <Grid
        container spacing={ { xs: 3 } }>
        <Grid item xs={ 12 }>
          <Typography variant="body1" display="block" gutterBottom sx={ { mb: 2 } }>
            Bibliografia { index + 1 }
          </Typography>
          <Grid container spacing={ { xs: 3 } }>

            <Grid item xs={ 12 } sm={ 4 }>
              <TextField
                id={"tipoBibliografia"+index}
                name="tipoBibliografia"
                select
                label="Tipo de bibliografía*"
                onChange={ ( e ) => handleChange( e ) }
                onBlur={ ( e ) => handleBlur( e ) }
                error={
                  !bibliography.tipoBibliografia && validate ||
                  formState.fields.tipoBibliografia.isTouched &&
                  !formState.fields.tipoBibliografia.isValid
                }
                helperText={
                  formState.fields.tipoBibliografia.isTouched &&
                    !formState.fields.tipoBibliografia.isValid ?
                    'El campo es requerido' :
                    ''
                }
                value={ bibliography.tipoBibliografia ?? '' }
                size='small'>
                { bibliographicTypes.map( ( option ) => (
                  <MenuItem key={ option.value } value={ option.value }>
                    { option.label }
                  </MenuItem>
                ) ) }
              </TextField>
            </Grid>

            <Grid item xs={ 12 } sm={ 8 }>
              <TextField
                label="Bibliografía*"
                id={"descripcionBibliografia"+index}
                name="descripcion"
                multiline
                rows={1}
                fullWidth
                onChange={ ( e ) => handleChange( e ) }
                onBlur={ ( e ) => handleBlur( e ) }
                error={
                  !bibliography.descripcion && validate ||
                  formState.fields.descripcion.isTouched &&
                  !formState.fields.descripcion.isValid
                }
                helperText={
                  formState.fields.descripcion.isTouched &&
                    !formState.fields.descripcion.isValid ?
                    'El campo es requerido' :
                    ''
                }
                value={ bibliography.descripcion ?? '' }
                size='small'
              />
              <Typography variant="h7" display="block" gutterBottom color={'primary'}>
                Seguir el siguiente formato: {descriptionFormat}
              </Typography>
            </Grid>

            {/* <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
              <TextField
                id="autor"
                name="autor"
                label="Autor(es)*"
                onChange={ ( e ) => handleChange( e ) }
                onBlur={ ( e ) => handleBlur( e ) }
                error={
                  !bibliography.autor && validate ||
                  formState.fields.autor.isTouched &&
                  !formState.fields.autor.isValid
                }
                helperText={
                  formState.fields.autor.isTouched &&
                    !formState.fields.autor.isValid ?
                    'El campo es requerido' :
                    'Autores separados por coma(,)'
                }
                value={ bibliography.autor ?? '' }
                size='small'
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
              <TextField
                id="titulo"
                name="titulo"
                label="Titulo*"
                onChange={ ( e ) => handleChange( e ) }
                onBlur={ ( e ) => handleBlur( e ) }
                error={
                  !bibliography.titulo && validate ||
                  formState.fields.titulo.isTouched &&
                  !formState.fields.titulo.isValid
                }
                helperText={
                  formState.fields.titulo.isTouched &&
                    !formState.fields.titulo.isValid ?
                    'El campo es requerido' :
                    ''
                }
                value={ bibliography.titulo ?? '' }
                size='small'
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
              <TextField
                id="nombre_editorial"
                name="nombre_editorial"
                label="Editorial o nombre de revista*"
                onChange={ ( e ) => handleChange( e ) }
                onBlur={ ( e ) => handleBlur( e ) }
                error={
                  !bibliography.nombre_editorial && validate ||
                  formState.fields.nombre_editorial.isTouched &&
                  !formState.fields.nombre_editorial.isValid
                }
                helperText={
                  formState.fields.nombre_editorial.isTouched &&
                    !formState.fields.nombre_editorial.isValid ?
                    'El campo es requerido' :
                    ''
                }
                value={ bibliography.nombre_editorial ?? '' }
                size='small'
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
              <TextField
                id="año"
                name="año"
                label="Año*"
                onChange={ ( e ) => handleChange( e ) }
                onBlur={ ( e ) => handleBlur( e ) }
                error={
                  !bibliography.año && validate ||
                  formState.fields.año.isTouched &&
                  !formState.fields.año.isValid
                }
                helperText={
                  formState.fields.año.isTouched &&
                    !formState.fields.año.isValid ?
                    'El campo es requerido y debe ser >= 1700 y <= al año actual' :
                    ''
                }
                value={ bibliography.año ?? '' }
                inputProps={ {
                  maxLength: 4,
                } }
                size='small'
              />
            </Grid> */}
          </Grid>
        </Grid>
        { bibliographies && bibliographies.length > 1 &&
          <Grid item xs={ 12 }>
            <Button startIcon={ <RemoveCircleIcon /> } color="error" sx={ { textTransform: 'initial' } } type='button' onClick={ deleteBibliographyClick }>
              Eliminar
            </Button>
          </Grid>
        }
      </Grid>
    </Box>
  )
}


SubjectBibliographyForm.propTypes = {
  sendBibliographyEvent: PropTypes.func.isRequired,
  deleteBibliographyEvent: PropTypes.func.isRequired,
  bibliographies: PropTypes.array.isRequired,
  bibliography: PropTypes.shape( {
    año: PropTypes.string,
    nombre_editorial: PropTypes.string,
    titulo: PropTypes.string,
    autor: PropTypes.string,
    isValid: PropTypes.bool,
  } ).isRequired,
  validate: PropTypes.bool,
  index: PropTypes.number.isRequired
};

export default SubjectBibliographyForm