import React from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import SubjectWeeksList from './SubjectWeeksList';
import SubjectBibliographyList from './SubjectBibliographyList';

export default function ContentAndDescriptionForm ( { formData, setFormData, subjectByProposedEdition, validate } )
{
  return (
    <Grid container spacing={ { xs: 3 } }>

      {/* Seccion de contenidos */ }
      <Grid item xs={ 12 }>
        <Typography gutterBottom variant="h5" component="h3" sx={ { textAlign: 'center' } }>
          Contenidos
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center" spacing={ { xs: 3 } }>
          <Grid item xs={ 12 } sm={ 12 }>
           
            <Typography variant="caption" display="block" gutterBottom>
              Descripción del contenido de la asignatura especificando cada uno de los ítems del contenido básico.
            </Typography>
          </Grid>
          <Grid item xs={ 12 }>
            <SubjectWeeksList
              formDataEvent={ formData }
              setFormDataEvent={ setFormData }
              subjectByProposedEdition={ subjectByProposedEdition }
              validate={ validate }>
            </SubjectWeeksList>
          </Grid>
        </Grid>
      </Grid>

      {/* Seccion de Bibliografia */ }
      <Grid item xs={ 12 }>
        <Typography gutterBottom variant="h5" component="h3" sx={ { textAlign: 'center' } }>
          Bibliografia básica
        </Typography>
        <Typography variant="caption" display="block" sx={ { textAlign: 'center', mb: 3 } }>
          Por favor escriba el título y los nombres de autor completos en mayúscula/minúscula.
        </Typography>
        <SubjectBibliographyList
          formDataEvent={ formData }
          setFormDataEvent={ setFormData }
          validate={ validate }>
        </SubjectBibliographyList>
      </Grid>
    </Grid>
  )
}
