import InfoIcon from '@mui/icons-material/Info';
import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { forwardRef } from 'react';
import { CustomDialog } from '../../helpers/styles/CustomDialog';
import { pxToRem } from '../../utils/unitsConversion';
import { UseLogicSubjectsApproved } from '../hooks/UseLogicSubjectsApproved';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/providers/AuthProvider';
import Roles from '../../enums/Roles.enum';
import Sources from '../../enums/Sources.enum';
import SubjectsFilter from '../../shared/components/SubjectsFilter';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend( utc );
dayjs.extend( timezone );
dayjs.tz.setDefault( 'America/Bogota' );


const Transition = forwardRef( function Transition ( props, ref )
{
  return <Slide direction="up" ref={ ref } { ...props } />;
} );

export default function SubjectsAppoved ()
{

  const auth = useAuth();
  const user = auth.getUser();

  const {
    filteredSubjects,
    isLoading,
    handleCloseDialog,
    openDialog,
    setTerm,
    handleSearchCriteria,
    handleSearchTerm,
    subjectByProposedEdition,
    handleSubjectEdit,
    searchCriteriaData
  } = UseLogicSubjectsApproved();

  const navigate = useNavigate();
  const redirectTo = ( id ) =>
  {
    navigate( `/subject-view/${ id }`, {
      replace: false,
      state: {
        source: Sources.SubjectApproved
      }
    } );
  }

  return (
    <Box>
      { isLoading ? (
        <Box sx={ { display: 'flex', justifyContent: 'center' } }>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant='h4' component='h2'> Asignaturas </Typography>
          <SubjectsFilter searchCriteriaData={searchCriteriaData} handleSearchTerm={handleSearchTerm} setTerm={setTerm} handleSearchCriteria={handleSearchCriteria}></SubjectsFilter>

          { filteredSubjects.length ? (
            <TableContainer component={ Paper }
              sx={ {
                mt: 3,
                overflowX: 'auto'
              } }>
              <Table sx={ { minWidth: 650 } } size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Fecha de creación</TableCell>
                    <TableCell>Código</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>No. Creditos</TableCell>
                    <TableCell>Validable</TableCell>
                    <TableCell>Nivel</TableCell>
                    <TableCell>Libre elección</TableCell>
                    { user.role === Roles.direccionAcademicaDeSede &&
                      <TableCell align="left">Facultad</TableCell>
                    }
                    { user.role !== Roles.Teacher &&
                      <TableCell align="left">UAB</TableCell>
                    }
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { filteredSubjects.map( ( subject ) => (
                    <TableRow
                      key={ subject.id }
                      sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                    >
                      <TableCell component="th" scope="row">
                        { dayjs.utc( subject.createdAt ).format( 'YYYY-MM-DD' ) ?? '-' }
                      </TableCell>
                      <TableCell component="th" scope="row">
                        { subject.CODIGO_ASIGNATURA }
                      </TableCell>

                      <TableCell component="th" scope="row">
                        { subject.NOMBRE }
                      </TableCell>
                      <TableCell component="th" scope="row">
                        { subject.NUM_CREDITOS }
                      </TableCell>
                      <TableCell component="th" scope="row">
                        { subject.VALIDABLE ? 'Si' : 'No' }
                      </TableCell>
                      <TableCell component="th" scope="row">
                        { subject.NIVEL }
                      </TableCell>
                      <TableCell component="th" scope="row">
                        { subject.ASIGNATURALIBRELECCION ? 'Si' : 'No' }
                      </TableCell>
                      { user.role === Roles.direccionAcademicaDeSede &&
                        <TableCell align="left">{ subject.FACULTAD }</TableCell>
                      }
                      { user.role !== Roles.Teacher &&
                        <TableCell align="left">{ subject.UNIDADACADEMICABASICA ? subject.UNIDADACADEMICABASICA : '-' }</TableCell>
                      }
                      <TableCell align="left">
                        <Button variant="contained"
                          sx={ { m: 1, whiteSpace: 'nowrap' } }
                          onClick={ () => redirectTo( subject.id ) }
                          size="small">Ver asignatura</Button>
                        { subjectByProposedEdition &&
                          <Button variant="outlined"
                            sx={ { m: 1, whiteSpace: 'nowrap' } }
                            color="secondary"
                            onClick={ () => handleSubjectEdit( subject ) }
                            size="small">Proponer edición
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  ) ) }
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box sx={ { mt: 3 } }>
              <Alert severity="info">No se encontraron resultados</Alert>
            </Box>
          ) }
        </>
      ) }

      <CustomDialog
        open={ openDialog }
        onClose={ handleCloseDialog }
        color='error'
        TransitionComponent={ Transition }
        keepMounted
        aria-labelledby="alert-cancel-dialog-title"
        aria-describedby="alert-cancel-dialog-description"
      >
        <DialogTitle id="alert-cancel-dialog-title" align='center'>
          <InfoIcon />
          <Typography variant="h4" component={ 'span' }>
            Asignaturas
          </Typography>
        </DialogTitle>
        <DialogContent align='center'>
          <DialogContentText id="alert-cancel-dialog-description" component="div">
            <Typography variant="body1">
              Error al obtener las asignaturas
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={ { justifyContent: 'center', gap: pxToRem( 16 ) } }>
          <Button
            color='primary'
            variant='outlined'
            size='medium'
            onClick={ () => handleCloseDialog() }
          >Aceptar</Button>
        </DialogActions>
      </CustomDialog>
    </Box>
  )
}
