import { useLocation } from 'react-router-dom';
import { SubjectProposal } from '../SubjectProposal';
import Box from '@mui/material/Box';
/**
 * 
 * Vista para proponer una propuesta de edición de una asignatura aprobada y Vista para editar una propuesta de edición
 * 
 */
export default function SubjectProposalEdit ()
{

  const { state } = useLocation();
  const subject = state ? state.subject : null;
  const subjectByProposedEdition = state ? state.subjectByProposedEdition : null;
  const editSubjectByEditingProposal = state ? state.editSubjectByEditingProposal : null;
  const isDraftSubject = state ? state.isDraftSubject : null;

  return (
    <Box>
      { subject ? (
        <Box>
          <SubjectProposal subject={ subject }
            subjectByProposedEdition={ subjectByProposedEdition }
            editSubjectByEditingProposal={ editSubjectByEditingProposal }
            isDraftSubject={ isDraftSubject } />
        </Box>

      ) : (
        <Box>
          No data
        </Box>
      ) }
    </Box>
  )
}
