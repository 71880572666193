import { Alert } from '@mui/material';

import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import createAxiosInstance from '../../utils/AxiosConfig.jsx';

export const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [responseMsg, setResponseMsg] = useState({
    message: '',
    type: 'error',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      navigate('/', {
        replace: true,
      });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMsg({ message: '', type: 'error' });
    if (password === confirmPassword) {
      handleResetPassword();
    } else {
      setResponseMsg({
        message: 'Las contraseñas no coinciden',
        type: 'warning',
      });
    }
  };

  const handleResetPassword = async () => {
    setIsLoading(true);
    const request = {
      token,
      newPassword: password,
    };
    try {
      await createAxiosInstance().post(`/auth/reset-password`, request);
      navigate('/', {
        replace: true,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setResponseMsg({
        message:
          error.response &&
          (error.response.status === 400 || error.statusCode === 400)
            ? error.response.data.message &&
              Array.isArray(error.response.data?.message)
              ? error.response.data?.message.join(', ')
              : error.response.data?.message
            : 'Error del servidor, intentalo más tarde',
        type: 'error',
      });
    }
  };

  return (
    <>
      {responseMsg && responseMsg.message && (
        <Alert severity={responseMsg.type} sx={{ mb: 3 }}>
          {responseMsg.message}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <input
            type='password'
            id='password'
            name='password'
            placeholder='Contraseña'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className='form-group'>
          <input
            type='password'
            id='confirmPassword'
            name='confirmPassword'
            placeholder='Confirmar contraseña'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button
          className="auth-action_button"
          type='submit'
          disabled={!password || !confirmPassword || isLoading}
        >
          {isLoading ? 'Cargando...' : 'Ingresar'}
        </button>
      </form>
    </>
  );
};
